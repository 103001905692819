.checkBox {
  font-size: 0.7rem;
  font-weight: 400;
  display: flex;
  gap: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > input {
    width: 2rem;
  }

  & > div {
    & > span {
      color: #d0004b;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.profile {
  height: 100%;

  & > .navbar {
    padding: 0rem 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    & > .leftContainer {
      display: flex;

      & > .navbarOption {
        padding: 1.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        width: max-content;
        cursor: pointer;
      }

      & > .navbarOptionSelected {
        color: var(--main-color);
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 35px 35px 0 0;
          background: var(--main-color);
        }
      }
    }

    & > .rightContainer {
    }
  }

  & > .brokerageDetails {
    padding: 2rem 2.5rem;
    padding-bottom: 0;
    // overflow-y: scroll;
    height: calc(100% - 5rem);
    background: #8080800d;
    position: relative;

    & > .title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 2.5rem;
      padding-top: 0.5rem;
    }

    & > .dropdownItems {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      // gap: 1.5rem;

      & > .filterDiv {
        display: flex;
        align-items: center;
        border: 1px solid #e1e1e1;
        background: white;
        gap: 0;
        border-radius: 5px;

        & > .filterBtn {
          // width: 3rem;
          // padding: 12px 0;
          // height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 51px;
          // height: 50px;
          // border-right: 1px solid #e5e5e5;
          position: relative;
          height: 100%;
          // width: 100%;
          padding: 0.5rem 0;

          & > svg {
            fill: var(--font-color);
            transition: all linear 0.3s;

            &:hover {
              fill: var(--main-color);
            }
          }

          & > .dropdownContent {
            position: absolute;
            top: 70%;
            left: 55%;
            padding: 1rem;
            background: white;
            z-index: 6;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #00000040;
            border-radius: 10px;
            // min-width: calc(max-content + 4rem);
            min-width: 250px;

            & > .eachFilterItem {
              padding: 0.7rem 0.5rem;
              font-size: 1rem;
              font-weight: 400;
              cursor: pointer;
              white-space: nowrap;
              // padding-right: 4rem;

              & > svg {
                rotate: -90deg;
              }

              &:hover {
                color: var(--main-color);
              }
            }
          }
        }

        & > :nth-child(1) {
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;
          height: 100%;
          border-left: none !important;
          border-radius: 5px;
        }

        & > div {
          border-left: 1px solid #e1e1e1;
          & > input {
            width: 200px;
            padding: 0.5rem 1.5rem;
            border: none;
            outline: none;
            border-radius: 5px;
          }
        }
      }

      & > div {
        display: flex;
        gap: 1.2rem;
        align-items: center;
        position: relative;

        & > .downloadListBtn {
          background: var(--main-color);
          color: white;
          font-size: 0.9rem;
          font-weight: 500;
          padding: 0.5rem 2rem;
          border-radius: 5px;
          text-align: center;
          cursor: pointer;
          border: 1px solid var(--main-color);
          transition: all linear 0.3s;
          position: relative;

          &:hover {
            // color: var(--main-color);
            // background: white;
            // border: 1px solid #e5e5e5;

            & > .dropdownContent {
              display: block;
            }
          }

          & > .dropdownContent {
            display: none;
            position: absolute;
            top: 100%;
            left: 0%;
            transform: translate(0%, -50%);
            padding: 1rem 0;
            background: white;
            z-index: 6;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #00000040;
            border-radius: 10px;
            min-width: 250px;
            color: var(--font-color);

            & > .eachFilterItem {
              padding: 0.7rem 1.5rem;
              font-size: 1rem;
              font-weight: 400;
              cursor: pointer;
              white-space: nowrap;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              width: 100%;
              // padding-right: 4rem;

              & > span {
                display: flex;
                gap: 10px;
                align-items: center;

                & > svg {
                  height: 1rem;
                  width: 1rem;
                }
              }

              & > svg {
                rotate: -90deg;
              }

              & > .subDropdown {
                position: absolute;
                display: none;
                min-width: 250px;
                left: 100%;
                top: 0;
                background: white;
                color: var(--font-color);
                padding: 1rem 0;
                background: white;
                border: 1px solid #e1e1e1;
                box-shadow: 0px 4px 4px 0px #00000040;
                border-radius: 10px;

                & > .subMenuTitle {
                  padding: 0.2rem 1.5rem;
                  font-size: 1rem;
                  font-weight: 520;
                  text-align: left;
                }

                & > .eachFilterItem {
                  padding: 0.7rem 1.5rem;
                  font-size: 1rem;
                  font-weight: 400;
                  cursor: pointer;
                  white-space: nowrap;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  position: relative;
                  width: 100%;
                  // padding-right: 4rem;

                  & > span {
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    & > svg {
                      height: 1rem;
                      width: 1rem;
                    }
                  }

                  & > svg {
                    rotate: -90deg;
                  }

                  & > .subDropdown {
                    position: absolute;
                    display: none;
                    // min-width: 250px;
                    left: 100%;
                    top: 0;
                    background: white;
                    color: var(--font-color);
                    padding: 1rem 0;
                    background: white;
                  }

                  &:hover {
                    color: var(--main-color);

                    & > .subDropdown {
                      display: block;
                    }
                  }
                }
              }

              &:hover {
                color: var(--main-color);

                & > .subDropdown {
                  display: block;
                }
              }
            }
          }
        }

        & > .inviteBtn,
        .deleteBtn {
          background: white;
          color: var(--font-color);
          font-size: 0.9rem;
          font-weight: 500;
          padding: 0.5rem 2rem;
          border-radius: 5px;
          text-align: center;
          cursor: pointer;
          border: 1px solid #e5e5e5;
          display: flex;
          align-items: center;
          gap: 5px;
          transition: all linear 0.3s;

          &:hover {
            color: var(--main-color);
            border: 1px solid var(--main-color);
          }
        }

        & > .deleteBtn {
          border-radius: 5px;
          background: #f40d0d;
          color: white;
          border: 1px solid #f40d0d !important;
          cursor: not-allowed;

          &:hover {
            color: white;
            // border: none;
          }
        }
      }
    }

    & > .extraBtns {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      position: relative;

      & > .transparentBtn {
        padding: 0.5rem 1.5rem;
        border: 1px solid #e1e1e1;
        border-radius: 7px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: 0.9rem;
        font-weight: 500;
        background: white;
        transition: all linear 0.3s;

        & > svg {
          fill: var(--font-color);
          transition: all linear 0.3s;
        }

        &:hover {
          border: 1px solid var(--main-color);
          background: var(--main-color);
          color: white;

          & > svg {
            fill: white;
          }
        }
      }

      & > .dropdownContent {
        position: absolute;
        right: 10rem;
        top: 0%;
        // transform: translate(-50%, -50%);
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
        border-radius: 10px;
        min-width: 250px;
        color: var(--font-color);
      }
    }

    & > .allLeads {
      border: 1px solid #e5e5e5;
      // border-radius: 35px 35px 0 0;
      margin-top: 2rem;
      height: calc(100% - 5rem);
      overflow: hidden;
      background: white;

      & > .nav {
        // padding: 0rem 12px;
        border-bottom: 1px solid #e5e5e5;
        // border-bottom: none;
        // border-radius: 35px 35px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .filterBtn {
          // width: 3rem;
          // padding: 12px 0;
          // height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 51px;
          height: 50px;
          border-right: 1px solid #e5e5e5;
          position: relative;

          & > svg {
            fill: var(--font-color);
            transition: all linear 0.3s;

            &:hover {
              fill: var(--main-color);
            }
          }

          & > .dropdownContent {
            position: absolute;
            top: 70%;
            left: 55%;
            padding: 1rem;
            background: white;
            z-index: 6;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #00000040;
            border-radius: 10px;
            // min-width: calc(max-content + 4rem);
            min-width: 250px;

            & > .eachFilterItem {
              padding: 0.7rem 0.5rem;
              font-size: 1rem;
              font-weight: 400;
              cursor: pointer;
              white-space: nowrap;
              // padding-right: 4rem;

              & > svg {
                rotate: -90deg;
              }

              &:hover {
                color: var(--main-color);
              }
            }
          }
        }

        & > .actionBtn {
          display: flex;
          align-items: center;
          gap: 1rem;

          & > div {
            border: 1px solid #e5e5e5;
            border-radius: 35px;
            padding: 0.5rem 2.5rem;
            cursor: pointer;
            font-size: 0.9rem;
            transition: all ease-in 0.2s;

            &:hover {
              background: #e5e5e5;
            }
          }
        }

        & > .actionBtnsDownload {
          & > div {
            font-size: 0.9rem;
            font-weight: 500;
            cursor: pointer;
            transition: all linear 0.3s;

            &:hover {
              background: var(--main-color);
              color: white;
            }
          }
        }
      }
    }

    & > .paginationBar {
      // position: absolute;
      // left: 0;
      // right: 0;
      // bottom: 0;
      height: 3rem;
      padding: 5px 2rem;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #dddddd;
      border-top: none;

      & > .paginationMsg {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
      }

      & > .allPagination {
        margin-left: auto;
        display: flex;
        gap: 10px;
        height: 100%;
        width: max-content;

        & > .eachBtn {
          height: 100%;
          aspect-ratio: 1/1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          border: 1px solid #e7e7e7;
          font-size: 0.9rem;
          font-weight: 500;
          cursor: pointer;
          background: #e5e5e553;
          transition: all linear 0.3s;

          &:hover {
            color: white;
            background: var(--main-color);
          }
        }

        & > .eachBtnCurrent {
          color: white;
          background: var(--main-color);
        }
      }
    }
  }

  & > .paginationBar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3rem;
    padding: 5px 2rem;
    background: white;

    & > .allPagination {
      margin-left: auto;
      display: flex;
      gap: 10px;
      height: 100%;
      width: max-content;

      & > .eachBtn {
        height: 100%;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid #e7e7e7;
        font-size: 0.9rem;
        font-weight: 500;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          color: white;
          background: var(--main-color);
        }
      }

      & > .eachBtnCurrent {
        color: white;
        background: var(--main-color);
      }
    }
  }
}

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 3rem;
  cursor: pointer;
  transition: all linear 0.3s;

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 0rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    transition: all ease-in 0.2s;

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

// src/Table.scss
.tableContainer {
  overflow-y: auto;
  height: 100%;
  width: 100%; /* Set the container width to 100% or any other desired value */
  // overflow-x: auto; /* Allow horizontal scrolling */
  // border-left: 0.25px solid #e5e5e5;
  // border-right: 0.25px solid #e5e5e5;
  position: relative;

  &::-webkit-scrollbar {
    display: block;
    width: 7px; /* Set the width of the table's scrollbar */
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(
      --font-color
    ); /* Set the color of the scrollbar thumb */
    border-radius: 5px; /* Set the border-radius of the thumb */
    height: 80px;
    transition: all linear 0.3s;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --main-color
    ); /* Set the color when the scrollbar thumb is hovered */
  }

  table {
    border-collapse: collapse;
    // min-width: 240%; /* Set the table width to 110% to ensure horizontal scrollbar */
    margin-bottom: 20px;
    table-layout: fixed; /* Ensures equal column width for horizontally scrolling table */
    width: 100%;

    th,
    td {
      text-align: left;
      white-space: nowrap;
      word-wrap: break-word;
      padding: 0.75rem 1rem;
      position: relative;
      display: flex;
      align-items: center;
      border-right: none;
      border-top: none;
      font-size: 0.85rem;
      text-overflow: ellipsis;

      & > input {
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    th {
      white-space: nowrap;
      font-weight: 550;
      font-size: 0.85rem;
      padding: 0.7rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg {
        cursor: pointer;
      }
    }

    thead {
      background: #e1e1e1;
      & > tr {
        & > th {
          overflow: hidden;
          border-top: none;

          & > span {
            width: calc(100% - 15px);
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    tr {
      border-bottom: 1px solid #dddddd;
      & > :nth-child(1) {
        border-left: none;
        width: 50px;
      }

      & > :last-child {
        border-right: none;
      }
    }

    tr {
      display: grid;
      grid-template-columns: min-content repeat(15, 1fr);
    }

    tr > :first-child {
      /* Set the width you want for the first child */
      width: 50px; /* Replace 200px with your desired width */
      /* Position the first child in the first column */
      grid-column: 1;
      overflow: unset;
    }

    .detailedItem {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      & > :nth-child(1) {
        padding: 0.5rem;
        border: 0.5px solid #e5e5e5;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 70%;
          height: 70%;
        }
      }

      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        & > :nth-child(1) {
          font-size: 0.9rem;
          font-weight: 550;
          white-space: nowrap;
        }
        & > :nth-child(2) {
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    td {
      position: relative;
      overflow: hidden;
      transition: all linear 0.3s;

      & > .statusBtn {
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        padding: 0.6rem 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
      }

      &:hover {
        background: #8080800d;
      }

      & > .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .seePreEnrollment {
          font-size: 0.9rem;
          padding: 0 1.5rem;
          display: flex;
          align-items: center;
          gap: 1rem;

          & > button {
            background: var(--main-color);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 0.5rem 1.5rem;
            cursor: pointer;
            font-weight: 520;
            color: white;
            border: none;
            outline: none;
            border: 1px solid var(--main-color);
            transition: all linear 0.3s;

            &:hover {
              background: white;
              border: 1px solid #e1e1e1;
              color: var(--font-color);
            }
          }
        }

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }

      .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > span {
            display: flex;
            gap: 10px;
            align-items: center;

            & > svg {
              height: 1rem;
              width: 1rem;
            }
          }

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }
    }
  }
}

.sidebarMenu {
  width: 45%;
  max-width: 500px;
  position: absolute;
  top: 1px;
  bottom: 0;
  right: -150%;
  background: white;
  border-left: 0.25px solid #e5e5e5;
  border-top: 0.25px solid #e5e5e5;
  border-bottom: 0.25px solid #e5e5e5;
  transition: all linear 0.3s;

  & > .eachItemDetailed {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 2rem;

    & > .emptyCard {
      height: 100px;
      padding: 1rem;

      & > div {
        font-size: 0.9rem;
        font-weight: 500;
        color: white;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    & > .allDetails {
      position: relative;
      padding: 0 1rem;

      & > .mainDetail {
        display: flex;
        gap: 1rem;
        transform: translateY(-30px);

        & > .eachPolicy {
          border: 1px solid #e5e5e5;
          background: white;
          border-radius: 25px;
          padding: 1rem;
          width: 100%;

          & > .headDiv {
            height: 80px;
            display: flex;
            gap: 1.2rem;

            & > .imageDiv {
              height: 100%;
              aspect-ratio: 1/1;
              border: 1px solid #e5e5e5;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              & > img {
                width: 100%;
                object-fit: contain;
              }
            }

            & > .companyDetails {
              display: flex;
              flex-direction: column;
              gap: 3px;
              justify-content: space-evenly;
              padding: 10px;

              & > .title {
                font-size: 0.95rem;
                font-weight: 550;
              }

              & > .company {
                font-size: 0.85rem;
              }
            }
          }

          & > .desc {
            font-size: 1rem;
            padding: 2rem 0;
          }

          & > .moreDetails {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            flex-direction: column;

            & > .moreDetailsTitle {
              font-size: 0.9rem;
              font-weight: 400;
              margin-bottom: 8px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            & > .allPolicyVarients {
              display: grid;
              gap: 0.6rem;
              width: 100%;
              font-size: 0.9rem;
              font-weight: 450;
              // max-width: 250px;
              align-items: flex-start;

              & > .eachVarient {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.45rem 1rem;
                border: 1px solid #e1e1e1;
                border-radius: 10px;
                width: 100%;
              }
            }

            & > .amount {
              width: 100%;

              & > :nth-child(1) {
                font-size: 0.8rem;
                font-weight: 450;
              }

              & > :nth-child(2) {
                font-size: 1rem;
                font-weight: 600;
                padding-top: 3px;
              }

              & > .flexDiv {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #e1e1e1;
                border-radius: 10px;
                padding: 0.5rem 1rem;
                width: 100%;
              }

              & > .hoverDiv {
                transition: all linear 0.3s;

                &:hover {
                  background: var(--main-color);
                  color: white;
                }
              }
            }

            & > .btn {
              border: 1px solid #e5e5e5;
              padding: 0.35rem 1.5rem;
              border-radius: 35px;
              font-size: 0.8rem;
              font-weight: 600;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all linear 0.3s;

              &:hover {
                color: white;
                background: var(--main-color);
                border: 1px solid var(--main-color);
              }
            }
          }
        }

        & > img {
          background: white;
          border-radius: 20px;
          width: 90px;
          height: 90px;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          line-height: 2;
          margin-top: auto;
          padding-bottom: 5px;

          & > :nth-child(1) {
            font-size: 1rem;
            font-weight: 500;
          }
          & > :nth-child(2) {
            font-size: 0.75rem;
          }
        }
      }

      & > .desc {
        margin-top: 1rem;

        & > .searchDiv {
          margin-bottom: 2rem;

          & > input {
            padding: 1rem 1.5rem;
            background: #e5e5e540;
            border-radius: 35px;
            outline: none;
            border: none;
            width: 100%;
            font-size: 0.9rem;
          }
        }

        & > .allDesc {
          & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // gap: 25px;
            margin-bottom: 2rem;

            & > :nth-child(1) {
              color: var(--main-color);
              font-size: 2rem;
              font-weight: 600;
              text-align: center;
            }

            & > :nth-child(2) {
              font-size: 0.9rem;
              line-height: 1.5;
              width: 85%;
            }
          }
        }

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .para {
          font-size: 0.85rem;
          margin-top: 1.2rem;
          line-height: 1.6;
        }
      }

      & > .offerings {
        margin-top: 2.5rem;

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .allOfferings {
          margin-top: 1.2rem;

          & > .eachOffering {
            margin-bottom: 1rem;
            border: 1px solid #e5e5e5;
            border-radius: 20px;
            padding: 1rem;
            display: flex;
            gap: 1rem;

            & > :nth-child(1) {
              width: 100px;
              height: 100px;
              padding: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;

              & > svg {
                height: 100%;
              }
            }

            & > :nth-child(2) {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              & > :nth-child(1) {
                & > .title {
                  font-size: 0.9rem;
                  font-weight: 500;
                }

                & > .learnBtn {
                  font-size: 0.7rem;
                  font-weight: 500;
                  text-decoration: underline;
                  margin-top: 10px;
                  cursor: pointer;
                  transition: all linear 0.3s;

                  &:hover {
                    color: #04bfda;
                  }
                }
              }

              & > .priceDiv {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: auto;

                & > :nth-child(1) {
                  font-size: 0.9rem;
                  font-weight: 500;

                  & > span {
                    text-decoration: line-through;
                    opacity: 0.5;
                    margin-left: 5px;
                  }
                }

                & > .btn {
                  font-size: 0.6rem;
                  padding: 7px 1.5rem;
                  border-radius: 35px;
                  cursor: pointer;
                  border: 1px solid #e5e5e5;
                  transition: all linear 0.3s;

                  &:hover {
                    background: #04bfda;
                    color: white;
                    border: 1px solid #04bfda;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  & > .allWellnessItems {
    padding: 1.5rem;

    & > .title {
      font-size: 1.2rem;
      font-weight: 550;
      padding: 1rem 0 2rem 0;
    }

    & > .allItems {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      gap: 1.5rem;
      flex-wrap: wrap;

      & > .eachWellnessItem {
        max-width: 150px;
        cursor: pointer;
        transition: all linear 0.3s;

        & > img {
          width: 100%;
          border-radius: 30px;
          aspect-ratio: 1/1;
          transition: all linear 0.3s;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          font-size: 1rem;
          font-weight: 500;
          padding: 1rem 0;
          text-align: center;
          transition: all linear 0.3s;
        }

        &:hover {
          & > img {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }

          & > div {
            font-weight: 550;
          }
        }
      }
    }
  }

  & > .dependentsContainer {
    padding: 2rem;

    & > .title {
      font-size: 1.4rem;
      font-weight: 500;
    }

    & > .allDependents {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      & > .eachDependent {
        font-size: 0.9rem;
        font-weight: 500;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 1.5rem;
        transition: all linear 0.3s;

        &:hover {
          border: 1px solid var(--main-color);
        }
      }
    }
  }
}

.seeDetails {
  width: 100% !important;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  & > .title {
    font-size: 1rem;
    font-weight: 550;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > span {
      font-size: 1.2rem;
      cursor: pointer;
    }
  }

  & > .gridFour {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 100px repeat(3, 1fr);
    margin-bottom: 1.2rem;
    gap: 1rem;

    & > :nth-child(1) {
      min-width: unset;
    }
  }
  & > .gridThree {
    display: grid;
    align-items: flex-end;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.2rem;
    gap: 1rem;

    & > :nth-child(1) {
      min-width: unset;
    }
  }

  & > .tableContainer {
    overflow: scroll;

    .tableParent {
      // width: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      margin: 0rem;
      overflow: scroll;
      min-width: unset;
      max-width: 100%;

      thead {
        background: #e1e1e1;
        border-radius: 10px 10px 0 0;
        width: 100%;

        & > tr {
          border-radius: 10px 10px 0 0;
        }
      }

      tbody {
        width: 100%;
        & > tr {
          border: none;
          background: white;
        }

        & > .noDataMessage {
          padding: 1rem;
          font-weight: 500;
          text-align: center;
        }
      }

      th,
      td {
        padding: 0.5rem;
        white-space: nowrap;
        border: none;
      }

      th {
        text-align: left;
        font-weight: 550;
      }

      td {
        padding: 1rem 0.5rem;
      }

      tr {
        display: grid;
        grid-template-columns: 40px repeat(4, 1fr) 60px;
        // padding: 0 0.5rem;

        & > :first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 1.2rem;
        }

        & > :nth-child(2) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & > :last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 1.2rem;
        }
      }
    }
  }
}

//add dependents

.addDependents {
  width: 600px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  /* max-width: 500px; */
  /* border-left: 0.5px solid #e5e5e5; */
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  /* right: 0; */
  padding: 2rem;
  overflow-y: scroll;
  background: white;
  z-index: 1;
  transition: all linear 0.3s;

  & > .title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  & > .allInputsParent {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    & > .twoInputContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      & > :nth-child(2) {
        & > input {
          margin-top: 0;
        }
      }
    }
  }

  & > .submitButton {
    padding: 0.7rem 2.5rem;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    background: var(--main-color);
    color: white;
    border-radius: 7px;
    margin-top: 2rem;
    width: max-content;
    min-width: 220px;
  }
}

.addPolicies {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  overflow: hidden !important;

  & > .allPolicies {
    display: flex;
    // align-items: center;
    gap: 1rem;
    overflow-x: scroll;
    padding: 1.5rem 1rem;
    padding: 2rem;
    padding-right: 0;
    width: 100%;

    & > .eachPolicy {
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 10px;
      border: 1px solid #e1e1e1;
      box-shadow: 0px 4px 4px 0px #00000040;
      background: #fff;
      // min-width: 50%;
      height: max-content;
      width: 550px;
      min-width: 550px;
      max-width: 550px;

      & > .headDiv {
        height: 80px;
        display: flex;
        gap: 1.2rem;
        margin-bottom: 1rem;

        & > .imageDiv {
          height: 100%;
          aspect-ratio: 1/1;
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            width: 100%;
            object-fit: contain;
            border: 1px solid #e5e5e5;
            border-radius: 10px;
          }
        }

        & > .companyDetails {
          display: flex;
          flex-direction: column;
          gap: 3px;
          justify-content: space-evenly;
          padding: 10px;

          & > .title {
            font-size: 1.1rem;
            font-weight: 550;
          }

          & > .company {
            font-size: 0.9rem;
          }
        }

        & > .donutBox {
          // width: 20%;
          // max-width: 180px;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          width: max-content;

          & > canvas {
            height: 100%;
            height: max-content;
          }

          & > .totalPerc {
            position: absolute;
            top: 55%;
            left: 48%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & > :nth-child(1) {
              font-size: 0.6rem;
              font-weight: 550;
              text-align: center;
            }
          }
        }
      }

      & > .desc {
        font-size: 0.9rem;
        padding: 2rem 0;
        padding-bottom: 1rem;
      }

      & > .moreDetails {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        flex-direction: column;

        & > .moreDetailsTitle {
          font-size: 0.9rem;
          font-weight: 400;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        & > .allPolicyVarients {
          display: grid;
          gap: 0.6rem;
          width: 100%;
          font-size: 0.9rem;
          font-weight: 450;
          // max-width: 250px;
          align-items: flex-start;

          & > .eachVarient {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.45rem 1rem;
            border: 1px solid #e1e1e1;
            border-radius: 10px;
            width: 100%;
          }
        }

        & > .amount {
          display: flex;
          flex-direction: column;
          align-items: flex-start !important;
          width: 100%;

          & > :nth-child(1) {
            font-size: 0.8rem;
            font-weight: 450;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
          & > :nth-child(2) {
            font-size: 0.9rem;
            font-weight: 550;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #e1e1e1;
            border-radius: 10px;
            // padding-top: 3px;
          }

          & > .flexDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #e1e1e1;
            border-radius: 10px;
            width: 100%;
            margin-bottom: 0.5rem;

            & > div {
              padding: 0.8rem 1rem;
            }
          }

          & > .hoverDiv {
            transition: all linear 0.3s;

            &:hover {
              background: var(--main-color);
              color: white;
            }
          }
        }

        & > :last-child {
          display: flex;
          gap: 10px;
          align-items: flex-end;

          .btn {
            border: 1px solid #e5e5e5;
            padding: 0.35rem 1.5rem;
            border-radius: 35px;
            font-size: 0.8rem;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all linear 0.3s;

            &:hover {
              color: white;
              background: var(--main-color);
              border: 1px solid var(--main-color);
            }
          }
        }

        & > .btn {
          border: 1px solid #e5e5e5;
          padding: 0.35rem 1.5rem;
          border-radius: 35px;
          font-size: 0.8rem;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all linear 0.3s;

          &:hover {
            color: white;
            background: var(--main-color);
            border: 1px solid var(--main-color);
          }
        }
      }
    }

    & > .messageContainer {
      padding: 1rem;
      text-align: center;
      font-weight: 500;
    }
  }

  & > .shadowDiv {
    position: fixed;
    inset: 0;
    background: #00000080;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .balanceDiv {
      padding: 2.5rem;
      background: white;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      & > .logo {
        height: 3rem;
      }

      & > .desc {
        width: 250px;
        text-align: center;
        font-size: 1.1rem;
      }

      & > .btnsContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > button {
          all: unset;
          cursor: pointer;
          padding: 0.5rem 1.5rem;
          border-radius: 7px;
          text-align: center;
          font-weight: 500;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }
        }

        & > .coloredBtn {
          color: white;
          border: 1px solid var(--main-color);
          background: var(--main-color);
        }

        & > .transparentBtn {
          background: white;
          border: 1px solid #e1e1e1;
        }
      }
    }
  }
}

.initiateInsurance {
  background: white !important;
  color: var(--font-color) !important;
  z-index: 6;
  max-height: 65vh;
  overflow-y: scroll;
  top: 2.5rem;

  & > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      cursor: pointer;
    }
  }

  & > .allInputsParent {
    & > .smallDrodown {
      width: 50%;
      max-width: 300px;
    }

    & > .twoInputContainer {
      & > :nth-child(1) {
        & > input {
          margin-top: 0;
        }
      }
    }
  }
}
