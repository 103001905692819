.sectionContainer {
  height: 70vh;
  padding: 0px 10%;
  // padding-top: 10%;
  padding-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-margin-top: 150px;
  scroll-snap-margin-top: 150px;
}
.aboutContainer {
  display: grid;
  grid-template-columns: 45% 40%;
  gap: 10%;
  max-width: 1400px;
}

.leftside {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.desc {
  font-size: 1.2rem;
  font-weight: 400;
  color: "#5F6163";
  // text-align: justify;
  line-height: 40px;
  padding-top: 50px;
}
.rightSide {
  height: 100%;
  padding-top: 40px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
}

.title {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--main-color);
}

.card {
  width: 100%;
  // height: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
  border: 1px solid #e5e5e5;
  height: 128px;
  border-radius: 25px;
  padding: 20px 30px;
  margin-bottom: 20px;
  &:hover {
    // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    cursor: pointer;
    // make the shadow subtle
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
}
.number {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--main-color);
  padding-right: 30px;
  opacity: 0.25;
}
.cardText {
  font-size: 20px;
  font-weight: 400;
  color: "#5F6163";
  // text-align: justify;
  line-height: 32px;
  // padding-top: 70px;
}

// media query

@media only screen and (max-width: 768px) {
  .sectionContainer {
    height: 100%;
    padding: 0px 10%;
    padding-top: 2rem;
    padding-bottom: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-margin-top: 150px;
    scroll-snap-margin-top: 150px;
  }
  .aboutContainer {
    display: grid;
    grid-template-columns: 100%;
    gap: 3rem;
    max-width: 1400px;
  }

  .leftside {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .desc {
    font-size: 1rem;
    font-weight: 400;
    color: "#5F6163";
    // text-align: justify;
    line-height: 30px;
    padding-top: 20px;
  }
  .rightSide {
    height: 100%;
    padding-top: 0px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
  }

  .title {
    font-size: 2.1rem;
    font-weight: 600;
    color: var(--main-color);
  }

  .card {
    width: 100%;
    // height: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
    border: 1px solid #e5e5e5;
    height: 128px;
    border-radius: 25px;
    padding: 20px 30px;
    margin-bottom: 20px;
    &:hover {
      // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
      cursor: pointer;
      // make the shadow subtle
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }
  .number {
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--main-color);
    padding-right: 30px;
    opacity: 0.25;
  }
  .cardText {
    font-size: 16px;
    font-weight: 400;
    color: "#5F6163";
    // text-align: justify;
    line-height: 25px;
    // padding-top: 70px;
  }
}
