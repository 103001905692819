.popupWrapper {
  padding: 28px;
}
.popupTitle {
  color: #5f6163;
  // font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
.dropdown {
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 0px 20px;
}
.inputStyle {
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 0px 20px;
  width: 100%;
  margin-top: 20px;
}
.textareaStyle {
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  width: 100%;
  padding: 10px 20px;
}
.bottomContainer {
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #e9ebf3;
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(217, 217, 217, 0.3);
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
}
.calendarWrapper {
  border-radius: 5px;
  background: #fff;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 20px;
  width: 50%;

  color: #5f6163;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}
.calendarIcon {
  width: 24px;
  height: 24px;
}
.uploadContainer {
  width: 40%;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  background: #fff;
  height: 179px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.upldImg {
  width: 52px;
  height: 52px;
}
.upldTxt {
  color: #5f6163;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-top: 20px;
}

.dropdown1 {
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  background: #fff;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  height: 200px;
  // padding: 0px 20px;
  // position: relative;

  & > :nth-child(1) {
    padding-left: 20px;
    padding-right: 20px;
  }

  & > :nth-child(2) {
    & > div {
      padding-left: 20px;
      padding-right: 20px;
    }
    &::-webkit-scrollbar {
      display: block;
      width: 7px; /* Set the width of the table's scrollbar */
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(
        --font-color
      ); /* Set the color of the scrollbar thumb */
      border-radius: 5px; /* Set the border-radius of the thumb */
      height: 80px;
      transition: all linear 0.3s;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(
        --main-color
      ); /* Set the color when the scrollbar thumb is hovered */
    }
  }
}

.dropdown1Search {
  width: 100%;
  padding: 0.5rem 20px;
  border: none;
  font-size: 0.9rem;
}

.individualQuote {
  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  div:nth-child(1) {
    color: #5f6163;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  div:nth-child(2) {
    color: #5f6163;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.noQuote {
  padding: 0.7rem 1rem;
  text-align: center;
  border: 1px solid #e1e1e1;
  margin: auto 20px;
  margin-top: 3rem;
}
.individualQuote1 {
  &:hover {
    font-weight: 600;
    cursor: pointer;
  }
  color: #5f6163;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 9px;
}
.addBtn {
  margin-top: 20px;
  border-radius: 5px;
  background: #2ec697;
  width: 236px;
  height: 45.564px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  &:hover {
    background-color: white;
    border: 1px solid #2ec697;
    color: #2ec697;
    cursor: pointer;
  }
}
.rowWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
  // justify-content: space-between;
  // align-items: center;
}
.rowWrapper1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;
  // justify-content: space-between;
  // align-items: center;
}
.inputGroup {
  div {
    color: #5f6163;
    text-align: justify;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 7px;
  }
  input {
    border-radius: 5px;
    border: 1px solid #e9ebf3;
    background: #fff;
    height: 46px;
    width: 100%;
    padding-left: 10px;
    &::placeholder {
      color: #5f6163;
      text-align: justify;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      opacity: 0.25;
    }
  }

  .validationInputParent {
    position: relative;
  }

  .dotValidation {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
  }
}

.inputGroup3 {
  div {
    color: #5f6163;
    text-align: justify;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 7px;
  }
  .inputBox {
    margin: 0px !important;
    padding: 0px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #e9ebf3;
    background: #fff;
    height: 46px;
    // width: 100%;
    padding-top: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    &::placeholder {
      color: #5f6163;
      text-align: justify;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      opacity: 0.25;
    }
    input {
      width: 90%;
      height: 40px;
      // border: none;
      outline: none;
    }
  }
}

.inputGroup1 {
  div:nth-child(1) {
    color: #5f6163;
    text-align: justify;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 7px;
  }

  input {
    border-radius: 5px;
    border: 1px solid #e9ebf3;
    background: #fff;
    height: 46px;
    width: 100%;
    padding-left: 10px;
    &::placeholder {
      color: #5f6163;
      text-align: justify;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      opacity: 0.25;
    }
  }
}
.inputGroup2 {
  flex-direction: row;
  align-items: center;
  div {
    color: #5f6163;
    text-align: justify;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 7px;
  }
  input {
    border-radius: 5px;
    border: 1px solid #e9ebf3;
    background: #fff;
    height: 46px;
    width: 100%;
    padding-left: 10px;
    &::placeholder {
      color: #5f6163;
      text-align: justify;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      opacity: 0.25;
    }
  }
}

.quoteDetailsWrapper {
  z-index: 9;
  border: 1px solid #e5e5e5;
  background: #fff;
  width: 65%;
  height: 70vh;
  position: fixed;
  top: 0;
  margin-left: 5%;
  margin-top: 10%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  overflow-y: scroll;
}
.leadSourceWrapper {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
}
.leadSource {
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 193px;
  height: 46px;
  font-size: 13px;
  font-style: normal;
  font-weight: 300 !important;
  margin-right: 10px;
  padding-bottom: 0px !important;
  &:hover {
    border: 1px solid #2ec697;
    color: #2ec697 !important;
    cursor: pointer;
  }
}

.leadSourceSelected {
  border-radius: 5px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 193px;
  height: 46px;
  font-size: 13px;
  font-style: normal;
  font-weight: 300 !important;
  margin-right: 10px;
  padding-bottom: 0px !important;
  border: 1px solid #2ec697;
  color: #2ec697 !important;
  cursor: pointer;
  &:hover {
    border: 1px solid #2ec697;
    color: #2ec697 !important;
    cursor: pointer;
  }
}
.dropDwnTitle {
  color: #5f6163;
  text-align: justify;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 184.615% */
}
.addRowBtn {
  border-radius: 5px;
  border: 2px dashed #e9ebf3;
  background: #fff;
  height: 46px;
  width: 20%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
  padding: 0 !important;
  // float: right;
  color: gray !important;
  &:hover {
    border: 2px dashed gray;
    color: gray !important;
    cursor: pointer;
  }
}
.addRowBtn1 {
  border-radius: 5px;
  border: 2px dashed #e9ebf3;
  background: #fff;
  height: 46px;
  width: 20%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
  padding: 0 !important;
  // float: right;
  color: red !important;
  &:hover {
    border: 1px dashed red;
    color: red !important;
    cursor: pointer;
  }
}

.addRowBtn2 {
  border-radius: 5px;
  border: 2px dashed #e9ebf3;
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
  padding: 0 !important;
  // float: right;
  color: gray !important;
  font-weight: 600;
  &:hover {
    border: 1px dashed gray;
    color: gray !important;
    cursor: pointer;
  }
}

.editRaterCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start !important;

  & > div {
    border: none !important;
    margin-bottom: 5px;
    gap: 10px;

    & > div {
      & > :nth-child(1) {
        font-size: 0.75rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .saveRaterCard {
      padding: 0.5rem 1.2rem;
      color: white;
      background: var(--main-color);
      border: 1px solid var(--main-color);
      border-radius: 6px;
      cursor: pointer;
      text-align: center;
    }

    .cancelBtn {
      padding: 0.5rem 1.2rem;
      color: white;
      background: #ea0f0f;
      border: 1px solid #ea0f0f;
      border-radius: 6px;
      cursor: pointer;
      text-align: center;
    }

    & > .editRaterCardDropdown {
      width: 125px;
      font-weight: 500 !important;

      & > div {
        width: 100%;
        margin-bottom: 0;

        & > div {
          width: 100%;

          & > :last-child {
            overflow-y: scroll;
            height: calc(100% - 30px);

            & > div {
              padding: 5px 20px;

              & > div {
                font-weight: 500;
                font-size: 0.8rem;
              }
            }
          }

          & > img {
            height: 8px !important;
          }
        }
      }
    }

    & > .inputBox {
      width: 200px;

      & > input {
        border-radius: 5px;
        border: 1px solid #e9ebf3;
        background: #fff;
        height: 46px;
        width: 100%;
        padding-left: 10px;
      }
    }
  }
}

//date picker custom input

.eachInputDatePicker {
  position: relative;

  & > .title {
    font-size: 0.85rem;
    font-weight: 400;
  }

  & > .inputContainer {
    color: var(--font-color);
    padding: 1rem 1.5rem;
    border-radius: 10px;
    border: 1px solid #a4a6a7;
    font-size: 0.8rem;
    margin-top: 8px;
    width: 100%;
    max-width: 500px;
    transition: all linear 0.3s;
    position: relative;

    // &:focus {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
    // &:hover {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
  }

  & > .calendarIcon {
    position: absolute;
    bottom: 1rem;
    right: 15px;
    height: 1rem;
    width: 1rem;
    z-index: 5;
  }
}
