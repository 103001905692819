.profile {
  height: 100%;

  & > .navbar {
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    background: white;

    & > .leftContainer {
      & > .navbarOption {
        padding: 1.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        width: max-content;
        cursor: pointer;
      }

      & > .navbarOptionSelected {
        color: var(--main-color);
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 35px 35px 0 0;
          background: var(--main-color);
        }
      }
    }

    & > .rightContainer {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > .navBtn {
        font-size: 0.8rem;
        border-radius: 35px;
        background: var(--main-color);
        padding: 0.5rem 1.5rem;
        color: white;
        font-weight: 550;
        transition: all linear 0.3s;
        cursor: pointer;
        border: 1px solid var(--main-color);

        &:hover {
          border: 1px solid #e5e5e5;
          background: white;
          color: var(--main-color);
        }
      }

      & > .navOppBtn {
        border: 1px solid #e5e5e5;
        background: white;
        color: var(--font-color);
        font-size: 0.8rem;
        // font-weight: 500;
        border-radius: 35px;
        padding: 0.5rem 1.5rem;
        transition: all linear 0.3s;
        cursor: pointer;

        &:hover {
          color: white;
          background: var(--main-color);
          border: 1px solid var(--main-color);
        }
      }
    }
  }

  & > .topMenuOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(229, 229, 229, 0.2117647059);
    padding: 2rem 2.5rem;
    padding-bottom: 0;

    & > .filterDiv {
      display: flex;
      align-items: center;
      border: 1px solid #e1e1e1;
      background: white;
      gap: 0;
      border-radius: 5px;
      font-size: 0.9rem;

      & > .filterBtn {
        // width: 3rem;
        // padding: 12px 0;
        // height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 51px;
        // height: 50px;
        // border-right: 1px solid #e5e5e5;
        position: relative;
        height: 100%;
        // width: 100%;
        padding: 0.5rem 0;

        & > svg {
          fill: var(--font-color);
          transition: all linear 0.3s;

          &:hover {
            fill: var(--main-color);
          }
        }

        & > .dropdownContent {
          position: absolute;
          top: 70%;
          left: 55%;
          padding: 1rem;
          background: white;
          z-index: 6;
          border: 1px solid #e1e1e1;
          box-shadow: 0px 4px 4px 0px #00000040;
          border-radius: 10px;
          // min-width: calc(max-content + 4rem);
          min-width: 250px;

          & > .eachFilterItem {
            padding: 0.7rem 0.5rem;
            font-size: 1rem;
            font-weight: 400;
            cursor: pointer;
            white-space: nowrap;
            // padding-right: 4rem;

            & > svg {
              rotate: -90deg;
            }

            &:hover {
              color: var(--main-color);
            }
          }
        }
      }

      & > :nth-child(1) {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        height: 100%;
        border-left: none !important;
        border-radius: 5px;
      }

      & > div {
        border-left: 1px solid #e1e1e1;
        & > input {
          width: 200px;
          padding: 0.7rem 1.5rem;
          border: none;
          outline: none;
          border-radius: 5px;
          font-weight: 450;
        }
      }
    }

    & > .pincodeDropdown {
      display: flex;
      align-items: center;
      gap: 15px;
      height: 3rem;

      & > span {
        font-size: 0.9rem;
        white-space: nowrap;
      }
    }

    & > .buttonsDiv {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 0.9rem;

      & > .coloredBtn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.7rem 1.5rem;
        border-radius: 5px;
        border: 1px solid var(--main-color);
        color: white;
        font-weight: 450;
        cursor: pointer;
        background: var(--main-color);
        transition: all linear 0.3s;

        &:hover {
          background: white;
          color: var(--main-color);
        }

        & > .dropdownContent {
          position: absolute;
          top: 130%;
          left: 0rem;
          z-index: 5;
          background: white;
          border-radius: 10px;
          padding: 1rem;
          border: 1px solid #e1e1e1;
          transition: all linear 0.3s;
          overflow: scroll;
          max-height: 65vh;
          width: max-content;
          max-width: 600px;
          overflow-y: scroll;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

          & > .menuDropdown {
            color: var(--font-color);

            & > div {
              padding: 10px 0;
            }
          }

          & > .allClaimsForm {
            color: var(--font-color);

            & > .title {
              font-size: 1.4rem;
              font-weight: 400;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 2rem;

              & > :nth-child(2) {
                display: flex;
                align-items: center;
                font-size: 1rem;
                cursor: pointer;
              }
            }

            & > .formContainer {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              margin-top: 2rem;

              & > .flexDivTwo {
                display: flex;
                gap: 1rem;

                & > :nth-child(2) {
                  width: 150px;
                }
              }
              & > .flexDivTwoSame {
                display: flex;
                gap: 1rem;

                & > div {
                  width: 40%;
                }
              }

              & > .flexDivThree {
                display: flex;
                gap: 1rem;

                & > div {
                  width: 37.5%;
                }

                & > :nth-child(2) {
                  width: 25%;
                }
              }

              & > .submitButton {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.5rem 1.5rem;
                border-radius: 10px;
                background: var(--main-color);
                font-size: 0.8rem;
                font-weight: 450;
                cursor: pointer;
                color: white;
                border: 1px solid var(--main-color);
                width: max-content;
                transition: all linear 0.3s;

                &:hover {
                  color: var(--main-color);
                  background: white;
                }
              }

              & > .noteMessage {
                font-size: 0.8rem;
                font-weight: 400;

                & > span {
                  font-weight: 550;
                }
              }
            }
          }
        }
      }

      & > .normalBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.7rem 1.5rem;
        border-radius: 5px;
        border: 1px solid #e7e7e7;
        color: var(--font-color);
        font-weight: 450;
        cursor: pointer;
        background: white;
        transition: all linear 0.3s;

        &:hover {
          background: white;
          color: var(--main-color);
          border: 1px solid var(--main-color);
        }
      }
    }
  }

  & > .proactiveDetails {
    padding: 0 2.5rem;
    padding-top: 2rem;
    height: calc(100% - 10rem);
    background: #e5e5e536;
    // overflow-y: scroll;

    & > .mainFlexTitle {
      font-size: 1.2rem;
      font-weight: 550;
      padding: 1.5rem 0;
    }

    & > .mainFlex {
      width: 100%;
      height: 45%;
      background: white;
      border-radius: 35px;
      display: flex;
      padding: 1.5rem 2rem;
      gap: 2.5rem;
      max-height: 400px;

      & > img {
        height: 100%;
        object-fit: contain;
      }

      & > .policyTypes {
        width: -webkit-fill-available;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.3rem;

        & > .eachType {
          display: flex;
          gap: 1rem;
          // height: 32%;
          max-height: 65px;
          cursor: pointer;
          transition: all linear 0.3s;

          &:hover {
            transform: translateX(10px);
          }

          & > .imgDiv {
            padding: 0.25rem;
            background: #2ec6971a;
            border-radius: 10px;
            aspect-ratio: 1/1;
            display: flex;
            justify-content: center;
            align-items: center;

            & > img {
              width: 50%;
              object-fit: contain;
            }
          }

          & > .contentDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            & > .title {
              font-size: 1.1rem;
              font-weight: 600;
            }

            & > .content {
              font-size: 0.95rem;
            }
          }
        }
      }
    }

    & > .otherGrids {
      display: flex;
      flex-direction: column;
      // gap: 1.5rem;
      height: 100%;
      overflow-y: scroll;
      // padding-bottom: 3rem;
      // margin-top: 0.5rem;

      & > div {
        width: 50%;
        height: 100%;
      }

      .gridTitle {
        font-size: 1.2rem;
        font-weight: 550;
        padding: 1.5rem 0;
      }

      & > :nth-child(1) {
        width: 100%;

        & > .box {
          // height: calc(100% - 4.5rem);
          border-radius: 35px;
          padding: 1.5rem 0;
          display: flex;
          align-items: center;
          overflow-x: scroll;
          gap: 1.5rem;

          & > .eachPolicy {
            border: 1px solid #e5e5e5;
            border-radius: 25px;
            padding: 1rem;
            width: 33%;
            max-width: 600px;
            min-width: 400px;
            scale: 0.9;
            cursor: pointer;
            background: white;
            transition: all linear 0.3s;

            & > .headDiv {
              height: 80px;
              display: flex;
              gap: 1.2rem;

              & > .imageDiv {
                height: 100%;
                aspect-ratio: 1/1;
                border: 1px solid #e5e5e5;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                & > img {
                  width: 100%;
                  object-fit: contain;
                }
              }

              & > .companyDetails {
                display: flex;
                flex-direction: column;
                gap: 3px;
                justify-content: space-evenly;
                padding: 10px;

                & > .title {
                  font-size: 1.1rem;
                  font-weight: 550;
                }

                & > .company {
                  font-size: 0.9rem;
                }
              }
            }

            & > .desc {
              font-size: 0.8rem;
              padding: 2rem 0;
            }

            & > .moreDetails {
              display: flex;
              justify-content: space-between;
              align-items: center;

              & > .amount {
                & > :nth-child(1) {
                  font-size: 0.8rem;
                  font-weight: 450;
                }
                & > :nth-child(2) {
                  font-size: 1rem;
                  font-weight: 600;
                  padding-top: 3px;
                }
              }

              & > .btn {
                border: 1px solid #e5e5e5;
                padding: 0.35rem 1.5rem;
                border-radius: 35px;
                font-size: 0.8rem;
                font-weight: 600;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all linear 0.3s;

                &:hover {
                  color: white;
                  background: var(--main-color);
                  border: 1px solid var(--main-color);
                }
              }
            }
          }

          & > .eachPolicySelected {
            scale: 1;
            border: 1px solid var(--font-color);
          }
        }
      }

      & > .policyNumbers {
        width: 100%;
        height: max-content !important;

        & > .box {
          display: flex;
          gap: 1rem;
          width: 100%;
          overflow-x: scroll;
          padding: 2rem 0;

          & > .eachBox {
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            padding: 1.5rem 2rem;
            width: 33%;
            cursor: pointer;
            background: white;
            transition: all linear 0.3s;

            &:hover {
              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }

            & > .title {
              font-size: 1.2rem;
              font-weight: 550;
              display: flex;
              align-items: center;
              gap: 0.5rem;

              & > span {
                font-size: 0.8rem;
              }

              & > .positiveAmount {
                color: var(--main-color);
              }

              & > .negativeAmount {
                color: #ea0f0f;
              }
            }

            & > .status {
              font-size: 0.9rem;
              padding: 0.5rem 0;
              white-space: nowrap;
            }
          }
        }
      }

      & > .trackerTable {
        width: 100%;
        // margin-top: 1rem;

        & > .navbar {
          display: flex;
          border-bottom: 1px solid #e5e5e5;
          padding: 0.5rem 1rem;
          font-size: 0.9rem;

          & > .leftNavContainer {
            display: flex;
            align-items: center;
            // gap: 2rem;
            width: -webkit-fill-available;

            & > img {
              width: 18px;
              height: 18px;
            }

            & > div {
              font-weight: 500;
              display: flex;
              align-items: center;
              cursor: pointer;
              margin-left: 15px;
            }

            & > .selectedNavOption {
              color: var(--main-color);
              font-weight: 600;
            }
          }

          & > .rightNavContainer {
            display: flex;
            align-items: center;
            font-weight: 500;
            gap: 1rem;

            & > .searchDiv {
              border: 1px solid #e5e5e5;
              border-radius: 35px;
              position: relative;
              width: max-content;

              & > input {
                border-radius: 35px;
                outline: none;
                border: none;
                padding: 0.5rem 3rem;
                font-size: 0.8rem;
                font-weight: 500;
                width: 100%;
                max-width: 300px;
                color: var(--font-color);

                :focus {
                  border: 2px solid var(--main-color);
                }

                &::placeholder {
                }
              }

              & > svg {
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translate(0%, -50%);
                height: 15px;
                width: 15px;
                fill: var(--font-color);
              }
            }

            & > .filterBtn {
              border: 1px solid #e5e5e5;
              border-radius: 35px;
              padding: 0.5rem 3rem;
              font-size: 0.8rem;
              background: white;
            }
          }
        }

        & > .tableParent {
          overflow: scroll;

          height: 100%;

          &::-webkit-scrollbar {
            display: block;
            width: 7px; /* Set the width of the table's scrollbar */
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            height: 7px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(
              --font-color
            ); /* Set the color of the scrollbar thumb */
            border-radius: 5px; /* Set the border-radius of the thumb */
            height: 80px;
            transition: all linear 0.3s;
            cursor: pointer;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: var(
              --main-color
            ); /* Set the color when the scrollbar thumb is hovered */
          }

          & > .tableTitles {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            width: 140%;

            & > div {
              padding: 1.5rem 1rem;
              font-size: 0.83rem;
              white-space: nowrap;
            }
          }
          & > .tableBody {
            width: 140%;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            & > .eachItem {
              display: grid;
              grid-template-columns: repeat(8, 1fr);
              background: white;
              border-radius: 10px;
              border: 1.5px solid white;
              transition: all linear 0.3s;
              cursor: pointer;

              & > div {
                padding: 1.5rem 1rem;
                font-size: 0.8rem;
                display: flex;
              }

              & > .verticalFields {
                flex-direction: column;
                gap: 7px;

                & > :nth-child(1) {
                  font-weight: 550;
                }
              }

              & > .singleFields {
                align-items: center;
              }

              &:hover {
                border: 1.5px solid var(--font-color);
              }
            }
          }
        }
      }
    }

    & > .allLeads {
      border: 1px solid #e5e5e5;
      // border-radius: 35px 35px 0 0;
      // margin-top: 2rem;
      height: calc(100% - 5rem);
      overflow: hidden;
      background: white;
    }

    & > .paginationBar {
      // position: absolute;
      // left: 0;
      // right: 0;
      // bottom: 0;
      height: 3rem;
      padding: 5px 2rem;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #dddddd;
      border-top: none;

      & > .paginationMsg {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
      }

      & > .allPagination {
        margin-left: auto;
        display: flex;
        gap: 10px;
        height: 100%;
        width: max-content;

        & > .eachBtn {
          height: 100%;
          aspect-ratio: 1/1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          border: 1px solid #e7e7e7;
          font-size: 0.9rem;
          font-weight: 500;
          cursor: pointer;
          background: #e5e5e553;
          transition: all linear 0.3s;

          &:hover {
            color: white;
            background: var(--main-color);
          }
        }

        & > .eachBtnCurrent {
          color: white;
          background: var(--main-color);
        }
      }
    }
  }

  & > .paginationBar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3rem;
    padding: 5px 2rem;
    background: white;

    & > .allPagination {
      margin-left: auto;
      display: flex;
      gap: 10px;
      height: 100%;
      width: max-content;

      & > .eachBtn {
        height: 100%;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid #e7e7e7;
        font-size: 0.9rem;
        font-weight: 500;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          color: white;
          background: var(--main-color);
        }
      }

      & > .eachBtnCurrent {
        color: white;
        background: var(--main-color);
      }
    }
  }
}

.eachInput {
  & > .inputContainer {
    border-radius: 10px;
    border: 1px solid #a4a6a7;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    width: 100%;
    max-width: 300px;
    font-weight: 450;
    color: var(--font-color);

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }
  }

  & > .inputDiv {
    position: relative;
    max-width: 300px;
    margin-top: 1rem;

    & > .inputContainer {
      padding: 1rem 1.5rem;
      border-radius: 35px;
      border: 1px solid #e5e5e566;
      font-size: 0.8rem;
      background: #e5e5e566;
      width: 100%;

      padding-right: 2.1rem;

      &:focus {
        outline: var(--main-color);
        background: white;
        border: 1.5px solid var(--main-color);
      }
    }

    & > .selectedBanner {
      background: white;
      padding: 0.6rem 1.5rem;
      font-size: 0.9rem;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translate(0%, -50%);
      border-radius: 35px;
      font-weight: 500;
    }

    & > .asteriskIcon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.eachProfileInputTextarea {
  border-radius: 10px;
  border: 1px solid #a4a6a7;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  transition: all linear 0.2s;
  background: white;
  position: relative;
  max-width: 400px;

  & > .inputParent {
    & > .inputContainer {
      font-size: 0.8rem;
      font-weight: 450;
      outline: none;
      border: none;
      width: 100%;
    }

    & > .status {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 5px;
      align-items: center;

      & > svg {
        font-size: 0.8rem;
        width: 1.1rem;
        height: 1.1rem;
      }

      & > span {
        font-size: 0.8rem;
      }
    }

    & > .trashCan {
      background: #2ec69726;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      right: -4.5rem;
      transform: translate(-50%, -50%);

      & > svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border: 1.25px solid var(--main-color);
  }
}

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 42px;
  cursor: pointer;
  max-width: 300px;

  & > .inputContainer {
    border-radius: 10px;
    border: 1px solid #a4a6a7;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    position: absolute;
    bottom: 0.3rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    font-weight: 450;
    transition: all linear 0.3s;

    &:focus {
      background: white;
      border: 1.5px solid var(--main-color);
    }

    &:hover {
      background: white;
      border: 1.5px solid var(--main-color);

      input {
        background: #e5e5e566;
      }
    }

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > :nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

.eachInputDatePicker {
  position: relative;
  max-width: 300px;

  & > .inputContainer {
    color: var(--font-color);
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 0.8rem;
    background: transparent;
    width: 100%;
    font-weight: 450;
    transition: all linear 0.3s;
    border: 1px solid #a4a6a7;
    padding: 0.5rem 1rem;

    // &:focus {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
    // &:hover {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
  }

  & > .calendarIcon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    height: 1rem;
    width: 1rem;
    z-index: 5;
  }
}

// src/Table.scss
.tableContainer {
  overflow-y: auto;
  height: 100%;
  width: 100%; /* Set the container width to 100% or any other desired value */
  overflow-x: auto; /* Allow horizontal scrolling */
  // border-left: 0.25px solid #e5e5e5;
  // border-right: 0.25px solid #e5e5e5;
  position: relative;

  &::-webkit-scrollbar {
    display: block;
    width: 7px; /* Set the width of the table's scrollbar */
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(
      --font-color
    ); /* Set the color of the scrollbar thumb */
    border-radius: 5px; /* Set the border-radius of the thumb */
    height: 80px;
    transition: all linear 0.3s;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --main-color
    ); /* Set the color when the scrollbar thumb is hovered */
  }

  table {
    border-collapse: collapse;
    min-width: 240%; /* Set the table width to 110% to ensure horizontal scrollbar */
    margin-bottom: 20px;
    table-layout: fixed; /* Ensures equal column width for horizontally scrolling table */
    width: max-content;
    height: 100%;

    th,
    td {
      text-align: left;
      white-space: normal;
      word-wrap: break-word;
      padding: 0.75rem 1rem;
      position: relative;
      display: flex;
      align-items: center;
      border-right: none;
      border-top: none;
      font-size: 0.85rem;

      & > input {
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      & > .processing {
        background: #f8cd70;
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }

      & > .processed {
        background: #2ec697;
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }

      & > .rejected {
        background: #ea0f0f;
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }
    }

    th {
      white-space: nowrap;
      font-weight: 550;
      font-size: 0.85rem;
      padding: 0.7rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg {
        cursor: pointer;
      }
    }

    thead {
      height: 47.06px;
      background: #e1e1e1;
      & > tr {
        & > th {
          border-top: none;
        }
      }
    }

    tbody {
      position: relative;
      height: calc(100% - 47.06px);

      & > tr {
        border-bottom: 1px solid #dddddd;

        & > :nth-child(1) {
          font-size: 1rem;
          cursor: pointer;
        }
      }
    }

    tr {
      & > :nth-child(1) {
        border-left: none;
        width: 50px;
      }

      & > :last-child {
        border-right: none;
      }
    }

    tr {
      display: grid;
      grid-template-columns: min-content repeat(15, 1fr);
    }

    tr > :first-child {
      /* Set the width you want for the first child */
      width: 50px; /* Replace 200px with your desired width */
      /* Position the first child in the first column */
      grid-column: 1;
    }

    .detailedItem {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      & > :nth-child(1) {
        padding: 0.5rem;
        border: 0.5px solid #e5e5e5;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 70%;
          height: 70%;
        }
      }

      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        & > :nth-child(1) {
          font-size: 0.9rem;
          font-weight: 550;
          white-space: nowrap;
        }
        & > :nth-child(2) {
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    td {
      position: relative;
      transition: all linear 0.3s;

      &:hover {
        background: #8080800d;
      }

      & > .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }

      .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > span {
            display: flex;
            gap: 10px;
            align-items: center;

            & > svg {
              height: 1rem;
              width: 1rem;
            }
          }

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }
    }
  }
}

.basicDetails {
  position: absolute;
  top: 1rem;
  left: 3rem;
  border: 1px solid red;
  right: 3rem;
  background: white;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 1.5rem;
  max-width: 70vw;

  & > .title {
    font-size: 1.1rem;
    font-weight: 520;
    margin: 1rem 0;
  }

  & > .detailsContainer {
    font-size: 0.9rem;

    & > .basicDisplayContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      & > .eachDisplayDetails {
        display: flex;
        gap: 1rem;
        font-size: 0.9rem;

        & > :nth-child(1) {
          font-weight: 550;
          width: 200px;
        }

        & > :nth-child(2) {
          width: calc(100% - 200px);
        }
      }
    }

    & > .flexContainer {
      display: flex;
      gap: 1rem;
      align-items: center;
      // justify-content: space-between;
      margin-top: 1.5rem;

      & > div {
        // width: 22.5%;
        padding: 8px 2.5rem;
        border-radius: 7px;
        border: 1px solid #e5e5e5;
        cursor: pointer;
        text-align: center;
        font-weight: 450;
      }

      & > .selectedSection {
        background: var(--main-color);
        color: white;
        font-weight: 500;
      }
    }

    & > .switchingContainer {
      font-size: 0.8rem;
      margin: 1rem 0;
      margin-top: 1.5rem;

      & > .subHeading {
        font-size: 0.9rem;
        font-weight: 500;
        margin: 1rem 0;
      }

      & > .uploadDocument {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 1rem;

        & > .title {
        }

        & > .uploadBtn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 400px;
          font-size: 0.9rem;

          & > :nth-child(1) {
            display: flex;
            align-items: center;
            gap: 5px;
          }

          & > button {
            padding: 5px 15px;
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            outline: none;
            cursor: pointer;
            font-size: 0.8rem;
          }
        }
      }

      & > .noteDiv {
        font-size: 0.9rem;
        margin-top: 3rem;

        & > span {
          font-weight: 550;
        }
      }

      & > .tableContainer {
        & > .tableHead {
          display: grid;
          grid-template-columns: 1fr 0.7fr 80px 1fr;
          gap: 1rem;
          font-weight: 550;
          align-items: center;

          & > div {
            padding: 0.5rem 1rem;
          }
        }

        & > .tableBody {
          & > div {
            display: grid;
            grid-template-columns: 1fr 0.7fr 80px 1fr;
            gap: 1rem;
            margin-bottom: 0.5rem;

            & > div {
              padding: 0.5rem 1rem;
              overflow: hidden;
              text-wrap: wrap;
              text-overflow: ellipsis;
            }

            & > :nth-child(3) {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  & > .closeBtn {
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
  }
}
