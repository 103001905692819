.profile {
  height: 100%;
  position: relative;

  & > .navbar {
    padding: 1.2rem 2rem;
    display: flex;
    justify-content: space-between;
    background: white;

    & > .leftContainer {
      display: flex;

      & > .searchDiv {
        border: 2px solid #e5e5e5;
        border-radius: 35px;
        position: relative;
        width: max-content;

        & > input {
          border-radius: 35px;
          outline: none;
          border: none;
          padding: 0.7rem 6rem;
          padding-left: 3rem;
          font-size: 0.8rem;
          width: 100%;
          max-width: 400px;

          :focus {
            border: 2px solid var(--main-color);
          }

          &::placeholder {
            opacity: 0.4;
          }
        }

        & > svg {
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translate(0%, -50%);
          height: 15px;
          width: 15px;
        }
      }
    }

    & > .pincodeDropdown {
      display: flex;
      align-items: center;
      gap: 15px;

      & > span {
        font-size: 0.9rem;
      }
    }

    & > .doubleDropdown {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      & > .pincodeDropdown {
        display: flex;
        align-items: center;
        gap: 15px;

        & > span {
          font-size: 0.9rem;
        }
      }
    }

    & > .rightContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      width: 30%;
      font-size: 0.9rem;
      font-size: 600;
      white-space: nowrap;
      gap: 1rem;

      & > div {
      }

      & > .rightBtn {
        background: var(--main-color);
        padding: 0.7rem 1rem;
        color: white;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 550;
        transition: all linear 0.3s;

        &:hover {
          background: white;
          border: 1px solid #e5e5e5;
          color: var(--main-color);
        }
      }
    }
  }

  & > .loadingParent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 90px);

    & > img {
      width: 10rem;
      object-fit: contain;
      animation: heartbeat 1.3s infinite;
    }

    @keyframes heartbeat {
      0% {
        transform: scale(0.75);
      }
      20% {
        transform: scale(1);
      }
      40% {
        transform: scale(0.75);
      }
      60% {
        transform: scale(1);
      }
      80% {
        transform: scale(0.75);
      }
      100% {
        transform: scale(0.75);
      }
    }
  }

  & > .topMenuOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(229, 229, 229, 0.2117647059);
    padding: 2rem 2.5rem;
    // padding-bottom: 0;

    & > .pincodeDropdown {
      display: flex;
      align-items: center;
      gap: 15px;
      height: 3rem;

      & > span {
        font-size: 0.9rem;
        white-space: nowrap;
      }
    }

    & > .buttonsDiv {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 0.9rem;

      & > .coloredBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.7rem 1.5rem;
        border-radius: 5px;
        border: 1px solid var(--main-color);
        color: white;
        font-weight: 450;
        cursor: pointer;
        background: var(--main-color);
        gap: 10px;
        transition: all linear 0.3s;

        & > svg {
          stroke: white;
          height: 0.9rem;
          width: 0.9rem;
          transition: all linear 0.3s;
        }

        &:hover {
          background: white;
          color: var(--main-color);

          & > svg {
            stroke: var(--main-color);
          }
        }
      }

      & > .normalBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.7rem 1.5rem;
        border-radius: 5px;
        border: 1px solid #e7e7e7;
        color: var(--font-color);
        font-weight: 450;
        cursor: pointer;
        background: white;
        transition: all linear 0.3s;

        &:hover {
          background: white;
          color: var(--main-color);
          border: 1px solid var(--main-color);
        }
      }
    }
  }

  & > .myProfileDetails {
    padding: 0 2.5rem;
    height: calc(100% - 12rem);
    background: #e5e5e536;
    // overflow-y: scroll;

    & > .mainDiv {
      & > .detailedItem {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: flex-start;
        padding: 1.5rem 0;
        margin-bottom: 1.5rem;

        & > :nth-child(1) {
          padding: 0.2rem;
          border: 0.5px solid #e5e5e5;
          border-radius: 50%;
          width: 6rem;
          height: 6rem;
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            width: 5.6rem;
            height: 5.6rem;
            border-radius: 50%;
          }
        }

        & > :nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;

          & > :nth-child(1) {
            font-size: 1.2rem;
            font-weight: 450;
            white-space: nowrap;
          }
          & > :nth-child(2) {
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }

      & > .diffSections {
        display: flex;
        gap: 0.5rem;
        margin-bottom: 1.5rem;

        & > div {
          padding: 0.5rem 1.5rem;
          border: 1px solid #e5e5e5;
          border-radius: 35px;
          cursor: pointer;
          font-size: 0.8rem;
          font-weight: 500;
        }

        & > .selectedSection {
          border: 1px solid var(--main-color);
          color: var(--main-color);
        }
      }
    }

    & > .selectedSectionMain {
      & > .personalDetails {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        padding-top: 1.5rem;
      }

      & > .contactDetails {
        padding-bottom: 3rem;

        & > div {
          padding-top: 2rem;

          & > .title {
            font-size: 1rem;
            font-weight: 550;
            margin-bottom: 0.5rem;
          }

          & > .addNewBtn {
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;

            & > .icon {
              font-size: 0.9rem;
              width: 1.2rem;
              height: 1.2rem;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              background: var(--main-color);
            }

            & > span {
              font-size: 0.9rem;
              font-weight: 500;
            }
          }
        }
      }

      & > .assetsDetails {
        padding-top: 1.5rem;

        & > .title {
          font-size: 1rem;
          font-weight: 550;
          margin-bottom: 0.5rem;
        }

        & > .assetsContainer {
          display: flex;
          flex-wrap: wrap;
          gap: 1.5rem;
          padding-top: 1rem;

          & > .eachAsset {
            padding: 1rem;
            border-radius: 20px;
            border: 1px solid #e5e5e5;
            width: 20%;
            aspect-ratio: 1/1;
            display: flex;
            position: relative;
            overflow: hidden;
            background: white;
            transition: all linear 0.3s;

            &:hover {
              border: 1px solid #5f6163;
              box-shadow: 0px 4px 4px 0px #00000040;
            }

            & > div {
              width: 50%;
            }

            & > :nth-child(1) {
              height: 50%;
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              font-size: 1.2rem;
              font-weight: 550;
            }

            & > :nth-child(2) {
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;

              & > .shadeArea {
                position: absolute;
                bottom: 0;
                right: -2rem;
                background: #2ec69714;
                border-radius: 0 75px 0 25px;
                rotate: -90deg;
                top: 65%;
                width: 60%;
              }

              & > svg {
                width: 55%;
                object-fit: contain;
                margin-right: 20%;
                margin-bottom: 30%;
              }
            }
          }
        }
      }
    }
  }
}

//custom dropdown

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 2.5rem;
  cursor: pointer;
  transition: all linear 0.2s;

  & > .inputContainer {
    padding: 0.6rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 0rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    transition: all ease-in 0.2s;

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;

      & > :nth-child(1) {
        align-items: center;
        display: flex;
        gap: 10px;
        width: 90%;

        & > img {
          border-radius: 50%;
          height: 18px;
          width: 18px;
        }

        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .addBtn {
        background: var(--main-color);
        padding: 0.5rem 0;
        color: white;
        font-weight: 550;
        border-radius: 35px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.7rem 0;
      }

      & > img {
        border-radius: 50%;
        height: 18px;
        width: 18px;
      }

      & > li {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 1rem 0rem;
        width: 100%;

        & > img {
          width: 17px;
          height: 17px;
          border-radius: 50%;
        }

        & > svg {
          width: 17px;
          height: 17px;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

//my profile specific

.eachProfileInput {
  border: 1px solid var(--font-color);
  padding: 0.75rem 1.2rem;
  border-radius: 10px;
  transition: all linear 0.2s;
  background: white;
  position: relative;
  max-width: 500px;
  margin-bottom: 1rem;

  & > .title {
    font-size: 0.65rem;
  }

  & > .inputParent {
    & > .inputContainer {
      font-size: 1rem;
      outline: none;
      border: none;
      margin-top: 10px;
    }

    & > .status {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 5px;
      align-items: center;

      & > svg {
        font-size: 0.8rem;
        width: 1.1rem;
        height: 1.1rem;
      }

      & > span {
        font-size: 0.8rem;
      }
    }

    & > .trashCan {
      background: #2ec69726;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      right: -4.5rem;
      transform: translate(-50%, -50%);

      & > svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border: 1.25px solid var(--main-color);
  }
}

//allEmployerList

.allEmployerList {
  height: 100%;
  overflow-y: scroll;

  & > .eachLetterList {
    & > .title {
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: 14px;
      padding: 2rem 0;
      padding-right: 2rem;

      & > h5 {
        font-size: 1.2rem;
        margin: 0;
      }

      & > div {
        height: 1px;
        width: -webkit-fill-available;
        background: var(--font-color);
      }
    }

    & > .companiesList {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;

      & > li {
        aspect-ratio: 1/1;
        background: white;
        border-radius: 15px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        transition: all linear 0.4s;
        cursor: pointer;
        width: 100%;
        // height: 100%;

        &:hover {
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
            rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
        }

        & > .letterSelected {
          font-size: 5rem;
          color: var(--main-color);
          font-weight: 800;
        }

        & > div {
          width: 100%;
          text-align: center;
          overflow: hidden; /* Hide any content beyond the specified height */
          text-overflow: ellipsis; /* Show ellipsis for the overflow text */
          display: -webkit-box; /* For Safari */
          -webkit-box-orient: vertical; /* For Safari */
          -webkit-line-clamp: 2; /* For Safari: Limit the text to 2 lines */
        }
      }
    }
  }

  & > .detailedPopup {
    position: absolute;
    background: #00000080;
    inset: 0;
    padding: 2rem;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .hospitalPopup {
      width: 100%;
      height: max-content;
      padding: 2.5rem 4rem;
      border-radius: 15px;
      background: white;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      z-index: 1;

      & > .flexDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & > .gridDiv {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        & > :nth-child(2) {
          text-align: center;
        }

        & > :nth-child(3) {
          text-align: right;
        }
      }

      .eachItem {
        display: flex;
        flex-direction: column;
        gap: 5px;

        & > .title {
          font-size: 0.95rem;
          text-decoration: underline;
          font-weight: 300;
        }

        & > .mainValue {
          font-size: 1.4rem;
          font-weight: 500;
        }

        & > .value {
          font-size: 0.95rem;
          font-weight: 500;
        }
      }
    }
  }

  & > .allLettersList {
    position: fixed;
    top: 18rem;
    bottom: 7rem;
    overflow-y: scroll;
    right: 2rem;
    display: flex;
    gap: 5px;
    font-size: 0.8rem;
    flex-direction: column;

    & > span {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      transition: all linear 0.3s;

      &:hover {
        color: var(--main-color);
      }
    }
  }
}

//allEmployerListMap

.allEmployerListMap {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  height: 100%;

  & > .hospitalList {
    width: 50%;
    height: 100%;
    overflow-y: scroll;

    & > .heading {
      font-size: 1rem;
      padding: 0.5rem 0;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid #00000033;
    }

    & > div {
      .eachHospital {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        padding: 0.5rem 0;
        border-radius: 10px;
        cursor: pointer;
        padding-left: 5px;
        transition: all linear 0.3s;

        & > .letterSelected {
          background: #2ec69780;
          color: #000000;
          font-weight: 600;
          width: 3rem;
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          transition: all linear 0.3s;
        }

        & > .hospitalName {
          font-size: 1rem;
          font-weight: 400;
          color: #000000;
          transition: all linear 0.3s;
        }

        &:hover {
          background: var(--main-color);

          & > .letterSelected {
            background: white;
            color: var(--main-color);
          }

          & > .hospitalName {
            color: white;
          }
        }
      }
    }

    & > .detailedPopup {
      position: absolute;
      background: #00000080;
      inset: 0;
      padding: 2rem;
      z-index: 6;
      display: flex;
      justify-content: center;
      align-items: center;

      & > .hospitalPopup {
        width: 100%;
        height: max-content;
        padding: 2.5rem 4rem;
        border-radius: 15px;
        background: white;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        z-index: 1;

        & > .flexDiv {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        & > .gridDiv {
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          & > :nth-child(2) {
            text-align: center;
          }

          & > :nth-child(3) {
            text-align: right;
          }
        }

        .eachItem {
          display: flex;
          flex-direction: column;
          gap: 5px;

          & > .title {
            font-size: 0.95rem;
            text-decoration: underline;
            font-weight: 300;
          }

          & > .mainValue {
            font-size: 1.4rem;
            font-weight: 500;
          }

          & > .value {
            font-size: 0.95rem;
            font-weight: 500;
          }
        }
      }
    }
  }

  & > .googleMap {
    width: 50%;
    position: relative;

    & > .allLettersList {
      position: absolute;
      top: 10%;
      bottom: 10%;
      overflow-y: scroll;
      left: -1.5rem;
      display: flex;
      gap: 5px;
      font-size: 0.8rem;
      flex-direction: column;

      & > span {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          color: var(--main-color);
        }
      }
    }
  }
}
