.toggle-switch {
    width: 50px;
    height: 27px;
    // background-color: #ccc;
    border: 1px solid #e7e7e7;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
  }
  
  .slider {
     position: absolute;
     top:2.5px; 
     bottom:2.5px; 
     left:2.5px; 
     width:20px; 
     height:20px; 
     background-color:#2EC697; 
     border-radius:50%; 
     transition:.3s; 
  }
  
  .active{
      transform : translateX(23px);
  }