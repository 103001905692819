.ereceipt {
  width: 100vw;
  height: 100vh;
  background: #2ec697;
  display: flex;
  justify-content: center;
  padding: 0 2.5rem;
  padding-bottom: 1.75rem;
  width: 600px;
  height: 739px;
}

.innerBox {
  background: white;
  border-radius: 0 0 35px 35px;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  max-width: 900px;
  max-height: 800px;
}

.logoBox {
  padding: 1rem 2rem;
  background: #2ec697;
  border-radius: 0 0 35px 35px;
  width: max-content;
  height: max-content;
}

.title {
  font-size: 1.5rem;
  color: #5f6163;
  font-weight: 550;
  margin: 2.5rem 0;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.eachDetailTab {
  width: calc(100% - 3rem);
  padding: 0.5rem 0.75rem;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  font-size: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
