.walletDetails {
  padding: 2rem;
  padding-bottom: 0;
  // overflow-y: scroll;
  height: calc(100% - 4rem);
  background: #e5e5e536;
  overflow-y: scroll;

  & > .allBalances {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .totalBalance {
      & > .balance {
        font-size: 2.2rem;
        font-weight: 600;
      }

      & > .walletType {
        font-size: 0.9rem;

        svg {
          cursor: pointer;
        }
      }
    }
  }

  & > .dropdownItems {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    margin: 2rem 0;
  }

  & > .transactionsContainer {
    height: calc(100% - 6rem);
    overflow-y: scroll;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > .eachTransaction {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 1.5rem;
      cursor: pointer;
      transition: all linear 0.3s;
      padding: 1rem 5px;
      border-radius: 15px;

      &:hover {
        background: white;
      }

      & > .imageContainer {
        height: 50px;

        & > img {
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }

      & > .detailContainer {
        display: flex;
        flex-direction: column;
        // align-items: center;
        gap: 3px;
        width: calc(65% - 70px);

        & > :nth-child(1) {
          font-size: 0.95rem;
          font-weight: 500;
        }

        & > :nth-child(2) {
          font-size: 0.8rem;
          font-weight: 400;
        }
      }

      & > .allTransaction {
        display: grid;
        align-items: center;
        justify-content: flex-end;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        font-size: 1rem;
        font-weight: 500;
        width: calc(35% - 70px);

        & > div {
          text-align: end;
        }

        & > :nth-child(1) {
          color: #3ea254;
        }
        & > :nth-child(2) {
          color: #ea0f0f;
        }
        & > :nth-child(3) {
          color: #5f6163;
        }
      }
    }
  }

  & > .overviewDetails {
    display: flex;
    gap: 1rem;

    & > .eachDetails {
      padding: 2rem 3rem 2rem 2rem;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      background: white;
      position: relative;

      & > .selectRaterCard {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
      }

      & > .value {
        font-size: 1.7rem;
        font-weight: 500;
      }

      & > .title {
        font-size: 0.9rem;
      }
    }

    & > .transactionBtn {
      background: var(--main-color);
      color: white;
      cursor: pointer;
      padding: 10px 2.5rem;
      outline: none;
      border: none;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-top: auto;
      font-size: 0.9rem;
      position: relative;

      & > .addTransactionForm {
        position: absolute;
        top: 120%;
        right: 0;
        padding: 1.5rem;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
        border-radius: 10px;
        min-width: 250px;
        width: max-content;
        color: var(--font-color);
        overflow-y: scroll;
        max-height: 60vh;

        & > .title {
          font-size: 1rem;
          font-weight: 550;
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > span {
            cursor: pointer;
          }
        }

        & > .inputFields {
          display: flex;
          flex-direction: column;
          align-items: end;
          gap: 1rem;
          width: 100%;
          margin-top: 1rem;

          & > .flexDiv {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            align-items: end;
            width: 100%;
          }
        }

        & > .addBtn {
          padding: 10px 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: white;
          margin-top: 2rem;
          background: var(--main-color);
          width: max-content;
          border-radius: 35px;
        }
      }

      & > span {
        font-weight: 520;
      }
    }
  }

  & > .allLeads {
    border: 1px solid #e5e5e5;
    // border-radius: 35px 35px 0 0;
    // margin-top: 2rem;
    height: calc(100% - 12rem);
    overflow: hidden;
    background: white;
    margin-top: 2rem;

    & > .paginationBar {
      // position: absolute;
      // left: 0;
      // right: 0;
      // bottom: 0;
      height: 3rem;
      padding: 5px 2rem;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #dddddd;
      // border-top: none;

      & > .paginationMsg {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
      }

      & > .allPagination {
        margin-left: auto;
        display: flex;
        gap: 10px;
        height: 100%;
        width: max-content;

        & > .eachBtn {
          height: 100%;
          aspect-ratio: 1/1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          border: 1px solid #e7e7e7;
          font-size: 0.9rem;
          font-weight: 500;
          cursor: pointer;
          background: #e5e5e553;
          transition: all linear 0.3s;

          &:hover {
            color: white;
            background: var(--main-color);
          }
        }

        & > .eachBtnCurrent {
          color: white;
          background: var(--main-color);
        }
      }
    }
  }
}

// src/Table.scss
.tableContainer {
  // overflow-y: auto;
  height: calc(100% - 3rem);
  width: 100%; /* Set the container width to 100% or any other desired value */
  // overflow-x: auto; /* Allow horizontal scrolling */
  // border-left: 0.25px solid #e5e5e5;
  // border-right: 0.25px solid #e5e5e5;
  position: relative;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block;
    width: 7px; /* Set the width of the table's scrollbar */
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(
      --font-color
    ); /* Set the color of the scrollbar thumb */
    border-radius: 5px; /* Set the border-radius of the thumb */
    height: 80px;
    transition: all linear 0.3s;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --main-color
    ); /* Set the color when the scrollbar thumb is hovered */
  }

  table {
    border-collapse: collapse;
    min-width: 240%; /* Set the table width to 110% to ensure horizontal scrollbar */
    // margin-bottom: 20px;
    table-layout: fixed; /* Ensures equal column width for horizontally scrolling table */
    width: max-content;
    height: 100%;
    width: 100%;

    th,
    td {
      // border: 1px solid #dddddd;
      text-align: left;
      white-space: normal;
      word-wrap: break-word;
      padding: 0.75rem 1rem;
      position: relative;
      display: flex;
      align-items: center;
      border-right: none;
      border-top: none;
      font-size: 0.85rem;

      & > input {
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      & > .processing {
        background: #f8cd70;
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }

      & > .processed {
        background: #2ec697;
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }

      & > .rejected {
        background: #ea0f0f;
        color: white;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
      }
    }

    th {
      white-space: nowrap;
      font-weight: 550;
      font-size: 0.85rem;
      padding: 0.7rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg {
        cursor: pointer;
      }
    }

    thead {
      background: #e1e1e1;
      & > tr {
        & > th {
          border-top: none;
        }
      }
    }

    tbody {
      position: relative;
      overflow-y: scroll;
    }

    tr {
      border-bottom: 1px solid #dddddd;

      & > :nth-child(1) {
        border-left: none;
        // width: 50px;
      }

      & > :last-child {
        border-right: none;
      }
    }

    tr {
      display: grid;
      grid-template-columns: min-content repeat(15, 1fr);
    }

    tr > :first-child {
      /* Set the width you want for the first child */
      // width: 50px; /* Replace 200px with your desired width */
      /* Position the first child in the first column */
      grid-column: 1;
    }

    .detailedItem {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      & > :nth-child(1) {
        padding: 0.5rem;
        border: 0.5px solid #e5e5e5;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 70%;
          height: 70%;
        }
      }

      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        & > :nth-child(1) {
          font-size: 0.9rem;
          font-weight: 550;
          white-space: nowrap;
        }
        & > :nth-child(2) {
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    td {
      position: relative;
      transition: all linear 0.3s;

      &:hover {
        background: #8080800d;
      }

      & > .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }

      .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > span {
            display: flex;
            gap: 10px;
            align-items: center;

            & > svg {
              height: 1rem;
              width: 1rem;
            }
          }

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }
    }
  }
}
