.newBrokerageParent {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  & > .newBrokerage {
    height: calc(100% - 85px);
    overflow-y: scroll;

    & > .title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 2rem;
      padding-top: 0.5rem;
    }

    & > .sectionTitle {
      color: var(--main-color);
      background: #2ec69726;
      padding: 0.75rem 0.5rem;
      font-weight: 600;
      margin-bottom: 2rem;
      position: relative;
      border-radius: 10px;
    }

    & > .inputContainers {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      & > .eachInput {
        & > .inputContainer {
          border: 1.5px solid #e1e1e1;
          font-weight: 450;
          transition: all linear 0.3s;

          &:focus {
            background: white;
            border: 1.5px solid var(--main-color);
          }
          &:hover {
            background: white;
            border: 1.5px solid var(--main-color);
          }
        }
      }
    }
  }

  & > .btnsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2rem;

    & > div {
      padding: 1rem 1.5rem;
      border-radius: 35px;
      font-size: 0.8rem;
      margin-top: 1rem;
      width: 100%;
      max-width: 500px;
      cursor: pointer;
      color: white;
      font-size: 1rem;
      font-weight: 550;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all linear 0.3s;

      &:hover {
        scale: 1.05;
      }
    }

    & > :nth-child(1) {
      background: #5f6163;
    }
    & > :nth-child(2) {
      background: var(--main-color);
    }
  }
}

.eachInput {
  & > .title {
    font-size: 0.9rem;
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: #e5e5e566;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }
  }
}

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 6rem;
  cursor: pointer;

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    bottom: 0.3rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    border: 1.5px solid #e1e1e1;
    font-weight: 450;
    background: rgba(229, 229, 229, 0.4);
    transition: all linear 0.3s;

    &:focus {
      background: white;
      border: 1.5px solid var(--main-color);
    }

    &:hover {
      background: white;
      border: 1.5px solid var(--main-color);

      input {
        background: #e5e5e566;
      }
    }

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > :nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

.imageUploadDiv {
  // line-height: 2;
  font-size: 0.9rem;
  // font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  // margin-top: 2rem;

  & > .imageDiv {
    // margin-top: 0.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    & > img {
      min-width: 8vw;
      min-height: 8vw;
      max-width: 8vw;
      max-height: 8vw;
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      transition: all ease 0.4s;
      padding: 2rem;

      // &:hover {
      //   scale: 0.95;
      //   // border: 5px solid #44c2f4;
      // }
    }

    & > .overlayDiv {
      position: absolute;
      width: 20%;
      height: 20%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & > img {
        animation: heartBeat 0.5s alternate infinite;
      }

      @keyframes heartBeat {
        0% {
          scale: 1;
        }
        100% {
          scale: 1.2;
        }
      }
    }
  }

  & > .uploadFileDiv {
    font-size: 1.1rem;
    transition: all ease 0.4s;
    width: 40%;

    &:hover {
      font-size: 1.3rem;
      font-weight: 550;
    }
    & > .uploadNewPicPlanB {
      display: none;
    }
  }
}

.imageUploadDivProfilePic {
  // line-height: 2;
  font-size: 0.9rem;
  // font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  // margin-top: 2rem;

  & > .imageDiv {
    // margin-top: 0.5rem;
    padding: 2.5rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e5;
    font-size: 0.8rem;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      min-width: 5vw;
      min-height: 5vw;
      max-width: 5vw;
      max-height: 5vw;
      border-radius: 50%;
      transition: all ease 0.4s;

      // &:hover {
      //   scale: 0.95;
      //   // border: 5px solid #44c2f4;
      // }
    }

    & > .overlayDiv {
      position: absolute;
      width: 20%;
      height: 20%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & > img {
        animation: heartBeat 0.5s alternate infinite;
      }

      @keyframes heartBeat {
        0% {
          scale: 1;
        }
        100% {
          scale: 1.2;
        }
      }
    }
  }

  & > .uploadFileDiv {
    font-size: 1.1rem;
    transition: all ease 0.4s;
    width: 40%;

    &:hover {
      font-size: 1.3rem;
      font-weight: 550;
    }
    & > .uploadNewPicPlanB {
      display: none;
    }
  }
}
