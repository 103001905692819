.navbarv3 {
  height: 5rem;
  display: flex;
  padding: 1rem 2.5rem;
  background: rgba(229, 229, 229, 0.2117647059);

  & > div {
    border-radius: 35px;
    border: 1px solid #e1e1e1;
    // overflow: hidden;

    & > div {
      border-radius: 35px !important;
      border: none !important;
    }
  }

  & > :nth-child(2) {
    margin-left: auto;
    margin-right: 1.5rem;
  }

  & > .associateDropdown {
    display: flex;
    // overflow-x: hidden;
    padding-right: 1rem;
    background: white;
    position: relative;

    & > :nth-child(1) {
      width: 100px;
    }

    & > :nth-child(2) {
      width: calc(100% - 100px);
    }
  }

  & > .mergedProfile {
    display: flex;
    background: white;
    border-radius: 35px;
    position: relative;
    // margin-left: auto;
    // overflow: hidden;

    & > .profileDiv {
      // border-left: 1px solid #e7e7e7;
      // border-right: 1px solid #e7e7e7;
      width: max-content;
      position: relative;
      cursor: pointer;
      border-radius: 35px 0 0 35px;

      & > :nth-child(1) {
        display: flex;
        height: 100%;
        align-items: center;
        padding: 1rem;
        gap: 0.5rem;
        font-size: 0.9rem;
        color: var(--font-color);
        white-space: nowrap;
        position: relative;
        z-index: 5;
        background: white;
        border-radius: 35px;

        & > .nameDetails {
          display: flex;
          flex-direction: column;

          & > :nth-child(1) {
            font-size: 0.85rem;
            font-weight: 500;
          }

          & > :nth-child(2) {
            font-size: 0.7rem;
          }
        }

        & > img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
        }

        & > .profileLetter {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          font-size: 0.8rem;
          background: var(--main-color);
          color: white;
          font-weight: 550;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      & > .dropdownContent {
        position: absolute;
        top: 100%;
        // border: 1px solid green;
        // width: 100%;
        margin-top: 5px;
        left: -1px;
        right: -1px;
        z-index: 5;
        background: white;
        border: 1px solid #e1e1e1;
        // box-shadow: 0px 4px 4px 0px #00000040;
        // border-radius: 10px;
        // min-width: 250px;
        // border-left: 1px solid #e7e7e7;
        // border-right: 1px solid #e7e7e7;
        // border-bottom: 1px solid #e7e7e7;
        transition: all linear 0.3s;
        overflow: scroll;
        max-height: 500px;
        display: flex;
        flex-direction: column;
        border-radius: 35px;

        & > .eachContent {
          // width: 102;
          padding: 0.7rem 1rem;
          font-size: 1rem;
          font-weight: 500;
          color: var(--font-color);
          transition: all linear 0.2s;
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 10px;

          & > svg {
            width: 1.2rem;
            height: 1.2rem;
            stroke: var(--font-color);
          }

          &:hover {
            background: var(--main-color);
            color: white;

            & > svg {
              stroke: white;
            }
          }
        }
      }
    }

    & > .notificationIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 5rem;
      width: 5rem;
      position: relative;
      border-radius: 0 35px 35px 0;

      & > svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--font-color);
        cursor: pointer;
      }

      & > .cartIcon {
        display: flex;
        align-items: center;
        position: relative;

        & > img {
          padding: 0.5rem 1rem;
        }

        & > .totalItems {
          background: var(--main-color);
          color: white;
          font-size: 0.9rem;
          font-weight: 500;
          position: absolute;
          top: 5px;
          right: 5px;
          aspect-ratio: 1/1;
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }
      }

      & > .dropdownContent {
        position: absolute;
        top: 120%;
        right: 1rem;
        z-index: 7;
        background: white;
        border-radius: 10px;
        padding: 1rem;
        border: 1px solid #e1e1e1;
        transition: all linear 0.3s;
        overflow: scroll;
        max-height: 500px;
        width: 400px;
        overflow-y: scroll;
        padding-bottom: 0;

        &::-webkit-scrollbar {
          display: block;
          width: 7px; /* Set the width of the table's scrollbar */
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 7px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(
            --font-color
          ); /* Set the color of the scrollbar thumb */
          border-radius: 5px; /* Set the border-radius of the thumb */
          height: 80px;
          transition: all linear 0.3s;
          cursor: pointer;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: var(
            --main-color
          ); /* Set the color when the scrollbar thumb is hovered */
        }

        & > .singleNotification {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          margin-bottom: 2rem;

          & > .heading {
            display: flex;
            align-items: center;
            gap: 1px;

            & > div {
              display: flex;
              align-items: center;
              gap: 3px;
            }

            & > .title {
              font-size: 0.8rem;
              font-weight: 500;

              & > img {
                height: 1rem;
                border-radius: 3px;
              }
            }

            & > .duration {
              font-size: 0.7rem;
              font-weight: 400;
            }

            & > .closeBtn {
              margin-left: auto;
              font-size: 0.8rem;
              font-weight: 550;
              cursor: pointer;
            }
          }

          & > .notificationTitle {
            font-size: 0.9rem;
            font-weight: 500;
            margin: 3px 0;
          }

          & > .notificationMessage {
            font-size: 0.75rem;
            font-weight: 400;
          }

          & > .notificationImage {
            width: 100%;
            object-fit: contain;
            border-radius: 10px;
          }

          & > .notificationBtn {
            border-radius: 5px;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #00000040;
            background: #fff;
            font-weight: 550;
            cursor: pointer;
            font-size: 0.8rem;
            padding: 0.5rem;
            text-align: center;
            transition: all linear 0.3s;

            &:hover {
              background: var(--main-color);
              color: white;
            }
          }
        }
      }
    }
  }

  & > :last-child {
    margin-right: 0;
  }
}

.customDropdown {
  position: relative;
  display: flex;
  width: max-content;
  min-width: 240px;
  //   border: 1px solid blue;
  max-width: 400px;
  height: 100%;
  cursor: pointer;
  border-radius: 10px;

  & > .dropdownBtn {
    display: flex;
    gap: 1.5rem;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 520;
    width: 100%;
    user-select: none;
    white-space: nowrap;
    position: relative;
    z-index: 7;
    background: white;

    & > :nth-child(1) {
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > .dropdownContent {
    position: absolute;
    top: 115%;
    // border: 1px solid green;
    // width: 100%;
    left: -1px;
    right: -1px;
    z-index: 7;
    background: white;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    transition: all linear 0.3s;
    overflow: scroll;
    max-height: 500px;
    border-radius: 20px !important;
    padding: 0.5rem;
    transition: all linear 0.5s;

    & > .eachContent {
      padding: 1.2rem 1rem;
      font-size: 1rem;
      font-weight: 500;
      color: var(--font-color);
      border-radius: 10px;
      transition: all linear 0.2s;

      &:hover {
        background: var(--main-color);
        color: white;
      }
    }
  }

  & > .overflowContainer {
    position: fixed;
    background: #00000080;
    inset: 0;
    z-index: 6;
    border-radius: 0 !important;
  }
}
