.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: var(--main-color);

  & > .sidebar {
    padding: 1.5rem;
    // height: calc(100% - 60px);
    height: calc(100% - 4rem);

    & > .fullLogo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1.5rem;
      height: 100px;

      & > img {
        height: 55%;
        object-fit: contain;
        max-width: 70%;
      }

      & > :nth-child(2) {
        display: flex;
        gap: 10px;

        & > div {
          height: 100%;

          & > div {
            height: 40px;
            width: 40px;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff73;

            & > svg {
              color: white;
            }

            &:hover {
              border: 0.5px solid white;
              background: var(--main-color);
            }
          }

          & > svg {
            height: 45%;
            object-fit: contain;
            cursor: pointer;
            fill: white;
            // transition: all ease-in 0.4s;
            border-radius: 10px;

            // & > rect {
            //   transition: all ease-in 0.4s;
            // }

            &:hover {
              border: 0.5px solid white;

              & > rect {
                fill: var(--main-color);
              }
            }
          }
        }
      }
    }

    & > .navigations {
      padding: 0 0.25rem;
      // overflow-y: scroll;
      height: calc(100% - 6.5rem);
      display: flex;
      flex-direction: column;

      & > .eachCategory {
        margin-top: 0.2rem;
        margin-bottom: 1.8rem;
        overflow-y: scroll;

        & > .title {
          font-size: 1rem;
          color: white;
          font-weight: 550;
          margin-bottom: 0.75rem;
        }

        & > .optionsParent {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 1rem;
          justify-content: space-between;
          width: 100%;

          & > .eachOption {
            display: flex;
            // flex-direction: column;
            gap: 1rem;
            color: white;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            width: 100%;
            // background: white;
            color: var(--main-color);
            border-radius: 10px;
            padding: 0.75rem 1rem;
            // transition: all linear 0.1s;

            & > :nth-child(1) {
              aspect-ratio: 1/1;
              // background: #ffffff;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              // padding: 1rem;

              & > svg {
                height: 1.7rem;
                width: 1.7rem;
                fill: white;
              }
            }

            & > .title {
              font-size: 1rem;
              font-weight: 550;
              text-align: center;
              color: white;
            }

            &:hover {
              background: white !important;

              & > :nth-child(1) {
                & > svg {
                  fill: var(--main-color);
                }
              }

              & > .title {
                color: var(--main-color);
              }
            }
          }

          & > .selectedDashboardItem {
            background: white !important;

            & > :nth-child(1) {
              & > svg {
                fill: var(--main-color);
              }
            }

            & > .title {
              color: var(--main-color);
            }
          }
        }
      }

      & > .alert {
        width: 100%;
        aspect-ratio: 1/1.4 !important;
        background: #ffffff73;
        border-radius: 25px;
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        color: white;

        & > img {
          width: 70%;
        }

        & > .title {
          font-size: 0.9rem;
          font-weight: 550;
        }

        & > .seeQuotes {
          background: var(--main-color);
          border-radius: 20px;
          padding: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.95rem;
          cursor: pointer;

          & > img {
            width: 15px;
            height: 15px;
            margin-right: 8px;
          }
        }

        & > .btnsContainer {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          font-weight: 500;
          font-size: 0.8rem;

          & > .confirmBtns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

            & > div {
              width: 50%;
              cursor: pointer;
              padding: 7px;
              border-radius: 35px;
              text-align: center;
              transition: all linear 0.3s;

              &:hover {
                scale: 1.02;
              }
            }

            & > :nth-child(1) {
              background: var(--main-color);
            }
            & > :nth-child(2) {
              background: var(--main-red);
            }
          }

          & > .newQuoteBtn {
            cursor: pointer;
            padding: 7px;
            border-radius: 35px;
            text-align: center;
            background: #5f6163bd;
            transition: all linear 0.3s;

            &:hover {
              scale: 1.02;
            }
          }
        }
      }

      & > .rahaxUpgrade {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        background: white;
        border-radius: 35px;
        color: var(--main-color);
        font-size: 1rem;
        font-weight: 600;
        padding: 1.5rem;
        cursor: pointer;
        // width: calc(100% - 2rem);
        // position: absolute;
        // left: 50%;
        // bottom: 1.2rem;
        // transform: translate(-50%, 0%);
        transition: all ease-in 0.4s;
        background: #75d9ba;
        color: white;
        // aspect-ratio: 1/1;
        width: 100%;

        & > .title {
          font-size: 1.2rem;
          padding-bottom: 0.5rem;
        }

        & > img {
          width: 80%;
          object-fit: contain;
        }

        & > .description {
          font-size: 0.9rem;
          padding: 1rem 0;
        }

        & > .upgradeBtn {
          padding: 0.5rem 2rem;
          background: var(--main-color);
          color: white;
          border-radius: 35px;
          cursor: pointer;
          width: max-content;
          cursor: pointer;
          transition: all linear 0.3s;
          font-size: 0.9rem;
          width: 100%;
          text-align: center;

          &:hover {
            color: var(--main-color);
            background: white;
          }
        }
      }
    }

    & > .dashboardHam {
      padding: 1rem 1rem;
      // overflow-y: scroll;
      // height: calc(100% - 2.5rem);
      height: calc(100% - 100px);
      background: white;
      border-radius: 30px;
      position: relative;
      z-index: 1;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem 0.5rem;

        & > div {
          font-size: 1rem;
          padding: 0.5rem;
          cursor: pointer;
          transition: all ease-in 0.3s;
          display: flex;
          align-items: center;
          gap: 10px;

          & > img {
            width: 20px;
            height: 20px;
          }

          &:hover {
            transform: translateX(10px);
            font-weight: 600;
          }
        }

        & > .selectedOption {
          background: #2ec69726;
          color: var(--main-color);
          font-weight: 600;
          border-radius: 35px;
        }

        & > .logoutBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--main-color);
          border-radius: 35px;
          color: white;
          font-size: 1rem;
          font-weight: 600;
          padding: 0.7rem 0;
          cursor: pointer;
          width: calc(100% - 2rem);
          position: absolute;
          left: 50%;
          bottom: 0.5rem;
          transform: translate(-50%, -50%);
          transition: all ease-in 0.4s;

          &:hover {
            scale: 1.025;
          }
        }
      }

      // & > :nth-child(1) {
      //   border-bottom: 1px solid #999999;
      // }
    }

    & > .popularActions {
      padding: 1rem 1rem;
      // overflow-y: scroll;
      // height: calc(100% - 2.5rem);
      height: calc(100% - 100px);
      background: white;
      border-radius: 30px;
      position: relative;
      z-index: 1;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem 0.5rem;
        padding-bottom: 5rem;
        position: relative;
        height: 100%;

        & > .title {
          font-size: 1.5rem;
          font-weight: 550;
          color: var(--main-color);
          padding: 0.5rem;
        }

        & > div {
          font-size: 1rem;
          padding: 0.75rem;
          cursor: pointer;
          transition: all ease-in 0.3s;

          &:hover {
            transform: translateX(10px);
            font-weight: 600;
          }
        }

        & > .selectedAction {
          background: #2ec69726;
          color: var(--main-color);
          font-weight: 500;
          border-radius: 35px;
        }

        & > .backBtn {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border: 1px solid #e5e5e5;
          border-radius: 35px;
          text-align: center;
        }

        & > .changeOption {
          & > .title {
            font-size: 0.8rem;
            text-decoration: underline;
            font-weight: 550;
            padding-bottom: 1.2rem;
          }

          & > .options {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            font-size: 1rem;

            & > div {
              cursor: pointer;
              transition: all linear 0.3s;

              &:hover {
                transform: translateX(15px);
                font-weight: 600;
              }
            }
          }

          & > .uploadDocument {
            border: 1px solid #e5e5e5;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
            width: 100%;
            font-size: 0.9rem;

            & > img {
              width: 50px;
              height: 50px;
            }

            & > span {
              font-size: 0.9rem;
            }

            & > .textArea {
              width: 100%;
              padding: 1rem;
              border: none;
              outline: none;
              border-radius: 15px;

              &::placeholder {
                color: #5f616380;
              }
            }
          }
        }

        & > .changeOptionBorder {
          border-bottom: 1px solid #e5e5e5;
          padding-bottom: 1.5rem;
        }
      }

      & > .updateBtn {
        position: absolute;
        bottom: 1rem;
        background: var(--main-color);
        font-size: 1rem;
        left: 1rem;
        right: 1rem;
        padding: 0.5rem 0;
        text-align: center;
        cursor: pointer;
        border-radius: 35px;
        color: white;
      }
    }
  }

  & > .actionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color);
    border: 1px solid white;
    border-radius: 35px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.7rem 0;
    cursor: pointer;
    width: calc(100% - 2rem);
    position: absolute;
    left: 50%;
    bottom: 4.2rem;
    transform: translate(-50%, -50%);
    transition: all ease-in 0.4s;

    &:hover {
      scale: 1.025;
    }
  }

  & > .rahaxUpgrade {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    background: white;
    border-radius: 35px;
    color: var(--main-color);
    font-size: 1rem;
    font-weight: 600;
    padding: 1.5rem 4rem 1.5rem 1.5rem;
    cursor: pointer;
    width: calc(100% - 2rem);
    position: absolute;
    left: 50%;
    bottom: 1.2rem;
    transform: translate(-50%, 0%);
    transition: all ease-in 0.4s;
    background: #75d9ba;
    color: white;
    // aspect-ratio: 1/1;

    & > .title {
      font-size: 1.2rem;
      padding-bottom: 0.5rem;
    }

    & > img {
      width: 80%;
      object-fit: contain;
    }

    & > .description {
      font-size: 0.9rem;
      padding: 1rem 0;
    }

    & > .upgradeBtn {
      padding: 0.5rem 2rem;
      background: var(--main-color);
      color: white;
      border-radius: 35px;
      cursor: pointer;
      width: max-content;
      cursor: pointer;
      transition: all linear 0.3s;
      font-size: 0.9rem;

      &:hover {
        color: var(--main-color);
        background: white;
      }
    }
  }

  & > .dashboardToggle {
    position: relative;
    background: var(--main-color);
    margin: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    border: 1px solid #ffffff;
    border-radius: 35px;
    margin-top: auto;
    margin-bottom: 1rem;

    & > .lockDiv {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #ffffff;
      padding: 0rem 1.5rem;
      cursor: pointer;

      & > img {
        width: 1rem;
        height: 1rem;
      }
    }

    & > span {
      width: -webkit-fill-available;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-right: 1px solid #ffffff;
      padding: 0.7rem 2rem;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 35px 0 0 35px;
      height: 100%;
      gap: 12px;
      transition: all linear 0.3s;

      & > svg {
        fill: white;
        width: 1rem;
        height: 1rem;
        transition: all linear 0.3s;
      }

      &:hover {
        color: var(--main-color);
        background: white;

        & > svg {
          fill: var(--main-color) !important;
        }
      }
    }

    & > :nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: -webkit-fill-available;
    }
  }
}

@media screen and (max-width: 1200px) {
  .dashboard {
    & > .sidebar {
      & > .navigations {
        & > .eachCategory {
          & > .optionsParent {
            // grid-template-columns: repeat(2, 1fr);
          }
        }

        & > .alert {
          width: 100%;
          aspect-ratio: 1/1.2;
          background: #ffffff;
          border-radius: 15px;
        }
      }
    }
  }
}
