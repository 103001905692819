.profile {
  height: 100%;

  & > .navbar {
    padding: 0rem 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    & > .leftContainer {
      display: flex;

      & > .navbarOption {
        padding: 1.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        width: max-content;
        cursor: pointer;
      }

      & > .navbarOptionSelected {
        color: var(--main-color);
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 35px 35px 0 0;
          background: var(--main-color);
        }
      }
    }

    & > .rightContainer {
    }
  }

  & > .brokerageDetails {
    padding: 2rem 2.5rem;
    padding-bottom: 0;
    padding-top: 0;
    overflow-y: scroll;
    height: calc(100% - 5rem);
    background: #8080800d;
    position: relative;

    & > .overallScore {
      display: flex;
      gap: 0.5rem;
      position: relative;

      & > .eachScoreCRM {
        width: 25%;
        padding: 1rem;
        border: 1px solid #e9ebf3;
        border-radius: 10px;
        background: white;
        gap: 10px;

        & > :nth-child(1) {
          height: calc(100% - 2rem);
          display: flex;

          & > .detailDiv {
            width: calc(100% - 2rem);
            & > .scoreIconDiv {
              display: flex;

              // justify-content: center;
              align-items: center;
              margin-bottom: 5px;

              & > .scoreIcon {
                height: 4rem;
              }
            }

            & > .descDiv {
              display: flex;
              flex-direction: column;
              // gap: 5px;

              & > .title {
                font-size: 0.85rem;
                opacity: 0.6;
              }

              & > .score {
                font-size: 1.2rem;
                font-weight: 500;
              }
            }
          }

          & > img {
            width: 1rem;
            height: 1rem;
            cursor: pointer;
          }
        }

        & > :nth-child(2) {
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 15px;

          & > .growthDiv {
            & > span {
              color: var(--main-color);
              font-weight: 600;
              font-size: 0.75rem;
            }
          }
        }
      }

      & > .detailedOverview {
        position: absolute;
        right: -2rem;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    & > .tableContainer {
      position: relative;
      height: calc(80% - 2rem);
      // overflow-y: scroll;
      margin-top: 2rem;
      background: white;
      border: 1px solid #e9ebf3;
      border-radius: 10px;

      & > .graphBoxes {
        width: 100%;
        // height: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-between;

        & > div {
          border: 1px solid #e9ebf3;
          width: calc(50% - 1rem);
          // height: 100%;
          background: white;
          border-radius: 10px;
          padding: 1rem;

          & > canvas {
            height: 90% !important;
          }

          & > .headerDiv {
            height: 4rem;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            padding: 0.5rem 1rem;

            & > .title {
              font-size: 1rem;
              font-weight: 500;
              display: flex;
              align-items: center;

              & > .miniTitle {
                font-size: 0.75rem;
              }

              & > .valueTitle {
                font-weight: 550;
                font-size: 1.2rem;
                display: flex;
                align-items: center;

                & > span {
                  font-size: 0.7rem;
                  color: var(--main-color);
                  margin-left: 10px;
                  display: flex;
                  gap: 5px;
                  align-items: center;
                }
              }
            }

            & > .otherOptions {
              display: flex;
              gap: 10px;
              align-items: center;

              & > .lables {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 0.8rem;

                & > span {
                  width: 1rem;
                  height: 1rem;
                  border-radius: 50%;
                }
              }
            }
          }

          & > .canvasDiv {
            height: calc(100% - 4rem);
            // height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            padding: 1rem;
            // position: relative;

            & > img {
              max-height: 100%;
              width: 100%;
            }

            .lockedDiv {
              position: absolute;
              inset: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: not-allowed;
              backdrop-filter: blur(10px);
              border-radius: 10px;
              overflow: hidden;
              z-index: 1;

              & > .title {
                font-size: 1rem;
                font-weight: 500;
                position: absolute;
                top: 0.8rem;
                z-index: 1;
              }

              & > svg {
                stroke: black;
                width: 1.2rem;
                height: 1.2rem;
                position: absolute;
                z-index: 2;
              }

              & > .opacityDiv {
                width: 100%;
                height: 100%;
                opacity: 0.1;
                position: absolute;
                inset: 0;
                z-index: 1;
                background: white;
              }
            }
          }
        }

        & > :nth-child(2) {
          width: 55%;
        }

        & > :nth-child(1) {
          width: calc(45% - 1.5rem);

          & > .detailDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            // gap: 1.4rem;
            // height: calc(100% - 2rem);
            padding-top: 1rem;

            & > .balanceParent {
              width: -webkit-fill-available;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              & > .title {
                font-size: 1.3rem;
                font-weight: 450;
              }

              & > .balanceDiv {
                font-weight: 600;
                font-size: 1.9rem;
                line-height: normal;
              }

              & > .growthDiv {
                display: flex;
                gap: 6px;
                align-items: center;
                font-weight: 400;
                font-size: 0.9rem;

                & > svg {
                  width: 1rem;
                  height: 1rem;
                  stroke: var(--main-color);
                  color: var(--main-color);
                }
              }

              & > .btnContainer {
                display: flex;
                justify-content: center;
                width: 100%;
                font-size: 0.9rem;
                text-align: center;
                padding: 0.5rem 1.5rem;
                border-radius: 10px;
                background: var(--main-color);
                color: #fff;
                font-weight: 520;
                cursor: pointer;
                border: 1px solid var(--main-color);
                transition: all linear 0.3s;

                &:hover {
                  background: white;
                  color: var(--main-color);
                }
              }

              & > .unDecoBtn {
                font-size: 0.9rem;
                color: #7b61ff;
                text-align: center;
              }
            }

            & > .countDivs {
              display: flex;
              justify-content: space-between;
              // gap: 15px;

              & > div {
                padding: 0.5rem 1rem;
                // border-radius: 10px;
                // border-right: 1px solid #e1e1e1;
                display: flex;
                flex-direction: column;
                gap: 5px;
                justify-content: center;
                align-items: center;
                position: relative;
                // width: 50%;

                & > .title {
                  font-size: 0.7rem;
                  white-space: nowrap;
                }

                & > .count {
                  font-size: 1rem;
                  font-weight: 550;
                }
              }

              & > .sampledropdown {
                padding: 10px 0;
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;
                border: none;
                font-size: 0.7rem;
                flex-direction: row;
                white-space: nowrap;
              }
            }
          }
        }
      }

      & > .headContainer {
        background: white;
        padding: 1.2rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > .title {
          font-size: 1.2rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: space-between;

          & > span {
            cursor: pointer;
          }
        }

        & > .filterButtons {
          display: flex;
          justify-content: space-between;

          & > .leftContainer {
            width: 30%;
            display: flex;
            justify-content: flex-start;
            gap: 0.5rem;

            & > .buttons {
              padding: 0.5rem 1.5rem;
              border: 1px solid #e9ebf3;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.85rem;
              cursor: pointer;
              border-radius: 10px;
              transition: all linear 0.3s;

              &:hover {
                background: var(--main-color);
                color: white;
              }
            }
          }

          & > .rightContainer {
            width: 70%;
            display: flex;
            justify-content: flex-end;
            gap: 0.5rem;
            font-size: 0.85rem;

            & > .searchDiv {
              position: relative;
              width: max-content;
              border-radius: 10px;

              & > input {
                padding: 0.5rem 1.5rem;
                border: 1px solid #e9ebf3;
                padding-left: 2rem;
                height: 100%;
                border-radius: 10px;
                padding-left: 2rem;
              }

              & > svg {
                position: absolute;
                top: 50%;
                left: 20px;
                transform: translate(-50%, -50%);
                font-size: 1rem;
              }
            }
          }
        }
      }

      & > .proactiveDetails {
        // padding: 0 2rem;
        // padding-top: 1.5rem;
        // height: calc(50% - 0rem);
        // background: #e5e5e536;
        overflow-y: scroll;
        // border: 2px solid red;
        height: calc(100% - 11rem);

        & > .mainFlexTitle {
          font-size: 1.2rem;
          font-weight: 550;
          padding: 1.5rem 0;
        }

        & > .mainFlex {
          width: 100%;
          height: 45%;
          background: white;
          // border-radius: 35px;
          display: flex;
          padding: 1.5rem 2rem;
          gap: 2.5rem;
          max-height: 400px;

          & > img {
            height: 100%;
            object-fit: contain;
          }

          & > .policyTypes {
            width: -webkit-fill-available;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.3rem;

            & > .eachType {
              display: flex;
              gap: 1rem;
              // height: 32%;
              max-height: 65px;
              cursor: pointer;
              transition: all linear 0.3s;

              &:hover {
                transform: translateX(10px);
              }

              & > .imgDiv {
                padding: 0.25rem;
                background: #2ec6971a;
                border-radius: 10px;
                aspect-ratio: 1/1;
                display: flex;
                justify-content: center;
                align-items: center;

                & > img {
                  width: 50%;
                  object-fit: contain;
                }
              }

              & > .contentDiv {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                & > .title {
                  font-size: 1.1rem;
                  font-weight: 600;
                }

                & > .content {
                  font-size: 0.95rem;
                }
              }
            }
          }
        }

        & > .otherGrids {
          display: flex;
          flex-direction: column;
          // gap: 1.5rem;
          height: 100%;
          overflow-y: scroll;
          // padding-bottom: 3rem;
          // margin-top: 0.5rem;

          & > div {
            width: 50%;
            height: 100%;
          }

          .gridTitle {
            font-size: 1.2rem;
            font-weight: 550;
            padding: 1.5rem 0;
          }

          & > :nth-child(1) {
            width: 100%;

            & > .box {
              // height: calc(100% - 4.5rem);
              border-radius: 35px;
              padding: 1.5rem 0;
              display: flex;
              align-items: center;
              overflow-x: scroll;
              gap: 1.5rem;

              & > .eachPolicy {
                border: 1px solid #e5e5e5;
                border-radius: 25px;
                padding: 1rem;
                width: 33%;
                max-width: 600px;
                min-width: 400px;
                scale: 0.9;
                cursor: pointer;
                background: white;
                transition: all linear 0.3s;

                & > .headDiv {
                  height: 80px;
                  display: flex;
                  gap: 1.2rem;

                  & > .imageDiv {
                    height: 100%;
                    aspect-ratio: 1/1;
                    border: 1px solid #e5e5e5;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & > img {
                      width: 100%;
                      object-fit: contain;
                    }
                  }

                  & > .companyDetails {
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    justify-content: space-evenly;
                    padding: 10px;

                    & > .title {
                      font-size: 1.1rem;
                      font-weight: 550;
                    }

                    & > .company {
                      font-size: 0.9rem;
                    }
                  }
                }

                & > .desc {
                  font-size: 0.8rem;
                  padding: 2rem 0;
                }

                & > .moreDetails {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  & > .amount {
                    & > :nth-child(1) {
                      font-size: 0.8rem;
                      font-weight: 450;
                    }
                    & > :nth-child(2) {
                      font-size: 1rem;
                      font-weight: 600;
                      padding-top: 3px;
                    }
                  }

                  & > .btn {
                    border: 1px solid #e5e5e5;
                    padding: 0.35rem 1.5rem;
                    border-radius: 35px;
                    font-size: 0.8rem;
                    font-weight: 600;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all linear 0.3s;

                    &:hover {
                      color: white;
                      background: var(--main-color);
                      border: 1px solid var(--main-color);
                    }
                  }
                }
              }

              & > .eachPolicySelected {
                scale: 1;
                border: 1px solid var(--font-color);
              }
            }
          }

          & > .policyNumbers {
            width: 100%;
            height: max-content !important;

            & > .box {
              display: flex;
              gap: 1rem;
              width: 100%;
              overflow-x: scroll;
              padding: 2rem 0;

              & > .eachBox {
                border: 1px solid #e5e5e5;
                border-radius: 10px;
                padding: 1.5rem 2rem;
                width: 33%;
                cursor: pointer;
                background: white;
                transition: all linear 0.3s;

                &:hover {
                  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                }

                & > .title {
                  font-size: 1.2rem;
                  font-weight: 550;
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;

                  & > span {
                    font-size: 0.8rem;
                  }

                  & > .positiveAmount {
                    color: var(--main-color);
                  }

                  & > .negativeAmount {
                    color: #ea0f0f;
                  }
                }

                & > .status {
                  font-size: 0.9rem;
                  padding: 0.5rem 0;
                  white-space: nowrap;
                }
              }
            }
          }

          & > .trackerTable {
            width: 100%;
            // margin-top: 1rem;

            & > .navbar {
              display: flex;
              border-bottom: 1px solid #e5e5e5;
              padding: 0.5rem 1rem;
              font-size: 0.9rem;

              & > .leftNavContainer {
                display: flex;
                align-items: center;
                // gap: 2rem;
                width: -webkit-fill-available;

                & > img {
                  width: 18px;
                  height: 18px;
                }

                & > div {
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  margin-left: 15px;
                }

                & > .selectedNavOption {
                  color: var(--main-color);
                  font-weight: 600;
                }
              }

              & > .rightNavContainer {
                display: flex;
                align-items: center;
                font-weight: 500;
                gap: 1rem;

                & > .searchDiv {
                  border: 1px solid #e5e5e5;
                  border-radius: 35px;
                  position: relative;
                  width: max-content;

                  & > input {
                    border-radius: 35px;
                    outline: none;
                    border: none;
                    padding: 0.5rem 3rem;
                    font-size: 0.8rem;
                    font-weight: 500;
                    width: 100%;
                    max-width: 300px;
                    color: var(--font-color);

                    :focus {
                      border: 2px solid var(--main-color);
                    }

                    &::placeholder {
                    }
                  }

                  & > svg {
                    position: absolute;
                    top: 50%;
                    left: 1rem;
                    transform: translate(0%, -50%);
                    height: 15px;
                    width: 15px;
                    fill: var(--font-color);
                  }
                }

                & > .filterBtn {
                  border: 1px solid #e5e5e5;
                  border-radius: 35px;
                  padding: 0.5rem 3rem;
                  font-size: 0.8rem;
                  background: white;
                }
              }
            }

            & > .tableParent {
              // overflow: scroll;
              position: relative;
              height: 100%;

              &::-webkit-scrollbar {
                display: block;
                width: 7px; /* Set the width of the table's scrollbar */
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                height: 7px;
              }

              &::-webkit-scrollbar-thumb {
                background-color: var(
                  --font-color
                ); /* Set the color of the scrollbar thumb */
                border-radius: 5px; /* Set the border-radius of the thumb */
                height: 80px;
                transition: all linear 0.3s;
                cursor: pointer;
              }

              &::-webkit-scrollbar-thumb:hover {
                background-color: var(
                  --main-color
                ); /* Set the color when the scrollbar thumb is hovered */
              }

              & > .tableTitles {
                display: grid;
                // grid-template-columns: 50px repeat(6, 1fr);
                grid-template-columns: 5% 15% 15% 15% 15% 15% 20%;
                width: 100%;
                background: #e1e1e1;
                color: black;
                font-weight: 550;
                // border-radius: 12px 12px 0 0;
                position: sticky;
                top: 0;
                width: 100%;
                z-index: 2;

                & > div {
                  padding: 0.5rem 1rem;
                  font-size: 0.83rem;
                  white-space: nowrap;
                  display: flex;
                  align-items: center;
                  gap: 7px;
                  position: relative;
                }
              }

              & > .tableBody {
                width: 100%;
                display: flex;
                flex-direction: column;
                // gap: 1rem;
                // border-radius: 0 0 12px 12px;
                background: white;
                overflow: scroll;
                position: relative;
                height: calc(100% - 40px);

                & > .eachItem {
                  display: grid;
                  grid-template-columns: 5% 15% 15% 15% 15% 15% 20%;
                  // background: white;
                  // border-radius: 10px;
                  border: 1.5px solid white;
                  transition: all linear 0.3s;
                  cursor: pointer;

                  & > .verticalDots {
                    position: relative;
                    align-items: center;

                    .dropdownContent {
                      position: absolute;
                      top: 70%;
                      left: 55%;
                      padding: 1rem 0;
                      background: white;
                      z-index: 6;
                      border: 1px solid #e1e1e1;
                      box-shadow: 0px 4px 4px 0px #00000040;
                      border-radius: 10px;
                      min-width: 250px;

                      & > .eachFilterItem {
                        padding: 0.7rem 1.5rem;
                        font-size: 1rem;
                        font-weight: 400;
                        cursor: pointer;
                        white-space: nowrap;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: relative;
                        width: 100%;
                        // padding-right: 4rem;

                        & > span {
                          display: flex;
                          gap: 10px;
                          align-items: center;

                          & > svg {
                            height: 1rem;
                            width: 1rem;
                          }
                        }

                        & > svg {
                          rotate: -90deg;
                        }

                        & > .subDropdown {
                          display: none;
                          min-width: 250px;
                          left: 100%;
                          top: 0;
                          color: var(--font-color);
                        }

                        &:hover {
                          color: var(--main-color);

                          & > .subDropdown {
                            display: block;
                          }
                        }
                      }
                    }
                  }

                  & > div {
                    padding: 0.5rem 1rem;
                    font-size: 0.8rem;
                    display: flex;
                    position: relative;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                    // overflow: hidden;
                  }

                  & > .verticalFields {
                    flex-direction: column;
                    gap: 7px;

                    & > :nth-child(1) {
                      font-weight: 550;
                    }
                  }

                  & > .singleFields {
                    align-items: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;

                    & > .statusButton {
                      width: 100px;
                      height: 100%;
                      border-radius: 10px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      color: #ffffff;
                      // padding: 0 1.5rem;
                      background: var(--main-color);
                    }
                  }

                  & > .singleFieldsCenter {
                    display: flex;
                    align-items: center;
                  }

                  &:hover {
                    border: 1.5px solid var(--font-color);
                  }
                }
              }
            }
          }
        }
      }

      & > .paginationBar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3rem;
        padding: 5px 2rem;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > .paginationMsg {
          font-size: 0.8rem;
          display: flex;
          align-items: center;
        }

        & > .allPagination {
          margin-left: auto;
          display: flex;
          gap: 10px;
          height: 100%;
          width: max-content;

          & > .eachBtn {
            height: 100%;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            border: 1px solid #e7e7e7;
            font-size: 0.9rem;
            font-weight: 500;
            cursor: pointer;
            background: #e5e5e553;
            transition: all linear 0.3s;

            &:hover {
              color: white;
              background: var(--main-color);
            }
          }

          & > .eachBtnCurrent {
            color: white;
            background: var(--main-color);
          }
        }
      }
    }

    & > .dropdownContent {
      // display: none;
      position: absolute;
      top: 150%;
      left: 0%;
      transform: translate(2.5rem, -50%);
      padding: 1rem 0;
      background: white;
      z-index: 7;
      border: 1px solid #e1e1e1;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 10px;
      min-width: 250px;
      color: var(--font-color);

      & > .eachFilterItem {
        padding: 0.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        // padding-right: 4rem;

        & > span {
          display: flex;
          gap: 10px;
          align-items: center;

          & > svg {
            height: 1rem;
            width: 1rem;
          }
        }

        & > svg {
          rotate: -90deg;
        }

        & > .subDropdown {
          position: absolute;
          display: none;
          min-width: 250px;
          left: 100%;
          top: 0;
          background: white;
          color: var(--font-color);
          padding: 1rem 0;
          background: white;
          border: 1px solid #e1e1e1;
          box-shadow: 0px 4px 4px 0px #00000040;
          border-radius: 10px;

          & > .subMenuTitle {
            padding: 0.2rem 1.5rem;
            font-size: 1rem;
            font-weight: 520;
            text-align: left;
          }

          & > .eachFilterItem {
            padding: 0.7rem 1.5rem;
            font-size: 1rem;
            font-weight: 400;
            cursor: pointer;
            white-space: nowrap;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            width: 100%;
            // padding-right: 4rem;

            & > span {
              display: flex;
              gap: 10px;
              align-items: center;

              & > svg {
                height: 1rem;
                width: 1rem;
              }
            }

            & > svg {
              rotate: -90deg;
            }

            & > .subDropdown {
              position: absolute;
              display: none;
              // min-width: 250px;
              left: 100%;
              top: 0;
              background: white;
              color: var(--font-color);
              padding: 1rem 0;
              background: white;
            }

            &:hover {
              color: var(--main-color);

              & > .subDropdown {
                display: block;
              }
            }
          }
        }

        &:hover {
          color: var(--main-color);

          & > .subDropdown {
            display: block;
          }
        }
      }
    }

    & > .title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 2.5rem;
      padding-top: 0.5rem;
    }

    & > .dropdownItems {
      display: flex;
      align-items: flex-start;
      gap: 1.5rem;

      & > .downloadListBtn {
        background: var(--main-color);
        color: white;
        font-size: 0.9rem;
        font-weight: 500;
        padding: 0.5rem 2rem;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        border: 1px solid var(--main-color);
        transition: all linear 0.3s;

        &:hover {
          color: var(--main-color);
          background: white;
          border: 1px solid #e5e5e5;
        }
      }

      & > .inviteBtn {
        background: white;
        color: var(--font-color);
        font-size: 0.9rem;
        font-weight: 500;
        padding: 0.5rem 2rem;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        border: 1px solid #e5e5e5;
        transition: all linear 0.3s;

        &:hover {
          color: var(--main-color);
          border: 1px solid var(--main-color);
        }
      }
    }

    & > .allLeads {
      border: 1px solid #e5e5e5;
      // border-radius: 35px 35px 0 0;
      margin-top: 2rem;
      height: calc(100% - 5rem);
      overflow: hidden;
      background: white;

      & > .nav {
        // padding: 0rem 12px;
        border-bottom: 1px solid #e5e5e5;
        // border-bottom: none;
        // border-radius: 35px 35px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .filterBtn {
          // width: 3rem;
          // padding: 12px 0;
          // height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 51px;
          height: 50px;
          border-right: 1px solid #e5e5e5;
          position: relative;

          & > svg {
            fill: var(--font-color);
            transition: all linear 0.3s;

            &:hover {
              fill: var(--main-color);
            }
          }

          & > .dropdownContent {
            position: absolute;
            top: 70%;
            left: 55%;
            padding: 1rem;
            background: white;
            z-index: 6;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #00000040;
            border-radius: 10px;
            // min-width: calc(max-content + 4rem);
            min-width: 250px;

            & > .eachFilterItem {
              padding: 0.7rem 0.5rem;
              font-size: 1rem;
              font-weight: 400;
              cursor: pointer;
              white-space: nowrap;
              // padding-right: 4rem;

              & > svg {
                rotate: -90deg;
              }

              &:hover {
                color: var(--main-color);
              }
            }
          }
        }

        & > .actionBtn {
          display: flex;
          align-items: center;
          gap: 1rem;

          & > div {
            border: 1px solid #e5e5e5;
            border-radius: 35px;
            padding: 0.5rem 2.5rem;
            cursor: pointer;
            font-size: 0.9rem;
            transition: all ease-in 0.2s;

            &:hover {
              background: #e5e5e5;
            }
          }
        }

        & > .actionBtnsDownload {
          & > div {
            font-size: 0.9rem;
            font-weight: 500;
            cursor: pointer;
            transition: all linear 0.3s;

            &:hover {
              background: var(--main-color);
              color: white;
            }
          }
        }
      }
    }
  }
}

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 3rem;
  cursor: pointer;
  transition: all linear 0.3s;

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 0rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    transition: all ease-in 0.2s;

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

// src/Table.scss
.tableContainer {
  overflow-y: auto;
  height: calc(100% - 4rem);
  width: 100%; /* Set the container width to 100% or any other desired value */
  overflow-x: auto; /* Allow horizontal scrolling */
  // border-left: 0.25px solid #e5e5e5;
  // border-right: 0.25px solid #e5e5e5;
  position: relative;

  &::-webkit-scrollbar {
    display: block;
    width: 7px; /* Set the width of the table's scrollbar */
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(
      --font-color
    ); /* Set the color of the scrollbar thumb */
    border-radius: 5px; /* Set the border-radius of the thumb */
    height: 80px;
    transition: all linear 0.3s;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --main-color
    ); /* Set the color when the scrollbar thumb is hovered */
  }

  table {
    border-collapse: collapse;
    min-width: 240%; /* Set the table width to 110% to ensure horizontal scrollbar */
    margin-bottom: 20px;
    table-layout: fixed; /* Ensures equal column width for horizontally scrolling table */
    width: max-content;

    th,
    td {
      border: 1px solid #dddddd;
      text-align: left;
      white-space: normal;
      word-wrap: break-word;
      padding: 0.75rem 1rem;
      position: relative;
      display: flex;
      align-items: center;
      border-right: none;
      border-top: none;
      font-size: 0.85rem;

      & > input {
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    th {
      white-space: nowrap;
      font-weight: 550;
      font-size: 0.85rem;
      padding: 0.7rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg {
        cursor: pointer;
      }
    }

    thead {
      background: #e1e1e1;
      & > tr {
        & > th {
          border-top: none;
        }
      }
    }

    tr {
      & > :nth-child(1) {
        border-left: none;
        width: 50px;
      }

      & > :last-child {
        border-right: none;
      }
    }

    tr {
      display: grid;
      grid-template-columns: min-content repeat(15, 1fr);
    }

    tr > :first-child {
      /* Set the width you want for the first child */
      width: 50px; /* Replace 200px with your desired width */
      /* Position the first child in the first column */
      grid-column: 1;
    }

    .detailedItem {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      & > :nth-child(1) {
        padding: 0.5rem;
        border: 0.5px solid #e5e5e5;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 70%;
          height: 70%;
        }
      }

      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        & > :nth-child(1) {
          font-size: 0.9rem;
          font-weight: 550;
          white-space: nowrap;
        }
        & > :nth-child(2) {
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    td {
      position: relative;
      transition: all linear 0.3s;

      &:hover {
        background: #8080800d;
      }

      & > .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }

      .dropdownContent {
        position: absolute;
        top: 70%;
        left: 55%;
        padding: 1rem 0;
        background: white;
        z-index: 6;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;
        min-width: 250px;

        & > .eachFilterItem {
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          // padding-right: 4rem;

          & > span {
            display: flex;
            gap: 10px;
            align-items: center;

            & > svg {
              height: 1rem;
              width: 1rem;
            }
          }

          & > svg {
            rotate: -90deg;
          }

          & > .subDropdown {
            display: none;
            min-width: 250px;
            left: 100%;
            top: 0;
            color: var(--font-color);
          }

          &:hover {
            color: var(--main-color);

            & > .subDropdown {
              display: block;
            }
          }
        }
      }
    }
  }
}

.sidebarMenu {
  width: 45%;
  max-width: 500px;
  position: absolute;
  top: 1px;
  bottom: 0;
  right: -150%;
  background: white;
  border-left: 0.25px solid #e5e5e5;
  border-top: 0.25px solid #e5e5e5;
  border-bottom: 0.25px solid #e5e5e5;
  transition: all linear 0.3s;

  & > .eachItemDetailed {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 2rem;

    & > .emptyCard {
      height: 100px;
      padding: 1rem;

      & > div {
        font-size: 0.9rem;
        font-weight: 500;
        color: white;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    & > .allDetails {
      position: relative;
      padding: 0 1rem;

      & > .mainDetail {
        display: flex;
        gap: 1rem;
        transform: translateY(-30px);

        & > .eachPolicy {
          border: 1px solid #e5e5e5;
          background: white;
          border-radius: 25px;
          padding: 1rem;
          width: 100%;

          & > .headDiv {
            height: 80px;
            display: flex;
            gap: 1.2rem;

            & > .imageDiv {
              height: 100%;
              aspect-ratio: 1/1;
              border: 1px solid #e5e5e5;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              & > img {
                width: 100%;
                object-fit: contain;
              }
            }

            & > .companyDetails {
              display: flex;
              flex-direction: column;
              gap: 3px;
              justify-content: space-evenly;
              padding: 10px;

              & > .title {
                font-size: 0.95rem;
                font-weight: 550;
              }

              & > .company {
                font-size: 0.85rem;
              }
            }
          }

          & > .desc {
            font-size: 1rem;
            padding: 2rem 0;
          }

          & > .moreDetails {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > .amount {
              & > :nth-child(1) {
                font-size: 0.8rem;
                font-weight: 450;
              }
              & > :nth-child(2) {
                font-size: 1rem;
                font-weight: 600;
                padding-top: 3px;
              }
            }

            & > .btn {
              border: 1px solid #e5e5e5;
              padding: 0.35rem 1.5rem;
              border-radius: 35px;
              font-size: 0.8rem;
              font-weight: 600;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all linear 0.3s;

              &:hover {
                color: white;
                background: var(--main-color);
                border: 1px solid var(--main-color);
              }
            }
          }
        }

        & > img {
          background: white;
          border-radius: 20px;
          width: 90px;
          height: 90px;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          line-height: 2;
          margin-top: auto;
          padding-bottom: 5px;

          & > :nth-child(1) {
            font-size: 1rem;
            font-weight: 500;
          }
          & > :nth-child(2) {
            font-size: 0.75rem;
          }
        }
      }

      & > .desc {
        margin-top: 1rem;

        & > .searchDiv {
          margin-bottom: 2rem;

          & > input {
            padding: 1rem 1.5rem;
            background: #e5e5e540;
            border-radius: 35px;
            outline: none;
            border: none;
            width: 100%;
            font-size: 0.9rem;
          }
        }

        & > .allDesc {
          & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // gap: 25px;
            margin-bottom: 2rem;

            & > :nth-child(1) {
              color: var(--main-color);
              font-size: 2rem;
              font-weight: 600;
              text-align: center;
            }

            & > :nth-child(2) {
              font-size: 0.9rem;
              line-height: 1.5;
              width: 85%;
            }
          }
        }

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .para {
          font-size: 0.85rem;
          margin-top: 1.2rem;
          line-height: 1.6;
        }
      }

      & > .offerings {
        margin-top: 2.5rem;

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .allOfferings {
          margin-top: 1.2rem;

          & > .eachOffering {
            margin-bottom: 1rem;
            border: 1px solid #e5e5e5;
            border-radius: 20px;
            padding: 1rem;
            display: flex;
            gap: 1rem;

            & > :nth-child(1) {
              width: 100px;
              height: 100px;
              padding: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;

              & > svg {
                height: 100%;
              }
            }

            & > :nth-child(2) {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              & > :nth-child(1) {
                & > .title {
                  font-size: 0.9rem;
                  font-weight: 500;
                }

                & > .learnBtn {
                  font-size: 0.7rem;
                  font-weight: 500;
                  text-decoration: underline;
                  margin-top: 10px;
                  cursor: pointer;
                  transition: all linear 0.3s;

                  &:hover {
                    color: #04bfda;
                  }
                }
              }

              & > .priceDiv {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: auto;

                & > :nth-child(1) {
                  font-size: 0.9rem;
                  font-weight: 500;

                  & > span {
                    text-decoration: line-through;
                    opacity: 0.5;
                    margin-left: 5px;
                  }
                }

                & > .btn {
                  font-size: 0.6rem;
                  padding: 7px 1.5rem;
                  border-radius: 35px;
                  cursor: pointer;
                  border: 1px solid #e5e5e5;
                  transition: all linear 0.3s;

                  &:hover {
                    background: #04bfda;
                    color: white;
                    border: 1px solid #04bfda;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  & > .allWellnessItems {
    padding: 1.5rem;

    & > .title {
      font-size: 1.2rem;
      font-weight: 550;
      padding: 1rem 0 2rem 0;
    }

    & > .allItems {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      gap: 1.5rem;
      flex-wrap: wrap;

      & > .eachWellnessItem {
        max-width: 150px;
        cursor: pointer;
        transition: all linear 0.3s;

        & > img {
          width: 100%;
          border-radius: 30px;
          aspect-ratio: 1/1;
          transition: all linear 0.3s;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          font-size: 1rem;
          font-weight: 500;
          padding: 1rem 0;
          text-align: center;
          transition: all linear 0.3s;
        }

        &:hover {
          & > img {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }

          & > div {
            font-weight: 550;
          }
        }
      }
    }
  }

  & > .dependentsContainer {
    padding: 2rem;

    & > .title {
      font-size: 1.4rem;
      font-weight: 500;
    }

    & > .allDependents {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      & > .eachDependent {
        font-size: 0.9rem;
        font-weight: 500;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 1.5rem;
        transition: all linear 0.3s;

        &:hover {
          border: 1px solid var(--main-color);
        }
      }
    }
  }
}

.customDropdown {
  position: relative;
  display: flex;
  width: max-content;
  // min-width: 240px;
  //   border: 1px solid blue;
  max-width: 400px;
  height: 100%;
  cursor: pointer;
  border: 1px solid #e9ebf3;

  & > .dropdownBtn {
    display: flex;
    gap: 1.5rem;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;
    font-weight: 450;
    width: 100%;
    user-select: none;
    white-space: nowrap;
    position: relative;
    z-index: 6;
    background: white;

    & > :nth-child(1) {
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > .dropdownContent {
    position: absolute;
    top: 100%;
    // border: 1px solid green;
    // width: 100%;
    left: -1px;
    right: -1px;
    z-index: 5;
    background: white;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    transition: all linear 0.3s;
    overflow: scroll;
    max-height: 500px;
    transition: all linear 0.5s;

    & > .eachContent {
      padding: 1.5rem 1rem;
      font-size: 0.9rem;
      font-weight: 450;
      color: var(--font-color);
      transition: all linear 0.2s;

      &:hover {
        background: var(--main-color);
        color: white;
        font-weight: 700;
      }
    }
  }
}

.statusBtn {
  width: max-content !important;
  margin: auto 0;
  padding: 0.5rem 1.5rem !important;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  background-color: #e7e7e7;
  border-radius: 5px;
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: 4;
  // background: rgba(0, 0, 0, 0.8);
  z-index: 7;
}

.onboarddd {
  position: absolute;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 10px;
  min-width: 250px;
  // top:0
  margin-top: 40px;
  background-color: white;
  z-index: 999999;
  padding: 1rem 1rem;
  div {
    padding: 5px 0px;
    &:hover {
      font-weight: 500;
      cursor: pointer;
    }
  }
}
