.navbar {
  border-radius: 35px;
  max-width: 1400px;
  position: fixed;
  z-index: 5;
  width: calc(100% - 20px);
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #cdf2e6;
  overflow: hidden;
  border-bottom: 0.5px solid #e7e7e7;
  transition: all ease-in 0.2s;

  & > .topSection {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.75rem 2rem;
    min-height: 30px;
    font-weight: 550;
    font-size: 1rem;
    font-weight: 550;
    font-size: 1rem;
    width: 100%;

    & > .categories {
      display: flex;
      gap: 0.5rem;
      color: var(--main-color);
      justify-content: space-between;
      width: 100%;

      & > div {
        cursor: pointer;

        &:hover {
          border-bottom: 2px solid var(--main-color);
        }
      }
      & > a {
        cursor: pointer;
        color: inherit;
        text-decoration: none;

        &:hover {
          border-bottom: 2px solid var(--main-color);
        }
      }
    }

    & > .selectCountry {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      cursor: pointer;
      transition: all ease-in 0.2s;
      padding-bottom: 4px;

      &:hover {
        border-bottom: 2px solid var(--main-color);
      }

      & > img {
        width: 20px;
        height: 20px;
      }

      & > .downArrow {
        width: 10px;
        height: 10px;
      }
    }
  }

  & > .bottomSection {
    min-height: 60px;
    padding: 1.5rem 2rem;
    border-radius: 35px 35px 0 0;
    background: white;
    display: flex;
    justify-content: space-between;

    & > .logoDiv {
      display: flex;
      gap: 1rem;
      align-items: center;

      & > img {
        height: 100%;
        object-fit: contain;
      }

      // & > :nth-child(1) {
      //   height: 50%;
      //   max-height: 1.5rem;
      //   cursor: pointer;
      // }
    }

    & > .navOptions {
      display: flex;
      align-items: center;
      font-size: 1rem;
      gap: 0.5rem;

      & > div {
        display: flex;
        gap: 1.25rem;

        & > div {
          cursor: pointer;
        }
      }

      & > :nth-child(1) {
        min-width: 200px;
        transition: all ease-in 0.4s;

        & > div {
          transition: all ease-in 0.2s;

          &:hover {
            font-weight: 600;
          }
        }

        & > .selectedOption {
          color: var(--main-color);
          font-weight: 550;
        }
      }

      & > .btnsContainer {
        font-weight: 500;
        gap: 0.75rem;
        justify-content: flex-end;

        & > div {
          padding: 0.5rem 1.5rem;
          border-radius: 35px;
          text-align: center;
          transition: all ease-in 0.4s;

          &:hover {
            scale: 1.05;
          }
        }
        & > a {
          padding: 0.5rem 1.5rem;
          border-radius: 35px;
          text-align: center;
          transition: all ease-in 0.4s;
          text-decoration: none;
          color: inherit;

          &:hover {
            scale: 1.05;
          }
        }

        & > :nth-child(1) {
          color: white;
          background: #2ec697;
        }
      }
    }
  }
}
