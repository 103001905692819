.basicHome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  height: 100vh;

  & > img {
    height: 6rem;
  }

  & > .mainNavigations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    max-width: 1200px;
    width: 100%;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5rem 5rem;
      background: #fafafa;
      border-radius: 10px;
      cursor: pointer;
      font-weight: 520;
      transition: all linear 0.3s;

      &:hover {
        color: white;
        background: var(--main-color);
      }
    }
  }
}
