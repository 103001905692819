.profile {
  height: 100%;

  & > .navbar {
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    background: white;

    & > .leftContainer {
      display: flex;

      & > .navbarOption {
        padding: 1.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        width: max-content;
        cursor: pointer;
      }

      & > .navbarOptionSelected {
        color: var(--main-color);
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 35px 35px 0 0;
          background: var(--main-color);
        }
      }
    }

    & > .rightContainer {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > .navBtn {
        font-size: 0.8rem;
        border-radius: 35px;
        background: var(--main-color);
        padding: 0.5rem 1.5rem;
        color: white;
        font-weight: 550;
        transition: all linear 0.3s;
        cursor: pointer;

        &:hover {
          border: 1px solid #e5e5e5;
          background: white;
          color: var(--main-color);
        }
      }
    }
  }

  & > .proactiveDetails {
    padding: 2rem 2.5rem;
    padding-top: 1rem;
    height: calc(100% - 4rem);
    background: #e5e5e536;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    background: rgba(229, 229, 229, 0.2117647059);
    position: relative;

    &::-webkit-scrollbar {
      display: block;
      width: 7px; /* Set the width of the table's scrollbar */
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(
        --font-color
      ); /* Set the color of the scrollbar thumb */
      border-radius: 5px; /* Set the border-radius of the thumb */
      height: 80px;
      transition: all linear 0.3s;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(
        --main-color
      ); /* Set the color when the scrollbar thumb is hovered */
    }

    .lockedDiv {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: not-allowed;
      backdrop-filter: blur(10px);
      border-radius: 10px;
      overflow: hidden;
      z-index: 3;

      & > .title {
        font-size: 1rem;
        font-weight: 500;
        position: absolute;
        top: 5px;
        z-index: 1;
      }

      & > svg {
        stroke: black;
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        z-index: 2;
      }

      & > .opacityDiv {
        width: 100%;
        height: 100%;
        opacity: 0.6;
        position: absolute;
        inset: 0;
        z-index: 1;
        background: white;
      }
    }

    & > .majorDetails {
      display: flex;
      gap: 1rem;
      // border: 1px solid red;

      & > .enrollmentDiv {
        width: 40%;
        padding: 1rem;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        background: #fff;
        display: flex;

        & > :nth-child(1) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: max-content;
          white-space: nowrap;
          min-width: 50%;

          & > .title {
            font-weight: 550;
            margin-bottom: 0.5rem;
            z-index: 1;
            position: relative;
          }

          & > .countDiv {
            margin-bottom: 0.5rem;

            & > .title {
              font-size: 0.9rem;
              font-weight: 400;
              margin-bottom: 3px;
            }

            & > .count {
              font-size: 0.9rem;
              font-weight: 550;
            }
          }
        }

        & > :nth-child(2) {
          width: -webkit-fill-available;
          max-width: 50%;
          position: relative;

          & > canvas {
            width: 100%;
            height: max-content;
          }

          & > .totalPerc {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & > :nth-child(1) {
              font-size: 2.5rem;
              font-weight: 600;
            }

            & > :nth-child(2) {
              font-size: 0.9rem;
              font-weight: 400;
            }
          }
        }
      }

      & > :nth-child(2) {
        width: 60%;
        display: flex;
        gap: 1rem;

        & > div {
          width: 50%;
          padding: 1rem;
          border-radius: 10px;
          border: 1px solid #e1e1e1;
          box-shadow: 0px 4px 4px 0px #00000040;
          background: #fff;
        }

        & > .miniCards {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          position: relative;
          // justify-content: space-between;

          & > .title {
            font-weight: 550;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: auto;
            z-index: 4;
            position: relative;

            & > .viewBtn {
              padding: 0.5rem 1rem;
              font-size: 0.75rem;
              border-radius: 10px;
              border: 1px solid #e1e1e1;
              font-weight: 550;
              cursor: pointer;
              transition: all linear 0.3s;

              &:hover {
                background: var(--main-color);
                color: white;
              }
            }
          }

          & > .cardDropdown {
            & > div {
              & > div {
                padding: 0.5rem 1rem;
              }
            }
          }

          & > .balanceDiv {
            font-weight: 600;
            font-size: 1.9rem;
            line-height: normal;
          }

          & > .growthDiv {
            display: flex;
            gap: 6px;
            align-items: center;
            font-weight: 400;
            font-size: 0.9rem;

            & > svg {
              width: 1rem;
              height: 1rem;
              stroke: var(--main-color);
            }
          }

          & > .countDivs {
            display: flex;
            gap: 10px;
            & > div {
              padding: 0.5rem;
              border-radius: 10px;
              border: 1px solid #e1e1e1;
              display: flex;
              flex-direction: column;
              gap: 5px;
              justify-content: center;
              align-items: center;
              position: relative;
              width: 50%;

              & > .title {
                font-size: 0.7rem;
                white-space: nowrap;
              }

              & > .count {
                font-size: 1rem;
                font-weight: 550;
              }
            }
          }

          & > .btnContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 0.9rem;
            text-align: center;
            padding: 0.5rem 1.5rem;
            border-radius: 10px;
            background: var(--main-color);
            color: #fff;
            font-weight: 520;
            cursor: pointer;
            border: 1px solid var(--main-color);
            transition: all linear 0.3s;

            &:hover {
              background: white;
              color: var(--main-color);
            }
          }

          & > .box {
            display: flex;
            gap: 0.7rem;
            background: white;
            height: calc(47% - 1rem);
            // max-height: 3.2rem;

            & > .associateProfile {
              // height: 100%;

              & > img {
                min-height: 100%;
                height: 100%;
                max-height: 100px;
                // object-fit: contain;
                border-radius: 10px;
                border: 0.5px solid #e5e5e5;
              }
            }

            & > .agentDetails {
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 10px;

              & > .name {
                font-size: 0.9rem;
                font-weight: 550;
              }

              & > .status {
                display: flex;
                gap: 5px;
                font-size: 0.75rem;
                font-weight: 500;
                color: var(--main-color);
                padding-top: 5px;
                padding-bottom: 15px;
              }

              & > .agentBtn {
                padding: 0.35rem 1rem;
                border: 1px solid #e5e5e5;
                border-radius: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all linear 0.3s;
                font-size: 0.7rem;
                font-weight: 550;
                gap: 7px;

                & > svg {
                  transition: all linear 0.3s;
                  fill: var(--main-color);
                }

                &:hover {
                  color: white;
                  background: var(--main-color);
                  border: 1px solid var(--main-color);

                  & > svg {
                    fill: white;
                  }
                }
              }
            }
          }

          & > .greyBtn {
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 0.9rem;
            text-align: center;
            padding: 0.5rem 1.5rem;
            border-radius: 10px;
            font-weight: 520;
            cursor: pointer;
            border: 1px solid #e1e1e1;
            background: white;
            color: var(--font-color);
            pointer-events: none;
            opacity: 0.5;
            transition: all linear 0.3s;

            &:hover {
              background: var(--main-color);
              color: #fff;
            }
          }
        }
      }
    }

    & > .smallBoxes {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 1.2rem;
      margin: 2rem 0;

      & > div {
        padding: 1rem;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 4px 4px 0px #00000040;
        background: #fff;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
        position: relative;
        min-height: 45px;

        & > .title {
          font-size: 1rem;
          font-weight: 500;
          z-index: 1;
          position: relative;
        }

        & > .count {
          font-size: 1rem;
          font-weight: 550;
        }
      }
    }

    & > .policiesDiv {
      display: flex;
      flex-direction: row-reverse;
      // flex-direction: column;
      gap: 1rem;

      & > .header {
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        width: 350px;
        margin-top: 5.4rem;

        & > .miniCards {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          background: white;
          padding: 1rem;
          // justify-content: space-evenly;
          margin-bottom: 1rem;
          box-shadow: 0px 4px 4px 0px #00000040;
          border: 1px solid #e1e1e1;
          border-radius: 10px;
          position: relative;

          & > .title {
            font-weight: 550;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin-bottom: auto;
            z-index: 4;
            position: relative;

            & > .viewBtn {
              padding: 0.5rem 1rem;
              font-size: 0.75rem;
              border-radius: 10px;
              border: 1px solid #e1e1e1;
              font-weight: 550;
              cursor: pointer;
              transition: all linear 0.3s;

              &:hover {
                background: var(--main-color);
                color: white;
              }
            }
          }

          & > .cardDropdown {
            display: flex;
            align-items: center;
            & > div {
              & > div {
                padding: 0.7rem 1rem;
              }
            }
          }

          & > .detailsBtn {
            font-size: 0.9rem;
            text-decoration: underline;
          }

          & > .balanceDiv {
            font-weight: 600;
            font-size: 1.9rem;
            line-height: normal;
          }

          & > .growthDiv {
            display: flex;
            gap: 6px;
            align-items: center;
            font-weight: 400;
            font-size: 0.9rem;

            & > svg {
              width: 1rem;
              height: 1rem;
              stroke: var(--main-color);
            }
          }

          & > .countDivs {
            display: flex;
            gap: 10px;
            & > div {
              padding: 0.5rem;
              border-radius: 10px;
              border: 1px solid #e1e1e1;
              display: flex;
              flex-direction: column;
              gap: 5px;
              justify-content: center;
              align-items: center;
              position: relative;
              width: 50%;

              & > .title {
                font-size: 0.7rem;
                white-space: nowrap;
              }

              & > .count {
                font-size: 1rem;
                font-weight: 550;
              }
            }
          }

          & > .btnContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 0.9rem;
            text-align: center;
            padding: 0.5rem 1.5rem;
            border-radius: 10px;
            background: var(--main-color);
            color: #fff;
            font-weight: 520;
            cursor: pointer;
            border: 1px solid var(--main-color);
            transition: all linear 0.3s;

            &:hover {
              background: white;
              color: var(--main-color);
            }
          }

          & > .box {
            display: flex;
            gap: 0.7rem;
            background: white;
            height: calc(47% - 1rem);
            // max-height: 3.2rem;
            margin-bottom: 1rem;

            & > .associateProfile {
              // height: 100%;

              & > .profileLetter {
                width: 4rem;
                height: 70px;
                border-radius: 6px;
                font-size: 1rem;
                background: var(--main-color);
                color: white;
                font-weight: 550;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              & > img {
                min-height: 100%;
                height: 100%;
                max-height: 70px;
                // object-fit: contain;
                border-radius: 10px;
                border: 0.5px solid #e5e5e5;
              }
            }

            & > .agentDetails {
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 10px;

              & > .name {
                font-size: 0.9rem;
                font-weight: 550;
              }

              & > .status {
                display: flex;
                gap: 5px;
                font-size: 0.75rem;
                font-weight: 500;
                color: var(--main-color);
                padding-top: 5px;
                padding-bottom: 15px;
              }

              & > .agentBtn {
                padding: 0.35rem 1rem;
                border: 1px solid #e5e5e5;
                border-radius: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all linear 0.3s;
                font-size: 0.7rem;
                font-weight: 550;
                gap: 7px;

                & > svg {
                  transition: all linear 0.3s;
                  fill: var(--main-color);
                }

                &:hover {
                  color: white;
                  background: var(--main-color);
                  border: 1px solid var(--main-color);

                  & > svg {
                    fill: white;
                  }
                }
              }
            }
          }

          & > .greyBtn {
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 0.9rem;
            text-align: center;
            padding: 0.5rem 1.5rem;
            border-radius: 10px;
            font-weight: 520;
            cursor: pointer;
            border: 1px solid #e1e1e1;
            background: white;
            color: var(--font-color);
            pointer-events: none;
            opacity: 0.5;
            transition: all linear 0.3s;

            &:hover {
              background: var(--main-color);
              color: #fff;
            }
          }
        }
      }

      & > .otherDashContainer {
        width: calc(100% - 350px);
        display: flex;
        gap: 1rem;

        & > div {
          padding: 1rem;
          position: relative;

          & > .title {
            & > :nth-child(1) {
              font-size: 1.5rem;
              font-weight: 420;

              & > span {
                font-weight: 520;
              }
            }

            & > :nth-child(2) {
              font-size: 1rem;
              font-weight: 500;
              text-decoration: underline;
              margin-top: 7px;
            }
          }

          & > .emptyPoliciesDiv {
            padding: 1rem;
            border-radius: 10px;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #00000040;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            & > div {
              width: 80%;
              text-align: center;
              font-weight: 500;
              margin-bottom: 2rem;
            }

            & > .btnsContainer {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
              font-size: 0.9rem;

              & > button {
                all: unset;
                padding: 0.6rem 1.5rem;
                width: 200px;
                cursor: pointer;
                border-radius: 10px;
                background: var(--main-color);
                color: white;
                font-weight: 520;
                // width: max-content;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                transition: all linear 0.3s;

                &:hover {
                  scale: 1.05;
                }
              }

              & > :nth-child(2) {
                background: white;
                color: var(--font-color);
                border: 1px solid #e1e1e1;
              }
            }
          }
        }

        & > :nth-child(1) {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          padding: 0;

          & > .eachPolicy {
            padding: 1rem;
            margin-bottom: 1rem;
            border-radius: 10px;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #00000040;
            background: #fff;

            & > .headDiv {
              height: 80px;
              display: flex;
              gap: 1.2rem;

              & > .imageDiv {
                height: 100%;
                aspect-ratio: 1/1;
                // border: 1px solid #e5e5e5;
                // border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                & > img {
                  width: 100%;
                  object-fit: contain;
                  border: 1px solid #e5e5e5;
                  border-radius: 10px;
                }
              }

              & > .companyDetails {
                display: flex;
                flex-direction: column;
                gap: 3px;
                justify-content: space-evenly;
                padding: 10px;

                & > .title {
                  font-size: 1.1rem;
                  font-weight: 550;
                }

                & > .company {
                  font-size: 0.9rem;
                }
              }

              & > .donutBox {
                // width: 20%;
                // max-width: 180px;
                height: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                width: max-content;

                & > canvas {
                  height: 100%;
                  height: max-content;
                }

                & > .totalPerc {
                  position: absolute;
                  top: 55%;
                  left: 48%;
                  transform: translate(-50%, -50%);
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;

                  & > :nth-child(1) {
                    font-size: 0.6rem;
                    font-weight: 550;
                    text-align: center;
                  }
                }
              }
            }

            & > .desc {
              font-size: 0.9rem;
              padding: 2rem 0;
            }

            & > .moreDetails {
              display: flex;
              justify-content: space-between;
              align-items: center;

              & > .coveragesDiv {
                width: 70%;
                max-width: 260px;

                & > .moreDetailsTitle {
                  font-size: 0.9rem;
                  font-weight: 400;
                  margin-bottom: 8px;
                }

                & > .allPolicyVarients {
                  display: grid;
                  gap: 0.6rem;
                  width: 100%;
                  font-size: 0.9rem;
                  font-weight: 450;
                  // max-width: 250px;
                  align-items: flex-start;

                  & > .eachVarient {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0.45rem 1rem;
                    border: 1px solid #e1e1e1;
                    border-radius: 10px;
                    width: 100%;
                  }
                }
              }

              & > .amount {
                & > :nth-child(1) {
                  font-size: 0.8rem;
                  font-weight: 450;
                }
                & > :nth-child(2) {
                  font-size: 1rem;
                  font-weight: 600;
                  padding-top: 3px;
                }
              }

              & > :last-child {
                display: flex;
                gap: 10px;
                align-items: flex-end;

                .btn {
                  border: 1px solid #e5e5e5;
                  padding: 0.35rem 1.5rem;
                  border-radius: 35px;
                  font-size: 0.8rem;
                  font-weight: 600;
                  cursor: pointer;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  transition: all linear 0.3s;
                  width: 210px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;

                  &:hover {
                    color: white;
                    background: var(--main-color);
                    border: 1px solid var(--main-color);
                  }
                }
              }

              & > .btn {
                border: 1px solid #e5e5e5;
                padding: 0.35rem 1.5rem;
                border-radius: 35px;
                font-size: 0.8rem;
                font-weight: 600;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all linear 0.3s;

                &:hover {
                  color: white;
                  background: var(--main-color);
                  border: 1px solid var(--main-color);
                }
              }
            }
          }
        }

        & > .employeeGraph {
          width: 40%;
          aspect-ratio: 1/1;
          padding-top: 0;

          & > :nth-child(2) {
            width: 100%;
          }
        }

        .hiddenGraph {
          width: 100%;
          aspect-ratio: 1/1;
          // height: 400px;
          background-image: url("../../../assest/images/barGraph.svg");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: white;
          border-radius: 10px;
          border: 1px solid #e1e1e1;
          box-shadow: 0px 4px 4px 0px #00000040;
          // background: #fff;
          max-height: 700px;
          position: relative;
          padding: 1rem;

          & > .title {
            font-weight: 550;
            margin-bottom: 0.5rem;
            z-index: 4;
            position: relative;
          }
        }
      }
    }
  }

  & > .walletLoading {
    background: #00000080;
    position: fixed;
    inset: 0;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .walletLoadingBox {
      padding: 2rem;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      gap: 3rem;
      background: white;
      aspect-ratio: 1/1;

      & > img {
        height: 3rem;
      }

      & > .title {
        font-size: 1.2rem;
        font-weight: 520;
        text-align: center;
      }
    }
  }
}

.sidebarMenu {
  width: 45%;
  max-width: 500px;
  position: absolute;
  top: 1px;
  bottom: 0;
  right: -150%;
  background: white;
  border-left: 0.25px solid #e5e5e5;
  border-top: 0.25px solid #e5e5e5;
  border-bottom: 0.25px solid #e5e5e5;
  transition: all linear 0.3s;
  z-index: 5;

  & > .eachItemDetailed {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 2rem;

    & > .emptyCard {
      height: 100px;
      padding: 1rem;

      & > div {
        font-size: 0.9rem;
        font-weight: 500;
        color: white;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    & > .allDetails {
      position: relative;
      padding: 0 1rem;

      & > .mainDetail {
        display: flex;
        gap: 1rem;
        transform: translateY(-30px);

        & > .eachPolicy {
          border: 1px solid #e5e5e5;
          background: white;
          border-radius: 25px;
          padding: 1rem;
          width: 100%;

          & > .headDiv {
            height: 80px;
            display: flex;
            gap: 1.2rem;

            & > .imageDiv {
              height: 100%;
              aspect-ratio: 1/1;
              // border: 1px solid #e5e5e5;
              // border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              & > img {
                width: 100%;
                object-fit: contain;
                border: 1px solid #e5e5e5;
                border-radius: 10px;
              }
            }

            & > .companyDetails {
              display: flex;
              flex-direction: column;
              gap: 3px;
              justify-content: space-evenly;
              padding: 10px;

              & > .title {
                font-size: 0.95rem;
                font-weight: 550;
              }

              & > .company {
                font-size: 0.85rem;
              }
            }
          }

          & > .desc {
            font-size: 0.9rem;
            padding: 2rem 0;
          }

          & > .moreDetails {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > .amount {
              width: 100%;

              & > :nth-child(1) {
                font-size: 0.8rem;
                font-weight: 450;
              }
              & > :nth-child(2) {
                font-size: 1rem;
                font-weight: 600;
                padding-top: 3px;
              }

              & > .allPolicyVarients {
                display: grid;
                gap: 0.6rem;
                width: 100%;
                font-size: 0.9rem;
                font-weight: 450;
                // max-width: 250px;
                align-items: flex-start;

                & > .eachVarient {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0.45rem 1rem;
                  border: 1px solid #e1e1e1;
                  border-radius: 10px;
                  width: 100%;
                }
              }
            }

            & > .btn {
              border: 1px solid #e5e5e5;
              padding: 0.35rem 1.5rem;
              border-radius: 35px;
              font-size: 0.8rem;
              font-weight: 600;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all linear 0.3s;

              &:hover {
                color: white;
                background: var(--main-color);
                border: 1px solid var(--main-color);
              }
            }
          }
        }

        & > img {
          background: white;
          border-radius: 20px;
          width: 90px;
          height: 90px;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          line-height: 1.5;
          margin-top: auto;
          padding-bottom: 5px;

          & > :nth-child(1) {
            font-size: 1rem;
            font-weight: 500;
          }
          & > :nth-child(2) {
            font-size: 0.75rem;
          }
        }
      }

      & > .desc {
        margin-top: 1rem;

        & > .detail {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.85rem;
          margin-bottom: 1.2rem;

          & > :nth-child(1) {
            font-weight: 520;
          }
          & > :nth-child(2) {
            font-weight: 350;
          }
        }

        & > .sumTable {
          margin-top: 2rem;

          & > .eachCell {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #e7e7e7;
            border-bottom: none;

            & > :nth-child(1) {
              border-right: 1px solid #e7e7e7;
            }

            & > :nth-child(2) {
              border-right: 1px solid #e7e7e7;
              text-align: end;
            }

            & > div {
              width: 50%;
              padding: 0.6rem 1rem;
              font-size: 0.8rem;
              font-weight: 550;
            }
          }

          & > :last-child {
            border-bottom: 1px solid #e7e7e7;
          }
        }

        & > .searchDiv {
          margin-bottom: 2rem;

          & > input {
            padding: 1rem 1.5rem;
            background: #e5e5e540;
            border-radius: 35px;
            outline: none;
            border: none;
            width: 100%;
            font-size: 0.9rem;
          }
        }

        & > .allDesc {
          & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // gap: 25px;
            margin-bottom: 2rem;

            & > :nth-child(1) {
              color: var(--main-color);
              font-size: 2rem;
              font-weight: 600;
              text-align: center;
            }

            & > :nth-child(2) {
              font-size: 0.9rem;
              line-height: 1.5;
              width: 85%;
            }
          }
        }

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .para {
          font-size: 0.85rem;
          margin-top: 1.2rem;
          line-height: 1.6;
        }
      }

      & > .offerings {
        margin-top: 2.5rem;

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .allOfferings {
          margin-top: 1.2rem;

          & > .eachOffering {
            margin-bottom: 1rem;
            border: 1px solid #e5e5e5;
            border-radius: 20px;
            padding: 1rem;
            display: flex;
            gap: 1rem;

            & > :nth-child(1) {
              width: 100px;
              height: 100px;
              padding: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;

              & > svg {
                height: 100%;
              }
            }

            & > :nth-child(2) {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              & > :nth-child(1) {
                & > .title {
                  font-size: 0.9rem;
                  font-weight: 500;
                }

                & > .learnBtn {
                  font-size: 0.7rem;
                  font-weight: 500;
                  text-decoration: underline;
                  margin-top: 10px;
                  cursor: pointer;
                  transition: all linear 0.3s;

                  &:hover {
                    color: #04bfda;
                  }
                }
              }

              & > .priceDiv {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: auto;

                & > :nth-child(1) {
                  font-size: 0.9rem;
                  font-weight: 500;

                  & > span {
                    text-decoration: line-through;
                    opacity: 0.5;
                    margin-left: 5px;
                  }
                }

                & > .btn {
                  font-size: 0.6rem;
                  padding: 7px 1.5rem;
                  border-radius: 35px;
                  cursor: pointer;
                  border: 1px solid #e5e5e5;
                  transition: all linear 0.3s;

                  &:hover {
                    background: #04bfda;
                    color: white;
                    border: 1px solid #04bfda;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  & > .allWellnessItems {
    padding: 1.5rem;

    & > .title {
      font-size: 1.2rem;
      font-weight: 550;
      padding: 1rem 0 2rem 0;
    }

    & > .allItems {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      gap: 1.5rem;
      flex-wrap: wrap;

      & > .eachWellnessItem {
        max-width: 150px;
        cursor: pointer;
        transition: all linear 0.3s;

        & > img {
          width: 100%;
          border-radius: 30px;
          aspect-ratio: 1/1;
          transition: all linear 0.3s;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          font-size: 1rem;
          font-weight: 500;
          padding: 1rem 0;
          text-align: center;
          transition: all linear 0.3s;
        }

        &:hover {
          & > img {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }

          & > div {
            font-weight: 550;
          }
        }
      }
    }
  }
}
