.newCompanyProfileStep2Parent {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  & > .successfulMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    margin: auto;
    height: 100%;

    & > :nth-child(1) {
      font-size: 1.5rem;
      font-weight: 550;
      text-align: center;
    }

    & > :nth-child(2) {
      font-size: 1rem;
      font-family: 400;
      padding: 1.2rem 0;
      text-align: center;
    }
  }

  & > .navbar {
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    background: white;

    & > .leftContainer {
      display: flex;

      & > .navbarOption {
        padding: 1.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        width: max-content;
        cursor: pointer;
      }

      & > .navbarOptionSelected {
        color: var(--main-color);
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 35px 35px 0 0;
          background: var(--main-color);
        }
      }
    }

    & > .rightContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      width: 30%;
    }
  }

  & > .contentContainer {
    background: #8080800d;
    height: calc(100% - 4rem);
    padding: 1rem 0;
    padding-top: 1.5rem;

    & > .companyProfileStep2 {
      height: calc(100% - 3rem);
      overflow-y: scroll;
      padding: 0 2rem;

      & > .successfulMessage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 450px;
        margin: auto;
        height: 100%;

        & > :nth-child(1) {
          font-size: 1.5rem;
          font-weight: 550;
          text-align: center;
        }

        & > :nth-child(2) {
          font-size: 1rem;
          font-family: 400;
          padding: 1.2rem 0;
          text-align: center;
        }
      }

      & > .title {
        font-size: 1.2rem;
        font-weight: 550;
        margin-bottom: 2rem;
        padding-top: 0.5rem;
      }

      & > .sectionTitle {
        color: var(--main-color);
        background: #2ec69726;
        padding: 0.75rem 0.5rem;
        font-weight: 600;
        margin-bottom: 2rem;
        position: relative;
        border-radius: 10px;
      }

      & > .inputContainers {
        display: flex;
        flex-direction: column;
        // grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        & > .eachInput {
          & > .inputContainer {
            border: 1.5px solid #e1e1e1;
            font-weight: 450;
            transition: all linear 0.3s;

            &:focus {
              background: white;
              border: 1.5px solid var(--main-color);
            }
            &:hover {
              background: white;
              border: 1.5px solid var(--main-color);
            }
          }
        }
      }
    }

    & > .btnsContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 2rem;
      padding: 0 2rem;
      // padding-top: 3rem;

      & > div {
        padding: 1rem 1.5rem;
        border-radius: 35px;
        font-size: 0.8rem;
        margin-top: 1rem;
        width: 100%;
        max-width: 500px;
        cursor: pointer;
        color: white;
        font-size: 1rem;
        font-weight: 550;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }

      & > :nth-child(1) {
        background: #5f6163;
      }
      & > :nth-child(2) {
        background: var(--main-color);
      }
    }
  }
}

.eachInput {
  padding: 0.75rem 0rem;
  margin-bottom: 1rem;

  & > .title {
    font-size: 0.9rem;
  }

  & > .inputDiv {
    position: relative;
    max-width: 500px;
    margin-top: 1rem;

    & > .inputContainer {
      padding: 1rem 1.5rem;
      border-radius: 35px;
      border: 1.5px solid rgb(229, 229, 229);
      font-size: 0.9rem;
      font-weight: 450;
      background: white;
      width: 100%;
      padding-right: 2.1rem;
      transition: all linear 0.3s;

      &:focus {
        outline: var(--main-color);
        background: white;
        border: 1.5px solid var(--main-color);
      }

      &:focus ~ .selectedBanner {
        color: white;
        background: var(--main-color);
        border: 1.5px solid var(--main-color);
      }

      &:hover {
        outline: var(--main-color);
        background: white;
        border: 1.5px solid var(--main-color);
      }

      &:hover ~ .selectedBanner {
        color: white;
        background: var(--main-color);
        border: 1.5px solid var(--main-color);
      }
    }

    & > .selectedBanner {
      background: white;
      padding: 0.6rem 1.5rem;
      font-size: 0.9rem;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translate(0%, -50%);
      border-radius: 35px;
      font-weight: 500;
      border: 1.5px solid rgb(229, 229, 229);
      transition: all linear 0.3s;
    }
  }

  & > .assetsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-top: 1.5rem;

    & > .eachAsset {
      padding: 1rem;
      border-radius: 20px;
      border: 1px solid #e5e5e5;
      width: 17%;
      aspect-ratio: 1/1;
      display: flex;
      position: relative;
      overflow: hidden;
      background: white;
      transition: all linear 0.3s;

      &:hover {
        border: 1px solid #5f6163;
        box-shadow: 0px 4px 4px 0px #00000040;
      }

      & > div {
        width: 50%;
      }

      & > :nth-child(1) {
        height: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        font-size: 1rem;
        font-weight: 550;
      }

      & > :nth-child(2) {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        & > .shadeArea {
          position: absolute;
          bottom: 0;
          right: -2rem;
          background: #2ec69714;
          border-radius: 0 75px 0 25px;
          rotate: -90deg;
          top: 65%;
          width: 60%;
        }

        & > svg {
          width: 55%;
          object-fit: contain;
          margin-right: 20%;
          margin-bottom: 30%;
        }
      }
    }
  }

  & > .allOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    border: 2px solid red;

    & > div {
      display: flex;
      text-align: center;
      width: max-content;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      font-size: 1rem;
      transition: all linear 0.3s;

      &:hover {
        background: #e5e5e566;
      }
    }
  }
}

.eachInputDatePicker {
  & > .title {
    font-size: 0.9rem;
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: #e5e5e566;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;
    border: 1.5px solid #e1e1e1;
    font-weight: 450;
    transition: all linear 0.3s;

    &:focus {
      background: white;
      border: 1.5px solid var(--main-color);
    }
    &:hover {
      background: white;
      border: 1.5px solid var(--main-color);
    }
  }
}

.eachInputPickOne {
  & > .title {
    font-size: 0.9rem;
  }

  & > .selectOneAllOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 1rem;
    margin-top: 0.5rem;

    & > div {
      display: flex;
      text-align: center;
      width: max-content;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      font-size: 0.9rem;
      background: white;
      font-weight: 450;
      transition: all linear 0.3s;

      &:hover {
        color: white;
        border: 1px solid var(--main-color);
        background: var(--main-color);
      }
    }
  }
}

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 6rem;
  cursor: pointer;

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    bottom: 0.3rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    border: 1.5px solid #e1e1e1;
    font-weight: 450;
    background: rgba(229, 229, 229, 0.4);
    transition: all linear 0.3s;

    &:focus {
      background: white;
      border: 1.5px solid var(--main-color);
    }

    &:hover {
      background: white;
      border: 1.5px solid var(--main-color);

      input {
        background: #e5e5e566;
      }
    }

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > :nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        & > div {
          & > :first-child {
            font-size: 1rem;
            font-weight: 550;
          }

          & > :last-child {
            font-size: 0.8rem;
            padding-top: 3px;
          }
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          transition: all ease-in 0.2s;
          background: inherit;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

.imageUploadDiv {
  // line-height: 2;
  font-size: 0.9rem;
  // font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  // margin-top: 2rem;

  & > .imageDiv {
    // margin-top: 0.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    & > img {
      min-width: 8vw;
      min-height: 8vw;
      max-width: 8vw;
      max-height: 8vw;
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      transition: all ease 0.4s;
      padding: 2rem;

      // &:hover {
      //   scale: 0.95;
      //   // border: 5px solid #44c2f4;
      // }
    }

    & > .overlayDiv {
      position: absolute;
      width: 20%;
      height: 20%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & > img {
        animation: heartBeat 0.5s alternate infinite;
      }

      @keyframes heartBeat {
        0% {
          scale: 1;
        }
        100% {
          scale: 1.2;
        }
      }
    }
  }

  & > .uploadFileDiv {
    font-size: 1.1rem;
    transition: all ease 0.4s;
    width: 40%;

    &:hover {
      font-size: 1.3rem;
      font-weight: 550;
    }
    & > .uploadNewPicPlanB {
      display: none;
    }
  }
}

.imageUploadDivProfilePic {
  // line-height: 2;
  font-size: 0.9rem;
  // font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  // margin-top: 2rem;

  & > .imageDiv {
    // margin-top: 0.5rem;
    padding: 2.5rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e5;
    font-size: 0.8rem;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      min-width: 5vw;
      min-height: 5vw;
      max-width: 5vw;
      max-height: 5vw;
      border-radius: 50%;
      transition: all ease 0.4s;

      // &:hover {
      //   scale: 0.95;
      //   // border: 5px solid #44c2f4;
      // }
    }

    & > .overlayDiv {
      position: absolute;
      width: 20%;
      height: 20%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & > img {
        animation: heartBeat 0.5s alternate infinite;
      }

      @keyframes heartBeat {
        0% {
          scale: 1;
        }
        100% {
          scale: 1.2;
        }
      }
    }
  }

  & > .uploadFileDiv {
    font-size: 1.1rem;
    transition: all ease 0.4s;
    width: 40%;

    &:hover {
      font-size: 1.3rem;
      font-weight: 550;
    }
    & > .uploadNewPicPlanB {
      display: none;
    }
  }
}

.eachProfileInput {
  padding: 0.75rem 0rem;
  border-radius: 10px;
  transition: all linear 0.2s;
  position: relative;
  margin-bottom: 1rem;
  width: 100%;

  & > .title {
    font-size: 0.9rem;
  }

  & > .addByEmail {
    padding-bottom: 0.5rem;

    & > .inputParent {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-top: 1rem;

      & > .inputContainer {
        font-size: 0.95rem;
        outline: none;
        border: none;
        margin-top: 10px;
        width: 500px;
        background: #e5e5e566;
        border: 1px solid #e5e5e566;
        transition: all linear 0.2s;
        border-radius: 35px;
        padding: 1rem 1.5rem;
        max-width: 500px;

        // &:hover {
        //   border: 1px solid var(--main-color);
        // }

        &:focus {
          background: white;
          border: 1px solid var(--main-color);
        }
      }

      & > .inputDiv {
        position: relative;
        width: 100%;
        max-width: 500px;

        & > .inputContainer {
          padding: 1rem 1.5rem;
          border-radius: 35px;
          border: 1.5px solid rgb(229, 229, 229);
          font-size: 0.9rem;
          font-weight: 450;
          background: white;
          width: 100%;
          padding-right: 2.1rem;
          transition: all linear 0.3s;

          &:focus {
            outline: var(--main-color);
            background: white;
            border: 1.5px solid var(--main-color);
          }

          &:focus ~ .selectedBanner {
            color: white;
            background: var(--main-color);
            border: 1.5px solid var(--main-color);
          }

          &:hover {
            outline: var(--main-color);
            background: white;
            border: 1.5px solid var(--main-color);
          }

          &:hover ~ .selectedBanner {
            color: white;
            background: var(--main-color);
            border: 1.5px solid var(--main-color);
          }
        }

        & > .selectedBanner {
          background: white;
          padding: 0.6rem 1.5rem;
          font-size: 0.9rem;
          font-weight: 500;
          position: absolute;
          right: 25px;
          top: 50%;
          transform: translate(0%, -50%);
          border-radius: 35px;
          border: 1.5px solid rgb(229, 229, 229);
          transition: all linear 0.3s;
        }
      }

      & > .addButton {
        font-size: 1.1rem;
        font-weight: 600;
        border-radius: 10px;
        border: 1px solid #e5e5e566;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          background: white;
          border: 1.5px solid var(--main-color);
        }
      }

      & > .status {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translate(-50%, -50%);
        display: flex;
        gap: 5px;
        align-items: center;

        & > svg {
          font-size: 0.8rem;
          width: 1.1rem;
          height: 1.1rem;
        }

        & > span {
          font-size: 0.8rem;
        }
      }

      & > .trashCan {
        background: #2ec69726;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        right: -4.5rem;
        transform: translate(-50%, -50%);

        & > svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
  }
}
