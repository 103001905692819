.profile {
  height: 100%;

  & > .navbar {
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    background: white;

    & > .leftContainer {
      display: flex;

      & > .navbarOption {
        padding: 1.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        width: max-content;
        cursor: pointer;
      }

      & > .navbarOptionSelected {
        color: var(--main-color);
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 35px 35px 0 0;
          background: var(--main-color);
        }
      }
    }

    & > .rightContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      width: 30%;
    }
  }

  & > .myProfileDetails {
    padding: 2rem 2.5rem;
    padding-bottom: 3rem;
    // overflow-y: scroll;
    height: calc(100% - 4rem);
    background: #e5e5e536;
    overflow-y: scroll;

    & > .mainDiv {
      & > .detailedItem {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: flex-start;
        padding: 1.5rem 0;
        margin-bottom: 1.5rem;

        & > :nth-child(1) {
          border: 0.5px solid #e5e5e5;
          border-radius: 50%;
          width: 6rem;
          height: 6rem;
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        & > :nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;

          & > :nth-child(1) {
            font-size: 1.2rem;
            font-weight: 450;
            white-space: nowrap;
          }
          & > :nth-child(2) {
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }

      & > .diffSections {
        display: flex;
        gap: 0.5rem;
        margin-bottom: 1.5rem;

        & > div {
          padding: 0.5rem 1.5rem;
          border: 1px solid #e5e5e5;
          border-radius: 35px;
          cursor: pointer;
          font-size: 0.8rem;
          font-weight: 500;
        }

        & > .selectedSection {
          border: 1px solid var(--main-color);
          color: var(--main-color);
        }
      }
    }

    & > .selectedSectionMain {
      & > .personalDetails {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        padding-top: 1.5rem;
      }

      & > .contactDetails {
        padding-bottom: 3rem;

        & > div {
          padding-top: 2rem;

          & > .title {
            font-size: 1rem;
            font-weight: 550;
            margin-bottom: 0.5rem;
          }

          & > .addNewBtn {
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;

            & > .icon {
              font-size: 0.9rem;
              width: 1.2rem;
              height: 1.2rem;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              background: var(--main-color);
            }

            & > span {
              font-size: 0.9rem;
              font-weight: 500;
            }
          }
        }
      }

      & > .assetsDetails {
        padding-top: 1.5rem;

        & > .title {
          font-size: 1rem;
          font-weight: 550;
          margin-bottom: 0.5rem;
        }

        & > .assetsContainer {
          display: flex;
          flex-wrap: wrap;
          gap: 1.5rem;
          padding-top: 1rem;

          & > .eachAsset {
            padding: 1rem;
            border-radius: 20px;
            border: 1px solid #e5e5e5;
            width: 20%;
            aspect-ratio: 1/1;
            display: flex;
            position: relative;
            overflow: hidden;
            background: white;
            transition: all linear 0.3s;

            &:hover {
              border: 1px solid #5f6163;
              box-shadow: 0px 4px 4px 0px #00000040;
            }

            & > div {
              width: 50%;
            }

            & > :nth-child(1) {
              height: 50%;
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              font-size: 1.2rem;
              font-weight: 550;
            }

            & > :nth-child(2) {
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;

              & > .shadeArea {
                position: absolute;
                bottom: 0;
                right: -2rem;
                background: #2ec69714;
                border-radius: 0 75px 0 25px;
                rotate: -90deg;
                top: 65%;
                width: 60%;
              }

              & > svg {
                width: 55%;
                object-fit: contain;
                margin-right: 20%;
                margin-bottom: 30%;
              }
            }
          }
        }
      }
    }
  }

  & > .noProfile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    height: 100%;

    & > :nth-child(1) {
      font-size: 1.5rem;
      font-weight: 550;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 5px;
    }

    & > :nth-child(2) {
      font-size: 1rem;
    }

    & > :nth-child(3) {
      background: var(--main-color);
      padding: 1rem 3rem;
      cursor: pointer;
      margin-top: 2rem;
      color: white;
      font-size: 1rem;
      font-weight: 550;
      border-radius: 35px;
      border: 1px solid var(--main-color);
      text-decoration: none;
      transition: all linear 0.3s;

      &:hover {
        background: white;
        border: 1px solid var(--main-color);
        color: var(--main-color);
      }
    }
  }
}

//custom dropdown

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 2.5rem;
  cursor: pointer;
  transition: all linear 0.2s;

  & > .inputContainer {
    padding: 0.6rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 0rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    transition: all ease-in 0.2s;

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;

      & > :nth-child(1) {
        align-items: center;
        display: flex;
        gap: 10px;
        width: 90%;

        & > img {
          border-radius: 50%;
          height: 18px;
          width: 18px;
        }

        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .addBtn {
        background: var(--main-color);
        padding: 0.5rem 0;
        color: white;
        font-weight: 550;
        border-radius: 35px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.7rem 0;
      }

      & > img {
        border-radius: 50%;
        height: 18px;
        width: 18px;
      }

      & > li {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 1rem 0rem;
        width: 100%;

        & > img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }

        & > svg {
          width: 17px;
          height: 17px;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

//my profile specific

.eachProfileInput {
  border: 1px solid var(--font-color);
  padding: 0.75rem 1.2rem;
  border-radius: 10px;
  transition: all linear 0.2s;
  background: white;
  position: relative;
  max-width: 500px;
  margin-bottom: 1rem;

  & > .title {
    font-size: 0.65rem;
  }

  & > .inputParent {
    & > .inputContainer {
      font-size: 1rem;
      outline: none;
      border: none;
      margin-top: 10px;
      width: 100%;
    }

    & > .status {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 5px;
      align-items: center;

      & > svg {
        font-size: 0.8rem;
        width: 1.1rem;
        height: 1.1rem;
      }

      & > span {
        font-size: 0.8rem;
      }
    }

    & > .trashCan {
      background: #2ec69726;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      right: -4.5rem;
      transform: translate(-50%, -50%);

      & > svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border: 1.25px solid var(--main-color);
  }
}
