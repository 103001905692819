.profile {
  height: 100%;

  & > .navbar {
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    background: white;

    & > .leftContainer {
      display: flex;

      & > .navbarOption {
        padding: 1.7rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        width: max-content;
        cursor: pointer;
      }

      & > .navbarOptionSelected {
        color: var(--main-color);
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 35px 35px 0 0;
          background: var(--main-color);
        }
      }
    }

    & > .rightContainer {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > .searchDiv {
        border: 1px solid #e5e5e5;
        border-radius: 35px;
        position: relative;
        width: max-content;

        & > input {
          border-radius: 35px;
          outline: none;
          border: none;
          padding: 0.5rem 4rem;
          padding-left: 3rem;
          font-size: 0.8rem;
          width: 100%;
          max-width: 300px;

          :focus {
            border: 2px solid var(--main-color);
          }

          &::placeholder {
            opacity: 0.4;
          }
        }

        & > svg {
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translate(0%, -50%);
          height: 15px;
          width: 15px;
        }
      }
    }

    & > .cartIcon {
      display: flex;
      align-items: center;
      position: relative;

      & > img {
        padding: 0.5rem 1rem;
      }

      & > .totalItems {
        background: var(--main-color);
        color: white;
        font-size: 0.9rem;
        font-weight: 500;
        position: absolute;
        top: 5px;
        right: 5px;
        aspect-ratio: 1/1;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }
  }

  & > .rahastoreDetails {
    padding: 1.5rem 2.5rem;
    padding-bottom: 0;
    // overflow-y: scroll;
    height: calc(100% - 5rem);
    background: #e5e5e536;
    // overflow-y: scroll;
    position: relative;

    & > .mainFlex {
      width: 100%;
      background: white;
      border-radius: 35px;

      & > img {
        width: 100%;
        object-fit: contain;
      }
    }

    & > .insuranceCompanies {
      & > .title {
        font-size: 1.2rem;
        font-weight: 550;
        padding: 2rem 0;
      }

      & > .allCompanies {
        display: flex;
        align-items: center;
        gap: 1rem;

        & > :nth-child(1) {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 1rem;
          width: -webkit-fill-available;

          & > div {
            border-radius: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--font-color);
            font-weight: 500;
            background: white;
            font-size: 1rem;
            cursor: pointer;
            padding: 0.6rem;
          }

          & > .selectedCompany {
            border: 1px solid var(--main-color);
            color: var(--main-color);
            font-weight: 550;
          }
        }

        & > .allCompaniesBtn {
          color: white;
          background: var(--main-color);
          padding: 0.6rem 1.5rem;
          border: none;
          border-radius: 35px;
          white-space: nowrap;
          font-weight: 500;
          cursor: pointer;
          font-size: 1rem;
        }
      }
    }

    & > .companyDetailed {
      width: 100%;
      height: 35%;
      background: white;
      border-radius: 35px;
      margin-top: 2rem;
      padding: 2rem;

      & > .companyTitle {
        font-size: 1.2rem;
        font-weight: 550;
      }

      & > .allItems {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-between;
        gap: 1.5rem;
        flex-wrap: wrap;
        margin-top: 1.5rem;
        padding-bottom: 2rem;

        & > .eachWellnessItem {
          max-width: 120px;
          cursor: pointer;
          transition: all linear 0.3s;

          & > img {
            width: 100%;
            border-radius: 30px;
            aspect-ratio: 1/1;
            transition: all linear 0.3s;
            border: 0.5px solid #e5e5e5;
          }

          & > div {
            font-size: 1rem;
            font-weight: 500;
            padding: 1rem 0;
            text-align: center;
            transition: all linear 0.3s;
          }

          &:hover {
            & > img {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }

            & > div {
              font-weight: 550;
            }
          }
        }
      }
    }

    & > .walletCards {
      padding: 2rem;
      padding-bottom: 0;

      & > .topCard {
        display: flex;
        gap: 1rem;
        & > :nth-child(1) {
          width: 40%;
        }
        & > :nth-child(2) {
          width: 40%;
        }
        & > :nth-child(3) {
          width: 20%;
        }

        & > div {
          & > h6 {
            font-size: 15px;
            font-weight: 600;
          }
          & > .wideCard {
            width: -webkit-fill-available;
            background: white;
            padding: 1rem;
            border-radius: 10px;
            border: 1px solid #eaeaeb;
            min-width: 392px;
            min-height: 215px;
            flex-shrink: 0;

            & > img {
              width: 100%;
              height: 100%;
            }

            & > .detailDiv {
              display: flex;
              gap: 1.4rem;
              height: calc(100% - 2rem);
              padding-top: 1rem;

              & > .balanceParent {
                width: -webkit-fill-available;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                & > .title {
                  color: #5f6163;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                }

                & > .balanceDiv {
                  font-weight: 600;
                  font-size: 1.9rem;
                  line-height: normal;
                }

                & > .growthDiv {
                  display: flex;
                  gap: 6px;
                  align-items: center;
                  font-weight: 400;
                  font-size: 0.9rem;

                  & > svg {
                    width: 1rem;
                    height: 1rem;
                    stroke: var(--main-color);
                  }
                }

                & > .btnContainer {
                  display: flex;
                  justify-content: center;
                  width: 100%;
                  font-size: 0.9rem;
                  text-align: center;
                  padding: 0.5rem 1.5rem;
                  border-radius: 10px;
                  background: var(--main-color);
                  color: #fff;
                  font-weight: 520;
                  cursor: pointer;
                  border: 1px solid var(--main-color);
                  transition: all linear 0.3s;
                  margin: 20px 0;

                  &:hover {
                    background: white;
                    color: var(--main-color);
                  }
                }

                & > .unDecoBtn {
                  color: #7b61ff;
                  text-align: center;
                  font-size: 12px;
                  font-style: italic;
                  font-weight: 700;
                  line-height: 24px;
                  text-decoration-line: underline;
                }
              }

              & > .countDivs {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                gap: 5px;

                & > div {
                  padding: 0.5rem 1rem;
                  border-radius: 10px;
                  border: 1px solid #e1e1e1;
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  // width: 50%;

                  & > .title {
                    font-size: 0.7rem;
                    white-space: nowrap;
                  }

                  & > .count {
                    font-size: 1rem;
                    font-weight: 550;
                  }
                }
              }
            }
          }
          & > .wideCard2 {
            width: -webkit-fill-available;
            background: white;
            padding: 1rem;
            border-radius: 10px;
            border: 1px solid #eaeaeb;
            min-width: 392px;
            min-height: 215px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          & > .supportCard {
            width: 100%;
            min-height: 215px;
            background: white;
            padding: 1.2rem;
            border-radius: 10px;
            border: 1px solid #eaeaeb;
            display: flex;
            align-items: center;

            & > .box {
              display: flex;
              // gap: 1rem;
              margin-bottom: 0.5rem;
              height: calc(47% - 1rem);
              // max-height: 3.2rem;

              & > .agentDetails {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;

                & > .name {
                  // display: flex;
                  // align-items: center;
                  gap: 7px;

                  & > img {
                    width: 60px;
                    height: 60px;
                    margin-bottom: 20px;
                  }

                  font-size: 0.9rem;
                  font-weight: 550;
                }

                & > .status {
                  display: flex;
                  gap: 5px;
                  font-size: 0.75rem;
                  font-weight: 500;
                  color: var(--main-color);
                  padding-top: 5px;
                  padding-bottom: 15px;
                }

                & > .agentBtn {
                  padding: 0.35rem 1rem;
                  border: 1px solid #e5e5e5;
                  border-radius: 35px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  transition: all linear 0.3s;
                  font-size: 0.7rem;
                  font-weight: 550;
                  gap: 7px;
                  white-space: nowrap;

                  & > svg {
                    transition: all linear 0.3s;
                    fill: var(--main-color);
                  }

                  &:hover {
                    color: white;
                    background: var(--main-color);
                    border: 1px solid var(--main-color);

                    & > svg {
                      fill: white;
                    }
                  }
                }
              }
            }
          }
        }
      }

      & > .bottomCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
        margin-top: 1.5rem;

        & > .transparentBtn {
          padding: 0.4rem 1rem;
          border-radius: 10px;
          border: 1px solid #e9ebf3;
          font-weight: 500;
          background: white;
          font-size: 0.9rem;
          cursor: pointer;
          white-space: nowrap;
        }

        & > .searchDiv {
          position: relative;
          width: -webkit-fill-available;
          max-width: 360px;
          background: transparent;
          border-radius: 5px;
          border: 1px solid #e9ebf3;
          background: #f9fbff;
          margin-left: auto;

          & > input {
            // border-radius: 35px;
            outline: none;
            border: none;
            padding: 0.5rem 3rem;
            font-size: 0.8rem;
            width: 100%;
            // max-width: 300px;
            background: transparent;

            :focus {
              border: 2px solid var(--main-color);
            }

            &::placeholder {
              opacity: 0.4;
            }
          }

          & > svg {
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translate(0%, -50%);
            height: 15px;
            width: 15px;
          }
        }

        & > .filterBtn {
          display: flex;
          align-items: center;
          gap: 2px;
          white-space: nowrap;
          cursor: pointer;
          border-radius: 5px;
          border: 1px solid #e9ebf3;
          background: #f9fbff;
          padding: 8px;

          & > .title {
            font-size: 0.8rem;
          }

          & > .filterValue {
            font-weight: 550;
            font-size: 0.8rem;
          }
        }
      }
    }

    & > .proactiveDetails {
      padding: 0 2.5rem;
      // padding-top: 1.5rem;
      // height: calc(50% - 0rem);
      // background: #e5e5e536;
      overflow-y: scroll;
      // border: 2px solid red;

      & > .mainFlexTitle {
        font-size: 1.2rem;
        font-weight: 550;
        padding: 1.5rem 0;
      }

      & > .mainFlex {
        width: 100%;
        height: 45%;
        background: white;
        border-radius: 35px;
        display: flex;
        padding: 1.5rem 2rem;
        gap: 2.5rem;
        max-height: 400px;

        & > img {
          height: 100%;
          object-fit: contain;
        }

        & > .policyTypes {
          width: -webkit-fill-available;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1.3rem;

          & > .eachType {
            display: flex;
            gap: 1rem;
            // height: 32%;
            max-height: 65px;
            cursor: pointer;
            transition: all linear 0.3s;

            &:hover {
              transform: translateX(10px);
            }

            & > .imgDiv {
              padding: 0.25rem;
              background: #2ec6971a;
              border-radius: 10px;
              aspect-ratio: 1/1;
              display: flex;
              justify-content: center;
              align-items: center;

              & > img {
                width: 50%;
                object-fit: contain;
              }
            }

            & > .contentDiv {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              & > .title {
                font-size: 1.1rem;
                font-weight: 600;
              }

              & > .content {
                font-size: 0.95rem;
              }
            }
          }
        }
      }

      & > .otherGrids {
        display: flex;
        flex-direction: column;
        // gap: 1.5rem;
        height: 100%;
        overflow-y: scroll;
        // padding-bottom: 3rem;
        // margin-top: 0.5rem;

        & > div {
          width: 50%;
          height: 100%;
        }

        .gridTitle {
          font-size: 1.2rem;
          font-weight: 550;
          padding: 1.5rem 0;
        }

        & > :nth-child(1) {
          width: 100%;

          & > .box {
            // height: calc(100% - 4.5rem);
            border-radius: 35px;
            padding: 1.5rem 0;
            display: flex;
            align-items: center;
            overflow-x: scroll;
            gap: 1.5rem;

            & > .eachPolicy {
              border: 1px solid #e5e5e5;
              border-radius: 25px;
              padding: 1rem;
              width: 33%;
              max-width: 600px;
              min-width: 400px;
              scale: 0.9;
              cursor: pointer;
              background: white;
              transition: all linear 0.3s;

              & > .headDiv {
                height: 80px;
                display: flex;
                gap: 1.2rem;

                & > .imageDiv {
                  height: 100%;
                  aspect-ratio: 1/1;
                  border: 1px solid #e5e5e5;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  & > img {
                    width: 100%;
                    object-fit: contain;
                  }
                }

                & > .companyDetails {
                  display: flex;
                  flex-direction: column;
                  gap: 3px;
                  justify-content: space-evenly;
                  padding: 10px;

                  & > .title {
                    font-size: 1.1rem;
                    font-weight: 550;
                  }

                  & > .company {
                    font-size: 0.9rem;
                  }
                }
              }

              & > .desc {
                font-size: 0.8rem;
                padding: 2rem 0;
              }

              & > .moreDetails {
                display: flex;
                justify-content: space-between;
                align-items: center;

                & > .amount {
                  & > :nth-child(1) {
                    font-size: 0.8rem;
                    font-weight: 450;
                  }

                  & > :nth-child(2) {
                    font-size: 1rem;
                    font-weight: 600;
                    padding-top: 3px;
                  }
                }

                & > .btn {
                  border: 1px solid #e5e5e5;
                  padding: 0.35rem 1.5rem;
                  border-radius: 35px;
                  font-size: 0.8rem;
                  font-weight: 600;
                  cursor: pointer;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  transition: all linear 0.3s;

                  &:hover {
                    color: white;
                    background: var(--main-color);
                    border: 1px solid var(--main-color);
                  }
                }
              }
            }

            & > .eachPolicySelected {
              scale: 1;
              border: 1px solid var(--font-color);
            }
          }
        }

        & > .policyNumbers {
          width: 100%;
          height: max-content !important;

          & > .box {
            display: flex;
            gap: 1rem;
            width: 100%;
            overflow-x: scroll;
            padding: 2rem 0;

            & > .eachBox {
              border: 1px solid #e5e5e5;
              border-radius: 10px;
              padding: 1.5rem 2rem;
              width: 33%;
              cursor: pointer;
              background: white;
              transition: all linear 0.3s;

              &:hover {
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
              }

              & > .title {
                font-size: 1.2rem;
                font-weight: 550;
                display: flex;
                align-items: center;
                gap: 0.5rem;

                & > span {
                  font-size: 0.8rem;
                }

                & > .positiveAmount {
                  color: var(--main-color);
                }

                & > .negativeAmount {
                  color: #ea0f0f;
                }
              }

              & > .status {
                font-size: 0.9rem;
                padding: 0.5rem 0;
                white-space: nowrap;
              }
            }
          }
        }

        & > .trackerTable {
          width: 100%;
          // margin-top: 1rem;

          & > .navbar {
            display: flex;
            border-bottom: 1px solid #e5e5e5;
            padding: 0.5rem 1rem;
            font-size: 0.9rem;

            & > .leftNavContainer {
              display: flex;
              align-items: center;
              // gap: 2rem;
              width: -webkit-fill-available;

              & > img {
                width: 18px;
                height: 18px;
              }

              & > div {
                font-weight: 500;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-left: 15px;
              }

              & > .selectedNavOption {
                color: var(--main-color);
                font-weight: 600;
              }
            }

            & > .rightNavContainer {
              display: flex;
              align-items: center;
              font-weight: 500;
              gap: 1rem;

              & > .searchDiv {
                border: 1px solid #e5e5e5;
                border-radius: 35px;
                position: relative;
                width: max-content;

                & > input {
                  border-radius: 35px;
                  outline: none;
                  border: none;
                  padding: 0.5rem 3rem;
                  font-size: 0.8rem;
                  font-weight: 500;
                  width: 100%;
                  max-width: 300px;
                  color: var(--font-color);

                  :focus {
                    border: 2px solid var(--main-color);
                  }

                  &::placeholder {
                  }
                }

                & > svg {
                  position: absolute;
                  top: 50%;
                  left: 1rem;
                  transform: translate(0%, -50%);
                  height: 15px;
                  width: 15px;
                  fill: var(--font-color);
                }
              }

              & > .filterBtn {
                border: 1px solid #e5e5e5;
                border-radius: 35px;
                padding: 0.5rem 3rem;
                font-size: 0.8rem;
                background: white;
              }
            }
          }

          & > .tableParent {
            overflow: scroll;

            height: 100%;

            &::-webkit-scrollbar {
              display: block;
              width: 7px;
              /* Set the width of the table's scrollbar */
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              height: 7px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: var(--font-color);
              /* Set the color of the scrollbar thumb */
              border-radius: 5px;
              /* Set the border-radius of the thumb */
              height: 80px;
              transition: all linear 0.3s;
              cursor: pointer;
            }

            &::-webkit-scrollbar-thumb:hover {
              background-color: var(--main-color);
              /* Set the color when the scrollbar thumb is hovered */
            }

            & > .tableTitles {
              display: grid;
              grid-template-columns: 3% 10% 30% 17% 17% 17%;
              width: 100%;
              background: #e1e1e1;
              color: black;
              font-weight: 550;
              // border-radius: 12px 12px 0 0;

              & > div {
                padding: 0.5rem 1rem;
                font-size: 0.83rem;
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 7px;
              }
            }
            & > .tableTitles1 {
              display: grid;
              grid-template-columns: 3% 15% 15% 30% 10% 10% 10%;
              width: 100%;
              background: #e1e1e1;
              color: black;
              font-weight: 550;
              // border-radius: 12px 12px 0 0;

              & > div {
                padding: 0.5rem 1rem;
                font-size: 0.83rem;
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 7px;
              }
            }
            & > .tableTitles1 {
              display: grid;
              grid-template-columns: 3% 15% 15% 30% 10% 10% 10%;
              width: 100%;
              background: #e1e1e1;
              color: black;
              font-weight: 550;
              // border-radius: 12px 12px 0 0;

              & > div {
                padding: 0.5rem 1rem;
                font-size: 0.83rem;
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 7px;
              }
            }

            & > .tableBody {
              height: calc(100% - 5rem);
              overflow: scroll;
              width: 100%;
              display: flex;
              flex-direction: column;
              // gap: 1rem;
              border-radius: 0 0 12px 12px;
              background: white;

              & > .eachItem {
                display: grid;
                grid-template-columns: 3% 10% 30% 17% 17% 17%;
                // grid-template-columns: repeat(6, 1fr);
                // background: white;
                // border-radius: 10px;
                border: 1.5px solid white;
                transition: all linear 0.3s;
                cursor: pointer;

                & > div {
                  padding: 0.5rem 1rem;
                  font-size: 0.8rem;
                  display: flex;
                }

                & > .verticalFields {
                  flex-direction: column;
                  gap: 7px;

                  & > :nth-child(1) {
                    // font-weight: 550;
                  }
                }

                & > .singleFields {
                  align-items: center;
                }

                &:hover {
                  border: 1.5px solid var(--font-color);
                }
              }
              & > .eachItem1 {
                display: grid;
                grid-template-columns: 3% 15% 15% 30% 10% 10% 10%;
                // grid-template-columns: repeat(6, 1fr);
                // background: white;
                // border-radius: 10px;
                border: 1.5px solid white;
                transition: all linear 0.3s;
                cursor: pointer;

                & > div {
                  padding: 0.5rem 1rem;
                  font-size: 0.8rem;
                  display: flex;
                }

                & > .verticalFields {
                  flex-direction: column;
                  gap: 7px;

                  & > :nth-child(1) {
                    // font-weight: 550;
                  }
                }

                & > .singleFields {
                  align-items: center;
                }

                &:hover {
                  border: 1.5px solid var(--font-color);
                }
              }
              & > .eachItem1 {
                display: grid;
                grid-template-columns: 3% 15% 15% 30% 10% 10% 10%;
                // grid-template-columns: repeat(6, 1fr);
                // background: white;
                // border-radius: 10px;
                border: 1.5px solid white;
                transition: all linear 0.3s;
                cursor: pointer;

                & > div {
                  padding: 0.5rem 1rem;
                  font-size: 0.8rem;
                  display: flex;
                }

                & > .verticalFields {
                  flex-direction: column;
                  gap: 7px;

                  & > :nth-child(1) {
                    // font-weight: 550;
                  }
                }

                & > .singleFields {
                  align-items: center;
                }

                &:hover {
                  border: 1.5px solid var(--font-color);
                }
              }
            }
          }
        }
      }
    }

    & > .paginationBar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3rem;
      padding: 5px 2rem;
      background: #e5e5e536;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > .paginationMsg {
        font-size: 0.8rem;
        display: flex;
        align-items: center;
      }

      & > .allPagination {
        margin-left: auto;
        display: flex;
        gap: 10px;
        height: 100%;
        width: max-content;

        & > .eachBtn {
          height: 100%;
          aspect-ratio: 1/1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          border: 1px solid #e7e7e7;
          font-size: 0.9rem;
          font-weight: 500;
          cursor: pointer;
          transition: all linear 0.3s;

          &:hover {
            color: white;
            background: var(--main-color);
          }
        }

        & > .eachBtnCurrent {
          color: white;
          background: var(--main-color);
        }
      }
    }
  }
}

.sidebarMenu {
  width: 45%;
  max-width: 500px;
  position: absolute;
  top: 1px;
  bottom: 0;
  right: -150%;
  background: white;
  border-left: 0.25px solid #e5e5e5;
  // border-top: 0.25px solid #e5e5e5;
  border-bottom: 0.25px solid #e5e5e5;
  transition: all linear 0.3s;

  & > .eachItemDetailed {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;

    & > .cartBtn {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 1rem 1.5rem;
      border-radius: 35px;
      background: var(--main-color);
      color: white;
      font-weight: 500;
      cursor: pointer;
      width: 100%;
      position: relative;
    }

    & > .emptyCard {
      height: 130px;
      padding: 1rem;
      position: relative;

      & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        inset: 0;
      }

      & > div {
        font-size: 0.9rem;
        font-weight: 500;
        color: white;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    & > .allDetails {
      position: relative;
      padding: 0 1rem;

      & > .mainDetail {
        display: flex;
        gap: 1rem;
        transform: translateY(-25px);

        & > img {
          background: white;
          border-radius: 20px;
          width: 90px;
          height: 90px;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          line-height: 1.5;
          margin-top: auto;
          padding-bottom: 5px;

          & > :nth-child(1) {
            font-size: 1rem;
            font-weight: 500;
          }

          & > :nth-child(2) {
            font-size: 0.75rem;
          }
        }
      }

      & > .desc {
        margin-top: 1rem;

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .para {
          font-size: 0.85rem;
          margin-top: 1.2rem;
          line-height: 1.6;
        }
      }

      & > .offerings {
        margin-top: 2.5rem;

        & > .title {
          font-size: 0.9rem;
          font-weight: 500;
        }

        & > .titleBig {
          font-size: 1.4rem;
          font-weight: 550;
        }

        & > .allOfferings {
          margin-top: 1.2rem;

          & > .eachOffering {
            margin-bottom: 1rem;
            border: 1px solid #e5e5e5;
            border-radius: 20px;
            padding: 1rem;
            display: flex;
            gap: 1rem;
            position: relative;

            & > :nth-child(1) {
              width: 100px;
              height: 100px;
              padding: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;

              & > svg {
                height: 100%;
              }

              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
              }
            }

            & > :nth-child(2) {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              & > :nth-child(1) {
                & > .title {
                  font-size: 0.9rem;
                  font-weight: 500;
                }

                & > .learnBtn {
                  font-size: 0.7rem;
                  font-weight: 500;
                  text-decoration: underline;
                  margin-top: 10px;
                  cursor: pointer;
                  transition: all linear 0.3s;

                  &:hover {
                    color: #04bfda;
                  }
                }

                & > .desc {
                  font-size: 0.7rem;
                  font-weight: 500;
                  margin-top: 10px;
                  cursor: pointer;
                }
              }

              & > .priceDiv {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: auto;

                & > :nth-child(1) {
                  font-size: 0.9rem;
                  font-weight: 500;

                  & > span {
                    text-decoration: line-through;
                    opacity: 0.5;
                    margin-left: 5px;
                  }
                }

                & > .btn {
                  font-size: 0.6rem;
                  padding: 7px 1.5rem;
                  border-radius: 35px;
                  cursor: pointer;
                  border: 1px solid #e5e5e5;
                  transition: all linear 0.3s;

                  &:hover {
                    background: var(--main-color);
                    color: white;
                    border: 1px solid var(--main-color);
                  }
                }

                & > .addButton {
                  display: flex;
                  justify-content: space-between;
                  gap: 10px;

                  & > .btns {
                    font-size: 1rem;
                    background: #e5e5e5;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    border-radius: 5px;
                    cursor: pointer;
                  }

                  & > :nth-child(2) {
                    width: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
            }

            & > img {
              position: absolute;
              top: -0.5rem;
              right: -0.5rem;
              width: 1.5rem;
              height: 1.5rem;
              cursor: pointer;
            }
          }
        }
      }
    }

    & > .bottomDiv {
      margin: 2rem 1rem;
      margin-top: auto;

      & > .buyDetails {
        & > .title {
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 1rem;
        }

        & > .priceBreakdown {
          box-shadow: 0px 2px 2px 0px #00000040;
          border: 1px solid #e7e7e7;
          border-radius: 10px;

          & > div {
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.9rem;

            & > :nth-child(1) {
              font-weight: 550;
            }
          }

          & > :nth-child(1) {
            border-bottom: 1px solid #e7e7e7;
          }
        }
      }

      & > .buyBtn {
        background: var(--main-color);
        color: white;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        padding: 0.5rem;
        border-radius: 35px;
        cursor: pointer;
        border: 1px solid var(--main-color);
        margin-top: 3rem;
        transition: all linear 0.3s;

        &:hover {
          background: white;
          color: var(--main-color);
        }
      }
    }
  }

  & > .allCart {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > .allWellnessItems {
    padding: 1.5rem;

    & > .title {
      font-size: 1.2rem;
      font-weight: 550;
      padding: 1rem 0 2rem 0;
    }

    & > .allItems {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      gap: 1.5rem;
      flex-wrap: wrap;

      & > .eachWellnessItem {
        max-width: 150px;
        cursor: pointer;
        transition: all linear 0.3s;

        & > img {
          width: 100%;
          border-radius: 30px;
          aspect-ratio: 1/1;
          transition: all linear 0.3s;
          border: 0.5px solid #e5e5e5;
        }

        & > div {
          font-size: 1rem;
          font-weight: 500;
          padding: 1rem 0;
          text-align: center;
          transition: all linear 0.3s;
        }

        &:hover {
          & > img {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }

          & > div {
            font-weight: 550;
          }
        }
      }
    }
  }
}
