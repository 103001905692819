.newBrokerageParent {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  & > .newBrokerage {
    height: calc(100% - 3rem);
    overflow-y: scroll;
    position: relative;

    & > .title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 2rem;
      padding-top: 0.5rem;
    }

    & > .sectionTitle {
      color: var(--main-color);
      background: #2ec69726;
      padding: 0.75rem 1.5rem;
      font-weight: 600;
      margin-bottom: 2rem;
      position: relative;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        & > svg {
          font-weight: 600;
          fill: var(--main-color);
        }
      }
    }

    & > .inputContainers {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      & > .eachInput {
        & > .inputContainer {
          border: 1.5px solid #e1e1e1;
          font-weight: 450;
          transition: all linear 0.3s;

          &:focus {
            background: white;
            border: 1.5px solid var(--main-color);
          }
          &:hover {
            background: white;
            border: 1.5px solid var(--main-color);
          }
        }
      }
    }
  }

  & > .btnsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2rem;

    & > div {
      padding: 1rem 1.5rem;
      border-radius: 35px;
      font-size: 0.8rem;
      margin-top: 1rem;
      width: 100%;
      max-width: 500px;
      cursor: pointer;
      color: white;
      font-size: 1rem;
      font-weight: 550;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all linear 0.3s;

      &:hover {
        scale: 1.05;
      }
    }

    & > :nth-child(1) {
      background: #5f6163;
    }
    & > :nth-child(2) {
      background: var(--main-color);
    }
  }
}

.eachInput {
  & > .title {
    font-size: 0.9rem;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: 1px;
      right: -0.95rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: #e5e5e566;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }
  }

  & > .inputDiv {
    position: relative;
    max-width: 500px;
    margin-top: 1rem;

    & > .inputContainer {
      padding: 1rem 1.5rem;
      border-radius: 35px;
      border: 1px solid #e5e5e566;
      font-size: 0.8rem;
      background: #e5e5e566;
      width: 100%;

      padding-right: 2.1rem;

      &:focus {
        outline: var(--main-color);
        background: white;
        border: 1.5px solid var(--main-color);
      }
    }

    & > .selectedBanner {
      background: white;
      padding: 0.6rem 1.5rem;
      font-size: 0.9rem;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translate(0%, -50%);
      border-radius: 35px;
      font-weight: 500;
    }

    & > .asteriskIcon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 6rem;
  cursor: pointer;

  & > .title {
    font-size: 0.9rem;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: 1px;
      right: -0.95rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e566;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    bottom: 0.3rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    border: 1.5px solid #e1e1e1;
    font-weight: 450;
    background: rgba(229, 229, 229, 0.4);
    transition: all linear 0.3s;

    &:focus {
      background: white;
      border: 1.5px solid var(--main-color);
    }

    &:hover {
      background: white;
      border: 1.5px solid var(--main-color);

      input {
        background: #e5e5e566;
      }
    }

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > :nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

.imageUploadDiv {
  // line-height: 2;
  font-size: 0.9rem;
  // font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  // margin-top: 2rem;

  & > .imageDiv {
    // margin-top: 0.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    & > img {
      min-width: 8vw;
      min-height: 8vw;
      max-width: 8vw;
      max-height: 8vw;
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      transition: all ease 0.4s;
      padding: 2rem;

      // &:hover {
      //   scale: 0.95;
      //   // border: 5px solid #44c2f4;
      // }
    }

    & > .overlayDiv {
      position: absolute;
      width: 20%;
      height: 20%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & > img {
        animation: heartBeat 0.5s alternate infinite;
      }

      @keyframes heartBeat {
        0% {
          scale: 1;
        }
        100% {
          scale: 1.2;
        }
      }
    }
  }

  & > .uploadFileDiv {
    font-size: 1.1rem;
    transition: all ease 0.4s;
    width: 40%;

    &:hover {
      font-size: 1.3rem;
      font-weight: 550;
    }
    & > .uploadNewPicPlanB {
      display: none;
    }
  }
}

.imageUploadDivProfilePic {
  // line-height: 2;
  font-size: 0.9rem;
  // font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  // margin-top: 2rem;

  & > .imageDiv {
    // margin-top: 0.5rem;
    padding: 2.5rem 1.5rem;
    border-radius: 35px;
    border: 1px solid #e5e5e5;
    font-size: 0.8rem;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      min-width: 5vw;
      min-height: 5vw;
      max-width: 5vw;
      max-height: 5vw;
      border-radius: 50%;
      transition: all ease 0.4s;

      // &:hover {
      //   scale: 0.95;
      //   // border: 5px solid #44c2f4;
      // }
    }

    & > .overlayDiv {
      position: absolute;
      width: 20%;
      height: 20%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & > img {
        animation: heartBeat 0.5s alternate infinite;
      }

      @keyframes heartBeat {
        0% {
          scale: 1;
        }
        100% {
          scale: 1.2;
        }
      }
    }
  }

  & > .uploadFileDiv {
    font-size: 1.1rem;
    transition: all ease 0.4s;
    width: 40%;

    &:hover {
      font-size: 1.3rem;
      font-weight: 550;
    }
    & > .uploadNewPicPlanB {
      display: none;
    }
  }
}

.eachInputDatePicker {
  & > .title {
    font-size: 0.9rem;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: 1px;
      right: -0.95rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  & > .inputContainer {
    color: var(--font-color);
    border: 1.5px solid #e1e1e1;
    padding: 1rem 1.5rem;
    border-radius: 35px;
    border: 1px solid rgba(229, 229, 229, 0.4);
    font-size: 0.8rem;
    background: rgba(229, 229, 229, 0.4);
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;
    transition: all linear 0.3s;

    // &:focus {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
    // &:hover {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
  }
}

//my profile specific

.eachProfileInput {
  border-radius: 10px;
  transition: all linear 0.2s;
  background: white;
  position: relative;
  max-width: 500px;
  margin-bottom: 1rem;

  & > .title {
    font-size: 0.9rem;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: 1px;
      right: -0.95rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  & > .inputParent {
    & > .inputContainer {
      font-size: 1rem;
      outline: none;
      border: none;
      margin-top: 10px;
      width: 100%;
    }

    & > .status {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 5px;
      align-items: center;

      & > svg {
        font-size: 0.8rem;
        width: 1.1rem;
        height: 1.1rem;
      }

      & > span {
        font-size: 0.8rem;
      }
    }

    & > .trashCan {
      background: #2ec69726;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      right: -4.5rem;
      transform: translate(-50%, -50%);

      & > svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  & > .addByEmail {
    padding-bottom: 0.5rem;

    & > .inputParent {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-top: 1.5rem;

      & > .inputContainer {
        font-size: 0.95rem;
        outline: none;
        border: none;
        margin-top: 10px;
        width: 500px;
        background: #e5e5e566;
        border: 1px solid #e5e5e566;
        transition: all linear 0.2s;
        border-radius: 35px;
        padding: 1rem 1.5rem;
        max-width: 500px;

        // &:hover {
        //   border: 1px solid var(--main-color);
        // }

        &:focus {
          background: white;
          border: 1px solid var(--main-color);
        }
      }

      & > .inputDiv {
        position: relative;
        width: 100%;
        max-width: 500px;

        & > .inputContainer {
          padding: 1rem 1.5rem;
          border-radius: 35px;
          border: 1px solid #e5e5e566;
          font-size: 0.8rem;
          background: #e5e5e566;
          width: 100%;

          padding-right: 2.1rem;

          &:focus {
            outline: var(--main-color);
            background: white;
            border: 1.5px solid var(--main-color);
          }
        }

        & > .selectedBanner {
          background: white;
          padding: 0.6rem 1.5rem;
          font-size: 0.9rem;
          font-weight: 500;
          position: absolute;
          right: 25px;
          top: 50%;
          transform: translate(0%, -50%);
          border-radius: 35px;
        }
      }

      & > .addButton {
        font-size: 1.1rem;
        font-weight: 600;
        border-radius: 10px;
        border: 1px solid #e5e5e566;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          border: 1px solid var(--main-color);
        }
      }

      & > .status {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translate(-50%, -50%);
        display: flex;
        gap: 5px;
        align-items: center;

        & > svg {
          font-size: 0.8rem;
          width: 1.1rem;
          height: 1.1rem;
        }

        & > span {
          font-size: 0.8rem;
        }
      }

      & > .trashCan {
        background: #2ec69726;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        right: -4.5rem;
        transform: translate(-50%, -50%);

        & > svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
  }
}

.eachInputPickOne {
  & > .title {
    font-size: 0.9rem;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: 1px;
      right: -0.95rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  & > .selectOneAllOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 1rem;
    margin-top: 0.5rem;

    & > div {
      display: flex;
      text-align: center;
      width: max-content;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      font-size: 0.9rem;
      background: white;
      font-weight: 450;
      transition: all linear 0.3s;

      &:hover {
        color: white;
        border: 1px solid var(--main-color);
        background: var(--main-color);
      }
    }
  }
}

.eachDocumentUpload {
  & > .heading {
    font-size: 1rem;
  }

  & > .imageDiv {
    background: var(--main-color);
    padding: 1rem 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    max-width: 500px;
    border-radius: 35px;

    & > img {
      height: 25px !important;
      width: 25px !important;
      min-width: 15px;
      min-height: 15px;
      padding: 0;
      border: none;
    }

    & > span {
      margin-left: 5px;
      font-size: 0.9rem;
      font-weight: 500;
      color: white;
    }
  }
}

.eachProfileInputTextarea {
  border: 1px solid var(--font-color);
  padding: 0.75rem 1.2rem;
  border-radius: 10px;
  transition: all linear 0.2s;
  background: white;
  position: relative;
  max-width: 500px;
  margin-bottom: 1rem;

  & > .title {
    font-size: 0.65rem;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: 1px;
      right: -0.95rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  & > .inputParent {
    & > .inputContainer {
      font-size: 1rem;
      outline: none;
      border: none;
      margin-top: 10px;
      width: 100%;
    }

    & > .status {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 5px;
      align-items: center;

      & > svg {
        font-size: 0.8rem;
        width: 1.1rem;
        height: 1.1rem;
      }

      & > span {
        font-size: 0.8rem;
      }
    }

    & > .trashCan {
      background: #2ec69726;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      right: -4.5rem;
      transform: translate(-50%, -50%);

      & > svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border: 1.25px solid var(--main-color);
  }
}

.termEditor {
  & > .eachTerm {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 1rem;

    & > .title {
      font-weight: 500;
    }

    & > input {
      width: 100%;
      padding: 1rem 1.4rem;
      border: 1px solid #e5e5e5;
      border-radius: 35px;
    }

    & > .subTerms {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      & > div {
        width: calc(100% - 4rem);
        margin-left: 4rem;
        border: 1px solid #e5e5e5;
        border-radius: 35px;

        & > input {
          padding: 1rem 1.4rem;
          border: none;
          outline: none;
          border-radius: 35px;
          width: 100%;
        }
      }
    }

    & > .btnsParent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 520;

      & > :nth-child(1) {
        height: max-content;
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;

        & > div {
          border: 1px solid #e5e5e5;
          width: 40px;
          height: 40px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
          font-weight: 400;
        }
      }

      & > :nth-child(2) {
        background: var(--main-color);
        padding: 0.8rem 3rem;
        border-radius: 35px;
        border: 1px solid var(--main-color);
        outline: none;
        color: white;
      }
    }
  }
}
