.eachInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  & > .asteriskIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 10px;
  }

  & > .title {
    font-size: 0.9rem;
    margin-bottom: 10px;
    font-weight: 500;
  }

  & > div {
    display: flex;
    gap: 0rem;
    align-items: center;

    & > img {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
    }

    & > .inputContainer {
      padding: 1rem 1.5rem;
      border-radius: 10px;
      border: 1px solid #e5e5e566;
      font-size: 0.8rem;
      background: #ffffff;
      // margin-top: 1rem;
      width: 100%;

      &:focus {
        outline: var(--main-color);
        background: white;
        border: 1.5px solid var(--main-color);
      }
    }
  }
}

.eachProfileInput {
  // border: 1px solid var(--font-color);
  padding: 0.75rem 1.2rem;
  border-radius: 10px;
  transition: all linear 0.2s;
  background: white;
  position: relative;
  width: 100%;

  & > .title {
    font-size: 0.65rem;
  }

  & > .inputParent {
    & > .inputContainer {
      font-size: 1rem;
      outline: none;
      border: none;
      margin-top: 10px;
      width: 100%;
    }

    & > .status {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 5px;
      align-items: center;

      & > svg {
        font-size: 0.8rem;
        width: 1.1rem;
        height: 1.1rem;
      }

      & > span {
        font-size: 0.8rem;
      }
    }

    & > .trashCan {
      background: #2ec69726;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      right: -4.5rem;
      transform: translate(-50%, -50%);

      & > svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border: 1.25px solid var(--main-color);
  }
}
.eachProfileInputGeneral {
  // border: 1px solid var(--font-color);
  background: white;
  position: relative;
  width: 100%;

  & > .title {
    font-size: 0.9rem;
    font-weight: 500;
    // margin-bottom: 1rem;
  }

  & > .inputParent {
    & > .inputContainer {
      font-size: 0.9rem;
      outline: none;
      border: none;
      margin-top: 10px;
      width: 100%;
      border: 1px solid rgba(229, 229, 229, 0.4);
      border-radius: 10px;
      padding: 1rem 1.5rem;
      transition: all linear 0.3s;

      &:focus {
        border: 1px solid var(--main-color);
      }
    }

    & > .status {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 5px;
      align-items: center;

      & > svg {
        font-size: 0.8rem;
        width: 1.1rem;
        height: 1.1rem;
      }

      & > span {
        font-size: 0.8rem;
      }
    }

    & > .trashCan {
      background: #2ec69726;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      right: -4.5rem;
      transform: translate(-50%, -50%);

      & > svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
}

.eachCustomDropdown {
  position: relative;
  min-width: 200px;
  height: 3.2rem;
  cursor: pointer;
  width: 100%;
  transition: all linear 0.3s;

  & > .title {
    font-size: 0.9rem;
    margin-bottom: 7px;
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    // border-radius: 35px;
    border-radius: 10px;
    border: 1.5px solid #e1e1e1;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    position: absolute;
    top: 0rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    transition: all ease-in 0.2s;

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > :nth-child(1) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:focus {
      outline: var(--main-color);
      background: white;
      border: 1.5px solid var(--main-color);
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;
      position: relative;
      height: 150px;
      overflow-y: scroll;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }

      &::-webkit-scrollbar {
        display: block;
        width: 7px; /* Set the width of the table's scrollbar */
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(
          --font-color
        ); /* Set the color of the scrollbar thumb */
        border-radius: 5px; /* Set the border-radius of the thumb */
        height: 20px;
        transition: all linear 0.3s;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: var(
          --main-color
        ); /* Set the color when the scrollbar thumb is hovered */
      }
    }

    // ::-webkit-scrollbar {
    //   width: 0.5em; /* Adjust the width as needed */
    //   background-color: transparent; /* Adjust the background color as needed */
    //   display: none;
    // }

    // ::-webkit-scrollbar-thumb {
    //   background-color: transparent; /* Adjust the thumb color as needed */
    //   display: none;
    // }
  }

  & > .asteriskIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 10px;
  }

  .associatesEachHr {
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: unset;

    & > img {
      border-radius: 50%;
      width: 2.2rem;
      height: 2.2rem;
    }

    & > :nth-child(2) {
      display: grid;
      // gap: 2px;

      & > :nth-child(1) {
        font-size: 1rem;
        font-weight: 520;
      }

      & > :nth-child(2) {
        font-size: 0.7rem;
      }
    }
  }
}

.eachDocumentUpload {
  & > .heading {
    font-size: 1rem;
  }

  & > .imageDiv {
    background: var(--main-color);
    padding: 1rem 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    max-width: 500px;
    border-radius: 10px;

    & > img {
      height: 25px !important;
      width: 25px !important;
      min-width: 15px;
      min-height: 15px;
      padding: 0;
      border: none;
    }

    & > span {
      margin-left: 5px;
      font-size: 0.9rem;
      font-weight: 500;
      color: white;
    }
  }

  & > .uploadFileDiv {
    font-size: 1.1rem;
    transition: all ease 0.4s;
    width: 40%;

    &:hover {
      font-size: 1.3rem;
      font-weight: 550;
    }
    & > .uploadNewPicPlanB {
      display: none;
    }
  }
}

.eachInputDatePicker {
  position: relative;
  & > .title {
    font-size: 0.9rem;
    position: relative;
    width: max-content;

    & > img {
      position: absolute;
      top: 1px;
      right: -0.95rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  & > .inputContainer {
    color: var(--font-color);
    padding: 1rem 1.5rem;
    border-radius: 10px;
    border: 1px solid rgba(229, 229, 229, 0.4);
    font-size: 0.8rem;
    background: white;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;
    transition: all linear 0.3s;

    // &:focus {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
    // &:hover {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
  }

  & > .asteriskIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 10px;
  }

  & > .calendarIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.eachInputSelectOne {
  & > .title {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 10px;
  }

  & > .optionParent {
    display: flex;
    gap: 1rem;
    margin-top: 0.75rem;

    & > div {
      cursor: pointer;
      transition: all linear 0.3s;
      padding: 0.8rem 2rem;
      border-radius: 10px;
      border: 1px solid rgba(229, 229, 229, 0.4);
      font-size: 0.8rem;
    }

    & > .selectedOption {
      background: var(--main-color);
      color: white;
    }
  }
}
